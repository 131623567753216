import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';

@Injectable({
  providedIn: 'root'
})
export class VendorManagementService {

  constructor(private http: HttpClient) { }

  getVendorList(data: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    params = params.append('page', data.page);
    params = params.append('per_page', data.per_page);
    params = params.append('search', data.search);
    return this.http.get<ResponseMessage>('get-vendors', { params });
  }
  addVendor(data: any): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('add-vendor', data);
  }
  updateVendor(data:any):Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('update-vendor', data);
  }
  getVendorDetail(id:any):Observable<ResponseMessage>{
    return this.http.get<ResponseMessage>(`vendor-details/${id}`)
  }
  deleteVendor(id: any): Observable<ResponseMessage> {
    return this.http.delete<ResponseMessage>(`delete-vendor/${id}`);
  }
}
