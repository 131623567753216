<div class="flex flex-justify-between flex-wrap gap-8px mb-24 flex-align-center section-box">
  <h1 class="text-white mb-0 mobile-full fs-20">Online Ordering</h1>
  <div class="flex gap-16px flex-grow-1 flex-justify-end">
    <mat-radio-group aria-label="Select an option">
      <mat-radio-button value="1">Setup Online Order</mat-radio-button>
      <mat-radio-button value="2">Manage Online Order</mat-radio-button>
      <mat-radio-button value="3">My Orders</mat-radio-button>
    </mat-radio-group>
  </div>
</div>

<div class="mb-20">
  <app-setup-online-order></app-setup-online-order>
</div>
<div class="mb-20">
<app-manage-online-order></app-manage-online-order>
</div>
<div class="mb-20">
 <app-my-orders></app-my-orders>
</div>
