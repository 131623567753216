import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { Router, RouterModule } from '@angular/router';
import { SuccessMessageComponent } from '../success-message/success-message.component';
import { UpdateListComponent } from '../update-list/update-list.component';
import { DeleteWaitingComponent } from '../delete-waiting/delete-waiting.component';
import { WaitingListService } from '../service/waiting-list.service';
import { ToastrService } from 'ngx-toastr';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { ConstantService } from '../../../shared/services/constant.service';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { InfiniteScrollDirective, InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ConfirmationComponent } from '../../../shared/components/dialog/confirmation/confirmation.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
export interface PeriodicElement {
  customerName: string;
  mobileNo: string;
  guestNo: string;
  queueNo: string;
  event: string
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
];
@Component({
  selector: 'app-waiting-list',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatTableModule,
    RouterModule,
    InfiniteScrollModule,
    TranslateModule
  ],
  templateUrl: './waiting-list.component.html',
  styleUrls: ['./waiting-list.component.scss']
})
export class WaitingListComponent {
  private waitingListService = inject(WaitingListService);
  private constantService = inject(ConstantService);
  private toastr = inject(ToastrService);
  private route = inject(Router);
  displayedColumns: string[] = ['customerName', 'queueNo', 'guestNo', 'mobileNo', 'event', 'action'];
  dataSource = ELEMENT_DATA;
  tempWaitingList: any = [];
  waitingListPayload: any = {
    page: 1,
    per_page: 15
  }
  subscribeDeleteAPI!: Subscription
  readonly dialog = inject(MatDialog);
  openDialog() {
    const dialogRef = this.dialog.open(SuccessMessageComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
      scrollStrategy: new NoopScrollStrategy()
    });
  }

  openDialogEdit(element?: any) {
    const dialogRef = this.dialog.open(UpdateListComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
      scrollStrategy: new NoopScrollStrategy(),
      data: { element }
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.getWaitingList();
      }
    })
  }
  openDialogDelete() {
    const dialogRef = this.dialog.open(DeleteWaitingComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
      scrollStrategy: new NoopScrollStrategy()
    });
  }
  getWaitingList() {
    this.constantService.progressLoader.next(true);
    this.waitingListPayload.page = 1;
    this.waitingListService.getWaitingList(this.waitingListPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err) => {
        this.toastr.error(err.error.message);
        return throwError(() => err.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code == 200) {
          this.dataSource = res.data.data;
          this.tempWaitingList = res.data.data;
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  waitingListScrollDown() {
    this.constantService.progressLoader.next(true);
    this.waitingListPayload.page = this.waitingListPayload.page + 1;
    this.waitingListService.getWaitingList(this.waitingListPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err) => {
        this.toastr.error(err.error.message);
        return throwError(() => err.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code == 200) {
          this.tempWaitingList = this.dataSource;
          this.dataSource = [...this.tempWaitingList, ...res.data.data];
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  deleteWaitingDialog(id: number) {
    let data = {
      type: 3,
      title: 'delete',
      message: 'delete_waiting_message', //delete_Product_message
      buttonName: { cancel: 'cancel', yes: 'yes', },
      id: id
    }
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      panelClass: ['modal-wrapper'],
      disableClose: true,
      autoFocus: false,
      data
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res?.success) {
        this.deleteWaiting(id);
      }
    })
  }
  deleteWaiting(id: any) {
    this.constantService.progressLoader.next(true);
    if (this.subscribeDeleteAPI) this.subscribeDeleteAPI.unsubscribe();
    this.subscribeDeleteAPI = this.waitingListService.deleteWaiting(id).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.toastr.success(res.message);
          this.getWaitingList();
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  redirectToTable() {
    this.route.navigate(['/waiting-list/tables'])
  }
}
