import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-delete-waiting',
  standalone: true,
  imports: [
    MatDialogModule,
    RouterModule,
    MatButtonModule
  ],
  templateUrl: './delete-waiting.component.html',
  styleUrls: ['./delete-waiting.component.scss']
})
export class DeleteWaitingComponent {

}
