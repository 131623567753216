<mat-dialog-content class="text-center">
  <div class="mb-24 radius-12 flex flex-justify-center">
    <img src="assets/images/delete-ic.png" alt="Makenna Schleifer" class="dialog-smallimg" />
  </div>
  <div class="block text-grey-light mb-24">
    <h3 class="h1 mb-24 text-white">{{data?.name}}</h3>
    <p>{{'are_you_sure_delete_vendor' | translate}}</p>
  </div>
  <div class="btn-box text-center">
    <button mat-flat-button mat-dialog-close class="btn-outline-primary" (click)="close(false)">{{'cancel' | translate}}</button>
    <button mat-flat-button mat-dialog-close (click)="close(true)">{{'confirm' | translate}}</button>
  </div>
</mat-dialog-content>