<!-- Header -->
<div
  class="section-box mb-24 flex flex-wrap flex-justify-between flex-align-center header-section gap-8px header-box backbtn-wrap">
  <h1 class="text-white mb-0 text-truncate">{{editId?('edit_reservation' | translate):('add_reservation' | translate)}}
  </h1>
  <a mat-flat-button class="btn-outline-secondary backbtn" (click)="backToReservationList()">
    <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859"
        stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    {{'back' | translate}}</a>
</div>

<!-- Form -->
<form class="section-box form-box" [formGroup]="addReservationForm">
  <div class="form-grid3">
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>{{'enter_customer_name' | translate}}</mat-label>
      <input matInput formControlName="customer_name">
    </mat-form-field>
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>{{'mobile_number' | translate}}</mat-label>
      <input matInput formControlName="mobile_number">
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic" appearance="outline" class="small-select">
      <mat-label>{{'no_of_guests' | translate | titlecase}}</mat-label>
      <mat-select placeholder="{{'select' | translate}}" formControlName="no_of_guests">
        <mat-option value="option1">Option 1</mat-option>
        <mat-option value="option2" disabled>Option 2</mat-option>
        <mat-option value="option3">Option 3</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="example-form-field" subscriptSizing="dynamic" appearance="outline">
      <mat-label>{{'select_date' | translate}}</mat-label>
      <div class="w-full relative input-rightspace">
        <input matInput [matDatepicker]="datepicker" formControlName="select_date" />
        <mat-datepicker-toggle matIconSuffix [for]="datepicker" class="ic-input cursor-pointer"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #datepicker>
        <mat-datepicker-actions>
          <button mat-flat-button class="btn-outline-primary" matDatepickerCancel>{{'cancel' | translate}}</button>
          <button mat-flat-button matDatepickerApply>{{'apply' | translate}}</button>
        </mat-datepicker-actions>
      </mat-datepicker>
    </mat-form-field>
    <mat-form-field class="example-form-field" subscriptSizing="dynamic" appearance="outline">
      <mat-label>{{'select_time' | translate}}</mat-label>
      <input matInput [owlDateTimeTrigger]="dt4" [owlDateTime]="dt4" formControlName="select_time">
      <owl-date-time [pickerType]="'timer'" #dt4></owl-date-time>
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic" appearance="outline" class="small-select">
      <mat-label>{{'select_table' | translate}}</mat-label>
      <mat-select placeholder="{{'select' | translate}}" formControlName="select_table">
        <mat-option value="option1">Option 1</mat-option>
        <mat-option value="option2">Option 2</mat-option>
        <mat-option value="option3">Option 3</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>{{'reservation_event' | translate | titlecase}}</mat-label>
      <input matInput formControlName="event">
    </mat-form-field>
    <div class="text-end flex flex-align-end flex-justify-end update-btnwrap">
      <button mat-flat-button class="btn-lg mobile-full" (click)="addUpdateReservation()">
        {{'update_reservation' | translate}}
      </button>
    </div>
  </div>

</form>