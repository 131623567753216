<mat-dialog-content class="text-center">
  <div class="mb-24 popup-image">
    <img src="assets/images/logout.png" [alt]="('logout_confirmation' | translate)" />
  </div>
  <div class="block text-grey mb-24">
    <h3 class="h1 mb-4 text-white">{{'logout_confirmation' | translate}}</h3>
  </div>
  <div class="btn-box">
    <button mat-flat-button mat-dialog-close class="w-full btn-outline-primary">{{'cancel' | translate}}</button>
    <!-- <button mat-flat-button class="w-full" (click)="logout()">
      {{'confirm' | translate}}
    </button> -->
    <button mat-flat-button (click)="logout()" class="w-full">
      <mat-spinner *ngIf="loader" [diameter]="spinnerDiameter"
        color="var(--mdc-filled-button-label-text-color)"></mat-spinner>
      <ng-container *ngIf="!loader">
        {{ "confirm" | translate }}
      </ng-container>
    </button>
  </div>
</mat-dialog-content>