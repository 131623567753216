import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResponseMessage } from '../../../../../model/interfaces/req.res.interface';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  private httpClient = inject(HttpClient);

  //#region Transactions API's

  // Get Transactions List
  getTrasactionList(data: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    params = params.append('page', data.page);
    params = params.append('per_page', data.per_page);
    data.search ? (params = params.append('search', data.search)) : '';
    data.from_date ? (params = params.append('from_date', data.from_date)) : '';
    data.to_date ? (params = params.append('to_date', data.to_date)) : '';
    data.day_wise ? (params = params.append('day_wise', data.day_wise)) : '';
    data.payment_method ? (params = params.append('payment_method', data.payment_method)) : '';
    data.transaction_type ? (params = params.append('transaction_type', data.transaction_type)) : '';
    data.user_business_location_id ? (params = params.append('user_business_location_id', data.user_business_location_id)) : '';
    data.item_id ? (params = params.append('item_id', data.item_id)) : '';
    return this.httpClient.get<ResponseMessage>('transaction-list', { params: params });
  }


  // Get Transactions Detail
  getTransactionDetail(id: number): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>(`transaction-details/${id}`);
  }
  // Get order Transactions Detail
  getOrderTransactionDetail(id: number): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>(`order-transaction-details/${id}`);
  }

  // ------- Filter--------------



  // Get Product List filter
  getProduct(data: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    params = params.append('business_location_id', data.business_location_id);
    return this.httpClient.get<ResponseMessage>('get-items', { params: params });
  }

  // Get Branch List
  getBranchList(): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>('view-branch');
  }

  //#endregion Transactions API's
}
