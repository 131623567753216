import { CdkDrag, CdkDragHandle, DragDropModule } from '@angular/cdk/drag-drop';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddTableComponent } from '../add-table/add-table.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { typeOfTableReservation } from '../../../model/const/common.const';
import { CommonModule } from '@angular/common';
import { TableManagementService } from '../services/table-management.service';
import { catchError, finalize, throwError } from 'rxjs';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ConstantService } from '../../../shared/services/constant.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationComponent } from '../../../shared/components/dialog/confirmation/confirmation.component';
@Component({
  selector: 'app-table-list',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    DragDropModule,
    MatCardModule,
    MatTooltipModule,
    MatRadioModule,
    FormsModule,
    TranslateModule,
    InfiniteScrollModule
  ],
  templateUrl: './table-list.component.html',
  styleUrls: ['./table-list.component.scss'],
})
export class TableListComponent {
  private tableManagementService = inject(TableManagementService);
  private constantService = inject(ConstantService);
  private toastr = inject(ToastrService);
  isClassAdded = false;
  tableList: any = [];
  tempTableList: any = [];
  selectTableType: any = '';
  availableTableCount: any = 3;
  reservedTableCount: any = 6;
  typeOfReservation = typeOfTableReservation;

  tableListPayload = {
    page: 1,
    per_page: 15
  }

  toggleClass() {
    this.isClassAdded = !this.isClassAdded;
  }

  readonly dialog = inject(MatDialog);
  openDialog() {
    const dialogRef = this.dialog.open(AddTableComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
      scrollStrategy: new NoopScrollStrategy()
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getTableList();
      }
    })
  }

  getTableList() {
    this.constantService.progressLoader.next(true);
    this.tableListPayload.page = 1;
    this.tableManagementService.tableList(this.tableListPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: any) => {
        if (res.code == 200) {
          this.tableList = res.data.data;
          this.tempTableList = res.data.data
        } else {
          this.toastr.error(res.message);

        }
      })
  }
  tableListScrollDown() {
    this.constantService.progressLoader.next(true);
    this.tableListPayload.page = 1;
    this.tableManagementService.tableList(this.tableListPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: any) => {
        if (res.code == 200) {
          this.tableList = [...this.tempTableList, ...res.data.data];
          this.tempTableList = this.tableList;
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  deleteTableDialog(id: number) {
    let data = {
      type: 3,
      title: 'delete',
      message: 'delete_table_message',
      buttonName: { cancel: 'cancel', yes: 'yes', },
      id: id
    }
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      panelClass: ['modal-wrapper'],
      disableClose: true,
      autoFocus: false,
      data
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res?.success) {
        this.deleteTable(id);
      }
    })
  }
  deleteTable(id: any) {

  }
}
