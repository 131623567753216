import { Component, Inject, inject, OnInit, Optional } from '@angular/core';
import { EncryptionService } from '../../../../../shared/services/encryption.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogContent, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ConstantService } from '../../../../../shared/services/constant.service';
import { TransactionsService } from '../Service/transactions.service';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ResponseMessage } from '../../../../../model/interfaces/req.res.interface';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { DateTime24HrPipe } from '../../../../../shared/pipes/date-time-24hr.pipe';
import { PaymentMethodTypePipe } from '../../../../../shared/pipes/payment-method-type.pipe';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { domainURL } from 'src/app/model/const/common.const';



@Component({
  selector: 'app-view-transaction-detail',
  templateUrl: './view-transaction-detail.component.html',
  styleUrls: ['./view-transaction-detail.component.scss'],
  standalone: true,
  providers: [EncryptionService, DatePipe],
  imports: [
    MatDialogModule,
    CommonModule,
    MatTableModule,
    MatButtonModule,
    DateTime24HrPipe,
    PaymentMethodTypePipe,
    TranslateModule
  ]
})
export class ViewTransactionDetailComponent implements OnInit {
  displayedColumns: string[] = ['no', 'price', 'quantity', 'total'];
  dataSource: any;
  taxPer = 5;
  transactionDetail: any;
  localData: any
  subscribeAPI!: Subscription;
  private transactionsService = inject(TransactionsService);
  private toastr = inject(ToastrService);
  private encDecObj = inject(EncryptionService);
  public constantService = inject(ConstantService);
  private dialog = inject(MatDialog);
  private dialogRef = inject(MatDialogRef<ViewTransactionDetailComponent>);
  getSelectedTransctionDetail: any;
  domainUrl = domainURL;
  total: any = 0;
  selectedTheme = this.constantService.themeType;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ViewTransactionDetailComponent) {
    this.localData = { ...data };
  }


  ngOnInit() {
    this.getTransactionDetail(this.localData.orderId);
  }

  getTransactionDetail(id: number) {
    if (this.subscribeAPI) this.subscribeAPI.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeAPI = this.transactionsService.getOrderTransactionDetail(id).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message);
      })
    ).subscribe((res: ResponseMessage) => {
      if (res.code === 200) {
        this.transactionDetail = res.data;
        this.getSelectedTransctionDetail = this.transactionDetail.transactions.data.filter((transaction: any) => { return transaction.id == this.localData.transactionId })[0]
      } else {
        this.toastr.error(res.message);
      }
    });
  }
  close() {
    this.dialogRef.close();
  }
  ngOnDestroy(): void {
    if (this.subscribeAPI) this.subscribeAPI.unsubscribe();
  }
}


