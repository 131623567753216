import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TableManagementService } from '../services/table-management.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ConstantService } from '../../../shared/services/constant.service';
import { catchError, finalize, throwError } from 'rxjs';

@Component({
  selector: 'app-add-table',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    RouterModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    ReactiveFormsModule
  ],
  templateUrl: './add-table.component.html',
  styleUrls: ['./add-table.component.scss']
})
export class AddTableComponent {
  private tableManagementService = inject(TableManagementService);
  private fb = inject(FormBuilder);
  private toastr = inject(ToastrService);
  private constantService = inject(ConstantService);
  private dialogRef = inject(MatDialogRef<AddTableComponent>)
  addTableForm!: FormGroup;

  initialTableForm() {
    this.addTableForm = this.fb.group({
      table_name: ['', Validators.required]
    })
  }
  ngOnInit() {
    this.initialTableForm();
  }
  addTable() {
    if (this.addTableForm.invalid) {
      return;
    }
    this.constantService.progressLoader.next(true);
    this.tableManagementService.addTable(this.addTableForm.value).pipe(catchError((err) => {
      finalize(() => this.constantService.progressLoader.next(false)),
        this.toastr.error(err.error.message);
      return throwError(() => err.error.message);
    })).subscribe((res: any) => {
      if (res.code == 200) {
        this.toastr.success(res.message);
        this.cancel(true);
      } else {
        this.toastr.error(res.message);
      }
    })
  }

  cancel(value: boolean) {
    this.dialogRef.close(value);
  }

}
