
<div class="section-box mb-24 flex flex-wrap flex-justify-between flex-align-center header-section gap-8px header-box">
  <h1 class="text-white mb-0 mobile-full text-truncate">{{'reservation_management' | translate}}</h1>
  <div class="flex filter-wrapper flex-align-center flex-grow-1 flex-justify-end">
    <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-selection">
      <mat-select placeholder="Filter by">
        <mat-option value="option1">Option 1</mat-option>
        <mat-option value="option2" disabled>Option 2</mat-option>
        <mat-option value="option3">Option 3</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="flex gap-16px flex-grow-1 flex-justify-end flex-align-center w-full search-catwrapper">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-input search-inputbx">
        <div class="w-full relative input-rightspace">
          <input type="search" class="form-control" matInput placeholder="{{'search_by_customer_name' | translate}}"  />
          <mat-icon class="ic-input cursor-pointer">search</mat-icon>
        </div>
      </mat-form-field>
      <a mat-flat-button class="flex-shrink-0 btn-lg" (click)="addReservation()">{{'add_reservation' | translate}}</a>
    </div>
  </div>
</div>

    <div class="table-responsive custom-scroll table-wrapper" infinite-scroll (scrolled)="reservationListScrollDown()"
    [immediateCheck]="true" [scrollWindow]="false" [infiniteScrollDistance]="20" [infiniteScrollThrottle]="0">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!-- Position Column -->
        <ng-container matColumnDef="customerName">
          <th mat-header-cell *matHeaderCellDef>{{'reservation_columns.reservation_customer_name' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="nowrap"> {{element.customerName}} </td>
        </ng-container>
      
        <!-- Name Column -->
        <ng-container matColumnDef="mobileNo">
          <th mat-header-cell *matHeaderCellDef>{{'reservation_columns.mobile_number' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="nowrap"> {{element.mobileNo}} </td>
        </ng-container>
      
        <!-- Weight Column -->
        <ng-container matColumnDef="guestNo">
          <th mat-header-cell *matHeaderCellDef class="text-center">{{'reservation_columns.no_of_guests' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="text-center"> {{element.guestNo}} </td>
        </ng-container>
      
        <!-- Symbol Column -->
        <ng-container matColumnDef="reservedTime">
          <th mat-header-cell *matHeaderCellDef>{{'reservation_columns.reservation_date_time' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="nowrap"> {{element.reservedTime}} </td>
        </ng-container>
        <ng-container matColumnDef="reseredTable">
          <th mat-header-cell *matHeaderCellDef class="text-center">{{'reservation_columns.reservation_reserved_table' | translate}}</th>
          <td mat-cell *matCellDef="let element" [innerHTML]="element.reseredTable" class="text-center"></td>
        </ng-container>
        <ng-container matColumnDef="event">
          <th mat-header-cell *matHeaderCellDef>{{'reservation_columns.reservation_event' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{element.event}} </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="action-col">{{'reservation_columns.action' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex table-actionbtn">
                <button  mat-icon-button aria-label="Edit" matTooltip="{{'edit' | translate}}" (click)="editReservation(element.id)"><span class="icon-edit-pencil"></span></button>
                <button (click)="deleteReservationDialog(element.id)" mat-icon-button aria-label="Delete" matTooltip="Delete"><span class="icon-ic-trash"></span></button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

  
