import { Component, inject, OnInit } from '@angular/core';
import { ConstantService } from '../../services/constant.service';

@Component({
  selector: 'app-under-development',
  standalone: true,
  imports: [],
  templateUrl: './under-development.component.html',
  styleUrls: ['./under-development.component.scss']
})
export class UnderDevelopmentComponent implements OnInit {
  private constantService = inject(ConstantService);
  themeType: any;
  constructor() { }

  ngOnInit() {
    this.themeType = this.constantService.getTheme();
  }

}
