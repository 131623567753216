<div class="flex flex-justify-between flex-wrap gap-8px mb-16 flex-align-center section-box">
  <h1 class="mb-0 mobile-full h3 fw-bold">Purchase Order</h1>
  <div class="flex gap-16px flex-grow-1 flex-justify-end">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="search-inputbx input-lg">
      <div class="w-full relative input-rightspace">
        <input type="search" class="form-control" matInput placeholder="Search Purchase"  />
        <mat-icon class="ic-input cursor-pointer">search</mat-icon>
      </div>
    </mat-form-field>
    <button mat-flat-button class="flex-shrink-0 createmenu-btn btn-lg" routerLink="/purchase-order/create">Create Purchase Order</button>
  </div>
</div>

<!-- Starts Filter Section -->
<div class="filter-boxwrapper flex flex-justify-end flex-wrap mb-20">
  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-date date-field select-bgwrap">
    <div class="w-full relative input-rightspace">
      <input matInput [matDatepicker]="picker" placeholder="From">
      <mat-datepicker-toggle matIconSuffix [for]="picker" class="ic-input cursor-pointer"></mat-datepicker-toggle>
    </div>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-date date-field select-bgwrap">
    <div class="w-full relative input-rightspace">
      <input matInput [matDatepicker]="picker2" placeholder="To">
      <mat-datepicker-toggle matIconSuffix [for]="picker2" class="ic-input cursor-pointer"></mat-datepicker-toggle>
    </div>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-item select-bgwrap">
    <mat-select placeholder="Items">
      <mat-option value="option1">Fruit</mat-option>
      <mat-option value="option2">Eggs</mat-option>
      <mat-option value="option3">Nuts and seeds</mat-option>
      <mat-option value="option3">Veggies</mat-option>
      <mat-option value="option3">Seafood</mat-option>
      <mat-option value="option3">Grains</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-item select-bgwrap">
    <mat-select placeholder="Vendor">
      <mat-option value="option1">Option 1</mat-option>
      <mat-option value="option2">Option 2</mat-option>
      <mat-option value="option3">Option 3</mat-option>
      <mat-option value="option3">Option 4</mat-option>
      <mat-option value="option3">Option 5</mat-option>
      <mat-option value="option3">Option 6</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-location select-bgwrap">
    <mat-select placeholder="Locations">
      <mat-option value="option1">Amsterdam</mat-option>
      <mat-option value="option2">Bangkok</mat-option>
      <mat-option value="option3">Dallas</mat-option>
      <mat-option value="option3">Dubai</mat-option>
      <mat-option value="option3">Frankfurt</mat-option>
      <mat-option value="option3">Hong Kong</mat-option>
    </mat-select>
  </mat-form-field>
  
</div>
<!-- Ends Filter Section -->

<div class="table-sectionbox">
  <div class="flex flex-justify-between flex-wrap gap-8px flex-align-center section-box  table-sectionbx">
    <h1 class="mb-0 mobile-full h3">Purchase Order List</h1>
    <div class="flex gap-16px flex-grow-1 flex-justify-end flex-align-center mobile-full radio-box flex-wrap fs-14">
    
        <label
          id="example-radio-group-label"
          class="text-grey-medium  mb-0 mobile-full fs-14"
          >Purchase Amount</label
        >
        <mat-radio-group aria-label="Select an option" class="hr-radiogroup">
          <mat-radio-button value="1">Last 30 days</mat-radio-button>
          <mat-radio-button value="2">Last 5 year</mat-radio-button>
        </mat-radio-group>
      
    </div>
  </div>
    <div class="table-responsive custom-scroll table-wrapper">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort >
    
        <!-- DATE Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>DATE</th>
          <td mat-cell *matCellDef="let element" class="nowrap"> {{element.date}} </td>
        </ng-container>
      
        <!-- VENDOR NAME Column -->
        <ng-container matColumnDef="vendorName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>VENDOR NAME</th>
          <td mat-cell *matCellDef="let element" class="nowrap"> {{element.vendorName}} </td>
        </ng-container>
      
        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>#ID</th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>
      
        <!-- TITLE Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>TITLE</th>
          <td mat-cell *matCellDef="let element" class="nowrap"> {{element.title}} </td>
        </ng-container>

        <!-- purchase Status Column -->
        <ng-container matColumnDef="purchaseStatus">
          <th mat-header-cell *matHeaderCellDef>purchase Status</th>
          <td mat-cell *matCellDef="let element">
            <span class="badge badge-light-success badge-small">Billed</span>
          </td>
        </ng-container>

        <!-- TOTAL AMOUNT Column -->
        <ng-container matColumnDef="totalAmount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>TOTAL AMOUNT</th>
          <td mat-cell *matCellDef="let element">${{element.totalAmount}} </td>
        </ng-container>

        <!-- ACTION Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="action-col">ACTION</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex table-actionbtn">
                <button mat-icon-button aria-label="View" matTooltip="View" (click)="openDialog()"><span class="icon-eye"></span></button>
                <button mat-icon-button aria-label="Edit" matTooltip="Edit" ><span class="icon-development"></span></button>
                <button mat-icon-button aria-label="Download" matTooltip="Download" ><span class="icon-ic-download"></span></button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
</div>
