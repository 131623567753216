import { Component, inject } from '@angular/core';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

// Modules
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink, RouterModule } from '@angular/router';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { emailOrNumberValidator } from '../../../shared/validators/email-or-number-validator.directive';
import { ConstantService } from '../../../shared/services/constant.service';
import { TranslateModule } from '@ngx-translate/core';
import { emailValidator } from '../../../shared/validators/email-validator.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { spinnerDiameter } from 'src/app/model/const/common.const';
import { LanguageNamePipe } from 'src/app/shared/pipes/language-name.pipe';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    RouterModule,
    RouterLink,
    ReactiveFormsModule,
    NgxMatIntlTelInputComponent,
    MatRadioModule,
    TranslateModule,
    MatProgressSpinnerModule,
    LanguageNamePipe,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  private authService = inject(AuthService);
  private fb = inject(NonNullableFormBuilder);
  private constantService = inject(ConstantService);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private forgotPassSubscription!: Subscription;
  public preferredCountries = ['us'];
  isEmail: boolean = true;
  loader: boolean = false;
  language: any;
  spinnerDiameter = spinnerDiameter;
  /**
   * forgot password form initialisation
   */
  forgotPassForm = this.fb.group({
    userName: ['', [Validators.required, emailOrNumberValidator()]],
    phone: ['', []],
    type: ['1'],
  });

  ngOnInit() {
    this.onInputChange();
    this.setModuleTitle();
    this.getLang();
    this.setCountry();
  }

  setCountry() {
    this.authService.getCountryName().subscribe((response) => {
      this.preferredCountries = [response.country.toLowerCase()];
    });
  }

  /**
   * based on input set validator for email or phone
   */
  onInputChange() {
    this.forgotPassForm.patchValue({
      userName: '',
      phone: '',
    });
    if (this.forgotPassForm.value.type == '1') {
      this.forgotPassForm.controls.userName.setValidators([
        Validators.required,
        emailValidator(),
      ]);
      this.forgotPassForm.controls.userName.updateValueAndValidity();
      this.forgotPassForm.controls.phone.setValidators([]);
      this.forgotPassForm.controls.phone.updateValueAndValidity();
    } else {
      this.forgotPassForm.controls.userName.setValidators([]);
      this.forgotPassForm.controls.userName.updateValueAndValidity();
      this.forgotPassForm.controls.phone.setValidators([
        Validators.required,
        emailOrNumberValidator(),
      ]);
      this.forgotPassForm.controls.phone.updateValueAndValidity();
    }
  }

  /**
   * Submit forgot password form and calling api
   */
  submitForm() {
    let obj: any = {
      email_phone_number:
        this.forgotPassForm.value.userName || this.forgotPassForm.value.phone,
      type: parseInt(this.forgotPassForm.value.type as string),
    };
    if (this.forgotPassSubscription) this.forgotPassSubscription.unsubscribe();
    if (this.forgotPassForm.invalid) {
      return;
    }
    this.loader = true;
    this.forgotPassSubscription = this.authService
      .forgotPassword(obj)
      .pipe(
        finalize(() => (this.constantService.progressLoader.next(false), this.loader = false)),
        catchError((err) => {
          this.toastr.error(err.error.message);
          return throwError(() => err.error.message);
        })
      )
      .subscribe((res: ResponseMessage) => {
        if (res.code == 200) {
          this.toastr.success(res.message);
          this.router.navigate(['/login']);
        } else {
          this.toastr.error(res.message);
        }
      });
  }
  redirectToLogin() {
    this.router.navigate(['/login'])
  }
  changeLanguage() {
    this.constantService.changeLanguages(this.language);
    this.getLang();
  }

  getLang() {
    this.language = this.constantService.getLanguage();
  }
  setModuleTitle() {
    this.constantService.setModuleTitle('Forgot Password');
  }

  ngOnDestroy() {
    if (this.forgotPassSubscription) this.forgotPassSubscription.unsubscribe();
  }
}
