import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LogoutComponent } from '../../../../modules/auth/logout/logout.component';
import { moduleList, moduleListTemp } from '../../../../model/const/common.const';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ConstantService } from '../../../services/constant.service';
import { Subscription } from 'rxjs';
import { BranchLocationComponent } from '../branch-location/branch-location.component';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    TranslateModule,
    CommonModule,
    BranchLocationComponent
  ],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  private ref = inject(ElementRef);
  readonly dialog = inject(MatDialog);
  private constantService = inject(ConstantService);
  readonly router = inject(Router);
  moduleList = moduleListTemp; // moduleList
  currentUrl = '';
  tempModuleName = 'Home';
  subscribeSubject!: Subscription;
  deviceType = this.constantService.getDeviceType();

  @Input() isOpenSideBar: boolean = false;
  @Output() openCloseSideMenu = new EventEmitter();

  constructor() {
    if (this.subscribeSubject) this.subscribeSubject.unsubscribe();
    this.subscribeSubject = this.constantService.selectMenu.subscribe(res => {
      if (res.success) {
        this.setModuleTitle(res.title)
      }
    })
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    this.deviceType = this.constantService.getDeviceType();
  }
  ngOnInit(): void {
    const urlSegment = this.router.url.split("/")[1];
    this.currentUrl = `/${urlSegment}`;
    const module = this.moduleList.find(item => item?.route === this.currentUrl);
    module?.name && this.setModuleTitle(module.name);
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (
      target.id !== 'menuItem' &&
      target.parentElement &&
      target.parentElement.id !== 'mobileMenu'
    ) {
      // this.openCloseSideMenu.emit();
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(LogoutComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
    });
  }

  setModuleTitle(name: string) {
    this.tempModuleName = name;
    this.constantService.setModuleTitle(name);
    this.openCloseSideMenu.emit();
  }
  closeSidemenu() {
    this.openCloseSideMenu.emit();
  }
  redirectToModule() {
    let res = {
      success: true,
      title: 'Product Management'
    }
    this.constantService.selectMenu.next(res);
    this.router.navigate(['/product-list/add'])
  }
  ngOnDestroy(): void {
    if (this.subscribeSubject) this.subscribeSubject.unsubscribe();
  }
}
