<div class="bg-grey-lighter header-balancewrap section-box mb-24 ">
  <div class="header-balanceinnerbox clearfix">
  <h2 class="h1 text-white mb-0 mobile-full greeting-text">
    {{greeting | translate }}, {{ userDetail.name | titlecase }} 😊
  </h2>
  <div class="grid profile-status flex-grow-1 flex-align-center gap-8px">
    <ng-container *ngIf="userDetail.user_type != 3">
      <span class="relative">
        <div class="spinner-background">{{profileCompletePer}}%</div>
        <mat-progress-spinner strokeWidth="2" diameter="40" class="example-margin" mode="determinate"
          [value]="profileCompletePer">
        </mat-progress-spinner>
      </span>
      <div class="progress-barwrap">
        <div class="flex flex-justify-between mb-4">
          <span class="fs-13 text-grey-medium">{{
            "profile_completed" | translate
            }}</span>
          <button mat-button class="fs-13 text-primary fw-normal" (click)="redirectToModule()">
            {{ "edit" | translate }}
          </button>
        </div>
        <mat-progress-bar mode="determinate" [value]="profileCompletePer"></mat-progress-bar>
      </div>
    </ng-container>
  </div>
  <button mat-flat-button class="btn-outline-primary mybalance-btn float-right">
    {{ "my_balance" | translate }}
  </button>
</div>
</div>
<ng-container *ngIf="data$ | async"></ng-container>
<div class="bg-grey-lighter section-box">
  <div class=" flex-align-center  mb-20 gap-8px heading-box">
    <h3 class="text-white mb-0 mobile-full">
      {{ "recent_transactions" | translate }}
    </h3>
    <div class=" gap-28px  innerbox-child ml-auto">
      <mat-form-field appearance="fill" subscriptSizing="dynamic" class="month-selection radius-12 ">
        <mat-select [(ngModel)]="selectedMonth" disableOptionCentering placeholder="{{ 'select_month' | translate }}"
          (valueChange)="changeSelectedMonth($event)">
          <mat-option value="">{{ "select_month" | translate }}</mat-option>

          <mat-option *ngFor="let month of months;" value="{{ month.value }}">
            {{ month.name }}
          </mat-option>

        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" subscriptSizing="dynamic"
        class="monthyear-selection radius-12 ">
        <mat-select [(ngModel)]="selectedPrevMonth" disableOptionCentering
          placeholder="{{ 'vs_previous_month' | translate }}" [disabled]="selectedMonth === ''"
          (valueChange)="changeSelectedPrevMonth($event)">
          <mat-option value="">{{
            "vs_previous_month" | translate
            }}</mat-option>

          <mat-option *ngFor="let month of months;" value=" {{ month.value }}"
            [disabled]="month.value === selectedMonth">
            {{ month.name }}
          </mat-option>

        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <!--  1=>Retail,2=>Restaurant -->
  <div class="transition-box">
    <div class="card-box">
      <div class="flex flex-justify-between gap-8px mb-20 text-white graphbox-info">
        <h2 class="mb-0">{{ businessType == 1 ? ("gross_sales" | translate):("revenue" | translate) }}</h2>
        <h2 class="mb-0">
          {{ businessType == 1
          ? (graphData.gross_sales_module?.data | currency : "USD" : "symbol" : "1.0-0")
          :(graphData.revenue_module?.data | currency : "USD" : "symbol" : "1.0-0")
          }}
        </h2>
      </div>
      <div class="">
        <canvas id="grossSalesCanvas"></canvas>
      </div>
    </div>
    <div class="card-box">
      <div class="flex flex-justify-between gap-8px mb-20 text-white graphbox-info">
        <h2 class="mb-0">{{ businessType == 1 ? ("returns" | translate):("net_profit" | translate) }}</h2>
        <h2 class="mb-0">
          {{ businessType == 1
          ? (graphData.returns_module?.data | currency : "USD" : "symbol" : "1.0-0")
          :(graphData.net_profit_module?.data | currency : "USD" : "symbol" : "1.0-0")
          }}
        </h2>
      </div>
      <div class="">
        <canvas id="returnsCanvas"></canvas>
      </div>
    </div>
    <div class="card-box">
      <div class="flex flex-justify-between gap-8px mb-20 text-white graphbox-info">
        <h2 class="mb-0">{{ "transaction" | translate }}</h2>
        <h2 class="mb-0">
          {{ graphData.transaction_module?.data }}
        </h2>
      </div>
      <div class="">
        <canvas id="transactionCanvas"></canvas>
      </div>
    </div>
    <div class="card-box">
      <div class="flex flex-justify-between gap-8px mb-20 text-white graphbox-info">
        <h2 class="mb-0">{{ "avg_net_sales" | translate }}</h2>
        <h2 class="mb-0">
          {{ graphData.average_net_sales_module?.data | currency : "USD" : "symbol" : "1.0-0" }}
        </h2>
      </div>
      <div class="">
        <canvas id="netSalesCanvas"></canvas>
      </div>
    </div>
  </div>
</div>