<mat-dialog-content class="text-center">
  <div class="mb-24 radius-12">
    <img src="assets/images/split-ic.png" [alt]="('total_amount' | translate)" class="dialog-smallimg" />
  </div>
  <div class="block text-grey-light mb-24">
    <h3 class="h1 text-white">${{localData.grandTotal}} {{'total_amount' | translate}}</h3>
  </div>
  <form [formGroup]="splitPaymentFormGroup">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
      <mat-label>
        {{'enter_amount_for_split' | translate}}
      </mat-label>
      <input matInput formControlName="amount" allowFlotNumberOnly />
    </mat-form-field>
    <div class="fs-16 split-btnwrap mb-16">
      <ng-container *ngFor="let option of splitAmountOptions;">
        <span class="split-btn bg-grey-lighter fw-medium text-grey-medium cursor-pointer"
          [ngClass]="{'active': activeSplitOption === option.value}" (click)="selectSplit(option.value)">
          {{ option.translateVal | translate }}
        </span>
      </ng-container>
    </div>
    <ng-container *ngIf="activeSplitOption == 'Custom'">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
        <mat-label>
          {{'enter_custom_split_value' | translate}}
        </mat-label>
        <input matInput formControlName="customSplit" allowFlotNumberOnly />
      </mat-form-field>
    </ng-container>
    <div class="btn-box text-center">
      <button mat-flat-button mat-dialog-close class="btn-outline-primary"> {{'cancel' | translate}}</button>
      <button mat-flat-button (click)="paymentSplit()">{{'continue' | translate}}</button>
      <!-- <button mat-flat-button mat-dialog-close routerLink="/billing/payment">Continue</button> -->
    </div>
  </form>
</mat-dialog-content>