import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { InfiniteScrollDirective, InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from 'src/app/shared/components/common/header/header.component';
import { SidebarComponent } from 'src/app/shared/components/common/sidebar/sidebar.component';
import { ReservationService } from '../services/reservation.service';
import { ConstantService } from 'src/app/shared/services/constant.service';
import { DeleteReservationComponent } from 'src/app/shared/components/dialog/delete-reservation/delete-reservation.component';
import { ResponseMessage } from 'src/app/model/interfaces/req.res.interface';
import { ConfirmationComponent } from 'src/app/shared/components/dialog/confirmation/confirmation.component';

export interface PeriodicElement {
  id: number,
  customerName: string;
  mobileNo: string;
  guestNo: string;
  reservedTime: string;
  reseredTable: string,
  event: string
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    id: 1,
    customerName: 'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
  {
    id: 2,
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
  {
    id: 3,
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
  {
    id: 4,
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
  {
    id: 5,
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
  {
    id: 6,
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
  {
    id: 7,
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
  {
    id: 8,
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
  {
    id: 9,
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
  {
    id: 10,
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
  {
    id: 11,
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
];
@Component({
  selector: 'app-reservation-list',
  templateUrl: './reservation-list.component.html',
  styleUrls: ['./reservation-list.component.scss'],
  standalone: true,
  imports: [
    HeaderComponent,
    SidebarComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatTooltipModule,
    MatIconModule,
    RouterModule,
    InfiniteScrollModule,
    TranslateModule
  ],
})
export class ReservationListComponent {
  private reservationService = inject(ReservationService);
  private constantService = inject(ConstantService);
  private toastr = inject(ToastrService);
  private route = inject(Router);
  displayedColumns: string[] = ['customerName', 'mobileNo', 'guestNo', 'reservedTime', 'reseredTable', 'event', 'action'];
  dataSource = ELEMENT_DATA;
  tempReservationList: any = [];
  subscribeDeleteAPI!: Subscription;
  reservationPayloadList: any = {
    page: 1,
    per_page: 15
  }

  readonly dialog = inject(MatDialog);

  openDialog() {
    const dialogRef = this.dialog.open(DeleteReservationComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
    });
  }
  getReservationList() {
    this.reservationPayloadList.page = 1;
    this.constantService.progressLoader.next(true);
    this.reservationService.getReservationList(this.reservationPayloadList).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err) => {
        return throwError(() => err.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code == 200) {
          this.tempReservationList = res.data.data;
          this.dataSource = res.data.data;
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  reservationListScrollDown() {
    this.reservationPayloadList.page = this.reservationPayloadList.page + 1;
    this.constantService.progressLoader.next(true);
    this.reservationService.getReservationList(this.reservationPayloadList).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err) => {
        return throwError(() => err.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code == 200) {
          this.tempReservationList = [...this.tempReservationList, ...res.data.data];
          this.dataSource = this.tempReservationList;
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  deleteReservationDialog(id: number) {
    let data = {
      type: 3,
      title: 'delete',
      message: 'delete_reservation_messag',
      buttonName: { cancel: 'cancel', yes: 'yes', },
      id: id
    }
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      panelClass: ['modal-wrapper'],
      disableClose: true,
      autoFocus: false,
      data
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res?.success) {
        this.deleteReservation(id);
      }
    })
  }
  deleteReservation(id: any) {
    this.constantService.progressLoader.next(true);
    if (this.subscribeDeleteAPI) this.subscribeDeleteAPI.unsubscribe();
    this.subscribeDeleteAPI = this.reservationService.deleteReservationt(id).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.toastr.success(res.message);
          this.getReservationList();
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  editReservation(id: number) {
    this.route.navigate(['/reservation/edit', id]);
  }
  addReservation() {
    this.route.navigate(['/reservation/add']);
  }
}
