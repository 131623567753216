<div class="table-sectionbox">
  <div class="flex flex-justify-between flex-wrap gap-8px flex-align-center section-box table-sectionbx">
    <h1 class="mb-0 mobile-full h3 mobile-full">Order List</h1>
  </div>
  <div class="table-responsive custom-scroll table-wrapper">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <!-- Position Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="item-namecol">#ID</th>
        <td mat-cell *matCellDef="let element" class="nowrap">{{element.id}}</td>
      </ng-container>
    
      <!-- Weight Column -->
      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef>CUSTOMER NAME</th>
        <td mat-cell *matCellDef="let element">{{element.customerName}} </td>
      </ng-container>
    
      <!-- Symbol Column -->
      <ng-container matColumnDef="customerNumber">
        <th mat-header-cell *matHeaderCellDef  class="text-center">CUSTOMER NUMBER</th>
        <td mat-cell *matCellDef="let element" class="text-center"> {{element.customerNumber}} </td>
      </ng-container>
      <ng-container matColumnDef="itemTotal">
        <th mat-header-cell *matHeaderCellDef class="text-center">ITEM TOTAL</th>
        <td mat-cell *matCellDef="let element" class="text-center">{{ element.itemTotal }}</td>
      </ng-container>
    
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>status</th>
        <td mat-cell *matCellDef="let element" >
          <span class="badge badge-light-success badge-small">{{element.status}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="action-col">ACTION</th>
        <td mat-cell *matCellDef="let element" class="nowrap">
          <div class="flex table-actionbtn ">
            <span class="badge badge-primary cursor-pointer badge-small" (click)="toggleClass()">Modify Order</span>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>


<!-- OnLine Order Drawer -->
<div class="create-orderwrap flex flex-column custom-scroll" [class.active]="isClassAdded">
  <div class="flex-shrink-0 mb-24">
    <h3 class="h1 mb-0">Online Order</h3>
  </div>

  
  <div class="createorder-innerscroll custom-scroll flex-grow-1">
 
    <div class="gridcontainer-2">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label>Customer Name</mat-label>
        <input matInput>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label>Customer Phone Number</mat-label>
        <input matInput>
      </mat-form-field>
    </div>

    <div class="block mb-24">
      <div class="flex gap-20px mb-24">
        <div class="ordered-image">
          <img src="https://images.pexels.com/photos/842571/pexels-photo-842571.jpeg?auto=compress&cs=tinysrgb&w=600" class="img-fit" alt="American Style Burger" />
        </div>
        <div class="flex flex-justify-between gap-16px flex-grow-1">
          <div class="block text-grey-light fs-14 flex-grow-1 ordered-info">
            <h3 class=" mb-4 text-truncate  fw-medium">American Style Burger</h3>
            <span class="text-grey text-truncate">Lorem ipsum dolor sit amet.</span>
          </div>
          <div class="flex-shrink-0 text-end">
            <h3 class="text-primary fw-bold mb-12">$27.09</h3>
            <div class="product-updatewrap flex">
              <span class="flex-shrink-0 counterupdate-btn decrese-btn">
                <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.04999 1H10.95" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
              <input class="form-control counter-updater flex-shrink-0" value="1" />
              <span class="flex-shrink-0 counterupdate-btn increase-btn">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.04999 8H12.95" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8 3.0498V12.9498" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>  
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex gap-20px mb-24">
        <div class="ordered-image">
          <img src="https://images.pexels.com/photos/842571/pexels-photo-842571.jpeg?auto=compress&cs=tinysrgb&w=600" class="img-fit" alt="American Style Burger" />
        </div>
        <div class="flex flex-justify-between gap-16px flex-grow-1">
          <div class="block text-grey-light fs-14 flex-grow-1 ordered-info">
            <h3 class=" mb-4 text-truncate fw-medium">American Style Burger</h3>
            <span class="text-grey text-truncate">Lorem ipsum dolor sit amet.</span>
          </div>
          <div class="flex-shrink-0 text-end">
            <h3 class="text-primary fw-bold mb-12">$27.09</h3>
            <div class="product-updatewrap flex">
              <span class="flex-shrink-0 counterupdate-btn decrese-btn">
                <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.04999 1H10.95" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
              <input class="form-control counter-updater flex-shrink-0" value="1" />
              <span class="flex-shrink-0 counterupdate-btn increase-btn">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.04999 8H12.95" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8 3.0498V12.9498" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>  
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="totalcount-wrapper mb-12">
      <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
        <span>Subtotal</span>
        <span>$54.18</span>
      </div>
      <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
        <span>Discount</span>
        <span>$00.00</span>
      </div>
      <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
        <span>Tax</span>
        <span>$1.99</span>
      </div>
      <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
        <span>Tip</span>
        <span matTooltip="Add Tip" class="text-gredient fs-16 cursor-pointer" aria-label="Add Tip">Add Tip</span>
      </div>
    </div>
    <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between mb-24">
      <span class="h3 text-grey-light fw-bold">Total</span>
      <span class="text-primary h3 fw-bold">$55.17</span>
    </div>
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="">
      <mat-label>Cooking Instruction (If any)</mat-label>
      <input matInput>
    </mat-form-field>
    <div class="order-btns">
      <button mat-flat-button class="btn-outline-secondary">Discard</button>
      <button mat-flat-button class="btn-outline-primary">Add Item</button>
      <button mat-flat-button class="">Modify Order</button>
    </div>
  </div>
  
</div>
<div class="overlay-wrapper" (click)="toggleClass()"></div>
<!--  -->