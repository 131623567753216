import { Component, Inject, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-delete-vendor',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    TranslateModule
  ],
  templateUrl: './delete-vendor.component.html',
  styleUrls: ['./delete-vendor.component.scss']
})
export class DeleteVendorComponent {
  dialofgRef = inject(MatDialogRef<DeleteVendorComponent>)
  constructor(@Inject(MAT_DIALOG_DATA) public data:any){

  }
  close(res:boolean){
    this.dialofgRef.close(res);
  }
}
