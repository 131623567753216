import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ChangePasswordComponent } from "../../../auth/change-password/change-password.component";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { SettingsService } from '../../services/settings.service';
import { ToastrService } from 'ngx-toastr';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EncryptionService } from '../../../../shared/services/encryption.service';
import { ConstantService } from '../../../../shared/services/constant.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    ChangePasswordComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    TranslateModule,
    MatInputModule,
  ],
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [EncryptionService]
})
export class ProfileComponent implements OnInit {

  subscribeGetAPI!: Subscription;
  subscribe!: Subscription;
  profileFormGroup!: FormGroup;
  profileDetail: any;
  private settingService = inject(SettingsService);
  private toastr = inject(ToastrService);
  private encDecObj = inject(EncryptionService);
  private fb = inject(NonNullableFormBuilder);
  private constantService = inject(ConstantService);
  private route = inject(Router);
  constructor() { }

  initialProfileForm() {
    this.profileFormGroup = this.fb.group({
      name: ['', Validators.required],
      emailOrPhone: [{ value: '', disabled: true }],
    })
  }

  ngOnInit() {
    this.initialProfileForm();
    this.constantService.progressLoader.next(true);
    this.getProfileDetail();
  }

  getProfileDetail() {
    if (this.subscribeGetAPI) this.subscribeGetAPI.unsubscribe();
    this.subscribeGetAPI = this.settingService.getProfile().pipe(
      catchError((error) => {
        this.constantService.progressLoader.next(false);
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.profileDetail = res.data;
          this.setFormData(res.data)
        } else {
          this.constantService.progressLoader.next(false);
          this.toastr.error(res.message);
        }
      })
  }

  setFormData(data: any) {
    this.profileFormGroup.patchValue({
      name: data.name,
      emailOrPhone: data.email_phone_number,
    })
    this.constantService.progressLoader.next(false);
  }
  updateProfile() {
    this.profileFormGroup.markAllAsTouched();
    if (this.profileFormGroup.invalid) return;
    if (this.subscribe) this.subscribe.unsubscribe();
    this.constantService.progressLoader.next(true);
    let formData = new FormData();
    formData.append('name', this.profileFormGroup.value.name);
    this.subscribe = this.settingService.updateProfile(formData).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.updateUserDetail(res.data);
          this.toastr.success(res.message);
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  updateUserDetail(data: any) {
    if (localStorage.getItem('userDetail')) {
      const userDetail = JSON.parse(this.encDecObj.getDecrypt(localStorage.getItem('userDetail')));
      userDetail.name = data.name;
      const tempUserDetail = this.encDecObj.setEncrypt(JSON.stringify(userDetail));
      localStorage.setItem('userDetail', tempUserDetail);
    }
  }
  redirectToPrimarySetup(){
    this.route.navigate(['/primary-setup']);
  }
  redirectToAccountProtection(){
    const account_protection = this.profileDetail.account_protection
    this.route.navigate(['/account-protection'],{state:{account_protection}});
  }
}
