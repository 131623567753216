import { Component, HostListener, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { ManageRefundService } from '../service/manage-refund.service';
import { ToastrService } from 'ngx-toastr';
import { EncryptionService } from '../../../../shared/services/encryption.service';
import { ConstantService } from '../../../../shared/services/constant.service';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime24HrPipe } from '../../../../shared/pipes/date-time-24hr.pipe';
import { CommonModule, DatePipe } from '@angular/common';

@Component({
  selector: 'app-refund-list',
  standalone: true,
  imports: [
    RouterModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatTableModule,
    MatInputModule,
    MatIconModule,
    MatButtonToggleModule,
    FormsModule,
    InfiniteScrollModule,
    TranslateModule,
    CommonModule,
    DateTime24HrPipe,
  ],
  providers: [
    EncryptionService,
    DatePipe,
  ],
  templateUrl: './refund-list.component.html',
  styleUrls: ['./refund-list.component.scss']
})
export class RefundListComponent {

  private refundService = inject(ManageRefundService);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private encDecObj = inject(EncryptionService);
  public constantService = inject(ConstantService);
  private activateRoute = inject(ActivatedRoute);

  displayedColumns: string[] = ['name', 'price', 'quantity', 'total_amount'];
  dataSourceRefundDetail: any;
  totalRecord: number = 0;
  subscribeListAPI!: Subscription;
  subscribeDetailAPI!: Subscription;
  selectedListType = '1';
  refundListData: any;
  taxPer = 0;
  selectedBranchLocation = 'select_business_location';
  tempRefundDetail = 'temp_refund_detail';
  refundDetailData: any;
  backInvoiceId: any;
  selectedBranchLocationId = 0;
  deviceType = 1;
  pageNumber: number = 1;
  perPages: number = 25;
  serachRefund: String = '';
  refundSearchPayload = {
    searchValue: '',
  };

  constructor() {
    const id = Number(this.activateRoute.snapshot.queryParams['back']);
    if (id > 0) {
      this.backInvoiceId = id;
      this.constantService.replaceStateUrl();
    }
  }


  ngOnInit(): void {
    const configurationDetail = this.constantService.getConfigurationDetail();
    this.taxPer = configurationDetail ? configurationDetail.tax : 0;
    
    this.getRefundList();
  }



  getBusinesslocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.selectedBranchLocationId = Number(id);
    }
  }

  //#region Refund List

  getRefundList() {
    this.getBusinesslocation();
    this.pageNumber = 1;
    let payload = {
      page: this.pageNumber,
      per_page: this.perPages,
      search: this.refundSearchPayload?.searchValue,
      user_business_location_id: this.selectedBranchLocationId,
      transaction_type: this.selectedListType,
    }
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeListAPI = this.refundService.getRefundList(payload).pipe(
      catchError((error) => {
        this.constantService.progressLoader.next(false);
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.refundListData = res.data.data;
          this.totalRecord = res.data.pagination.total;
          if (this.totalRecord > 0) {
            this.refundDetailData = '';
            // Check if this.backInvoiceId exists in the array
            const matchingTransaction = this.refundListData.find((transaction: { id: any; }) => transaction.id === this.backInvoiceId);
            const transactionId = matchingTransaction ? this.backInvoiceId : this.refundListData[0].id;
            this.selectTransaction(transactionId);
          } else {
            this.refundDetailData = '';
            this.constantService.progressLoader.next(false);
          }
        } else {
          this.constantService.progressLoader.next(false);
          this.toastr.error(res.message);
        }
      })
  }

  refundListScrollDown() {
    this.pageNumber += this.pageNumber;
    let payload = {
      page: this.pageNumber,
      per_page: this.perPages,
      business_location_id: this.selectedBranchLocationId,
      transaction_type: this.selectedListType,
    }
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeListAPI = this.refundService.getRefundList(payload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.refundListData = [...this.refundListData, ...res.data.data];
          this.totalRecord = res.data.pagination.total;
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  selectTransaction(transactionId: number, select?: boolean) {
    this.refundListData.forEach((transaction: { isSelected: boolean; id: number; }) => {
      transaction.isSelected = (transaction.id === transactionId);
    });
    select ? this.deviceType = this.constantService.getDeviceType() : '';
    this.getRefundDetail(transactionId);
  }
  //#endregion Refund List

  //#region Get Refund Detail
  getRefundDetail(id: number) {
    if (this.subscribeDetailAPI) this.subscribeDetailAPI.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.refundService.getRefundDetail(id).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.refundDetailData = res.data;
          this.dataSourceRefundDetail = new MatTableDataSource(res.data.items);
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  //#endregion Get Refund Detail

  //#region Search Refund
  serachTransactions() {
    this.serachRefund = this.serachRefund.trimStart();
    const searchText = this.serachRefund.trim();

    if (searchText !== this.refundSearchPayload.searchValue) {
      if (searchText && searchText.length > 1) {
        this.refundSearchPayload.searchValue = searchText;
        this.getRefundList();
      } else if (!searchText && this.refundSearchPayload.searchValue !== '') {
        this.refundSearchPayload.searchValue = '';
        this.getRefundList();
      }
    }
  }
  //#endregion  Search Refund

  refundRequest(id: number) {
    localStorage.setItem(this.tempRefundDetail, this.encDecObj.setEncrypt(JSON.stringify(this.refundDetailData)))
    this.router.navigate([`/refunds/request/${id}`]);
  }
  sendReceiptAgain(id: number) {

  }


  // Back button show only mobile screen
  backToList() {
    this.deviceType = 1;
  }

  selectList(value: any) {
    this.selectedListType = value;
    this.getRefundList()
  }

  ngOnDestroy(): void {
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    if (this.subscribeDetailAPI) this.subscribeDetailAPI.unsubscribe();
  }
}
