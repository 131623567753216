<!-- <div class="section-box mb-24 flex flex-wrap flex-justify-between flex-align-center header-section gap-8px">
  <h1 class="text-grey-light mb-0 mobile-full text-truncate">
    {{'bar_inventory' | translate}}
  </h1>
  <div class="flex filter-wrapper flex-wrap">
    <mat-form-field subscriptSizing="dynamic" class="filter-selection">
      <mat-select placeholder="Filter by">
        <mat-option value="option1">Option 1</mat-option>
        <mat-option value="option2" disabled>Option 2</mat-option>
        <mat-option value="option3">Option 3</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-flat-button class="btn-outline-primary hide-mobile create-menubtn mobile-half">Create Menu</button>
    <a mat-flat-button class="flex-shrink-0 additem-btn mobile-half" routerLink="/bar-inventory/edit">Add Item</a>
  </div>
</div>


<div class="table-responsive custom-scroll table-wrapper" infinite-scroll (scrolled)="inventoryListScrollDown()"
  [immediateCheck]="true" [scrollWindow]="false" [infiniteScrollDistance]="20" [infiniteScrollThrottle]="0">
  <table mat-table [dataSource]="dataSourceBarInventory" class="mat-elevation-z8">


    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="item-namecol"> {{ "bar_inventory_columns.item_name" | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="nowrap">
        <div class="item-infobox">
          <img [src]="element.url" [alt]="element?.image_name" class="img-fit item-image" />
          <span class="text-truncate max-w-300" [matTooltip]="element.name">{{ element.name }}</span>
        </div>
      </td>
    </ng-container>

 
    <ng-container matColumnDef="category_name">
      <th mat-header-cell *matHeaderCellDef> {{ "bar_inventory_columns.category" | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{element.category_name}} </td>
    </ng-container>

  
    <ng-container matColumnDef="user_business_location">
      <th mat-header-cell *matHeaderCellDef> {{ "bar_inventory_columns.branch_location" | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{element.user_business_location.name}} </td>
    </ng-container>

  
    <ng-container matColumnDef="available_stock">
      <th mat-header-cell *matHeaderCellDef> {{ "bar_inventory_columns.stock" | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{element.available_stock}} </td>
    </ng-container>


    <ng-container matColumnDef="available_to_sell">
      <th mat-header-cell *matHeaderCellDef> {{ "bar_inventory_columns.available_to_sell" | translate }} </th>
      <td mat-cell *matCellDef="let element">
        <div [ngClass]="{'text-grey-dark not-availabletxt': element?.available_to_sell != 1}">
          {{ element?.available_to_sell == 1 ? ('available' | translate) : ('not_available' | translate) }}
        </div>
      </td>
    </ng-container>

  
    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>{{ "bar_inventory_columns.price" | translate }}</th>
      <td mat-cell *matCellDef="let element">${{element.price.toFixed(2)}} </td>
    </ng-container>

  

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="action-col text-center">
        {{ "bar_inventory_columns.action" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div class="flex table-actionbtn">
          <button (click)="openDialog()" mat-icon-button aria-label="View" [matTooltip]="('view' | translate)"><span
              class="icon-eye"></span></button>
          <button mat-icon-button aria-label="Edit" [matTooltip]="('edit' | translate)"><span
              class="icon-development"></span></button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  @if (totalRecord == 0 && !constantService.progressLoader()) {
  <div class="no-records">
    {{ "no_records" | translate }}
  </div>
  }
</div> -->
<app-inventory-list [title]="'bar_inventory'" [moduleType]="2"></app-inventory-list>