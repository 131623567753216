import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { MatRadioModule } from '@angular/material/radio';
import { FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { PasswordFieldType } from '../../../model/interfaces/auth.interface';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { ConstantService } from '../../../shared/services/constant.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { spinnerDiameter } from 'src/app/model/const/common.const';
import { PasswordMatchAndValidatorDirective } from 'src/app/shared/validators/password-match-and-validator.directive';
import { LanguageNamePipe } from 'src/app/shared/pipes/language-name.pipe';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../authentication.scss'],
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    RouterModule,
    ReactiveFormsModule,
    MatRadioModule,
    TranslateModule,
    MatProgressSpinnerModule,
    PasswordMatchAndValidatorDirective,
    LanguageNamePipe,
  ],
  standalone: true
})
export class ResetPasswordComponent {
  private authService = inject(AuthService);
  private fb = inject(NonNullableFormBuilder);
  private ac = inject(ActivatedRoute);
  private route = inject(Router);
  private constantService = inject(ConstantService);
  hidePass: boolean = false;
  hideCPass: boolean = false;
  private token = this.ac.snapshot.queryParams.token;
  private toastr = inject(ToastrService);
  private resetPasswordSubscription!: Subscription
  passwordFieldType: PasswordFieldType = 'password';
  confirmPasswordFieldType: PasswordFieldType = 'password';
  loader: boolean = false;
  language: any;
  spinnerDiameter = spinnerDiameter;
  resetPasswordForm!: FormGroup;

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.innitialResetForm();
    this.setModuleTitle();
    this.getLang();
  }
  /**
   * reset-password form initialisation
   */
  innitialResetForm() {
    this.resetPasswordForm = this.fb.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', Validators.required]
    });
  }
  /**
   * Calls the reset password api and resets the password
   */
  resetPass() {
    if (this.resetPasswordSubscription) this.resetPasswordSubscription.unsubscribe()
    if (this.resetPasswordForm.invalid) {
      return;
    }
    let obj = {
      token: this.token,
      password: this.resetPasswordForm.value.password,
      password_confirmation: this.resetPasswordForm.value.confirmPassword
    }
    this.constantService.progressLoader.next(true);
    this.resetPasswordSubscription = this.authService.resetPassword(obj).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err) => {
        this.toastr.error(err.error.message)
        return throwError(() => err.error.message)
      }
      )).subscribe((res: ResponseMessage) => {
        if (res.code == 200) {
          this.toastr.success(res.message);
          this.redirectToLogin();
        } else {
          this.toastr.error(res.message)
        }
      })
  }

  changeLanguage() {
    this.constantService.changeLanguages(this.language);
    this.getLang();
  }

  getLang() {
    this.language = this.constantService.getLanguage();
  }

  setModuleTitle() {
    this.constantService.setModuleTitle('Reset Password');
  }

  redirectToLogin() {
    this.route.navigate(['/login']);
  }
}
