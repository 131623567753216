
<div class="section-box mb-24 flex flex-wrap">
  <h1 class="text-white mb-0 mobile-full">My Orders</h1>
  <div></div>
</div>
<div class="order-wrapper">
  <div class="table-box cursor-pointer" (click)="toggleClass()">
    <div class="flex flex-justify-between mb-24 table-datebox">
      <div class="text-grey-medium datetime-wrap">
        <span>28/11/2023</span>
        <span>11:45 AM</span>
      </div>
      <span class="fw-semibold text-grey-dark flex-shrink-0">#231128001</span>
    </div>
    <div>
      <div class="flex gap-8px flex-align-center flex-justify-between mb-16">
        <h4 class="text-white mb-0 fw-bold">Table 12</h4>
        <span class="fs-14 fw-medium text-grey-medium">3 items</span>
      </div>
      <div class="block mb-24">
        <div class="block mb-16">
          <p class="text-primary mb-4">American Style Burger</p>
          <p class="mb-4">No Tomatoes</p>
          <p class="mb-4">No Onion</p>
        </div>
        <div class="block mb-16">
          <p class="text-primary mb-4">Combo-6</p>
          <p class="mb-4">With everything</p>
        </div>
      </div>
      <div class="flex flex-justify-between">
        <span class="badge badge-warning">In Progress</span>
        <span class="badge badge-success">Ready</span>
      </div>
    </div>
  </div>
  <div class="table-box cursor-pointer" (click)="toggleClass()">
    <div class="flex flex-justify-between mb-24 table-datebox">
      <div class="text-grey-medium datetime-wrap">
        <span>28/11/2023</span>
        <span>11:45 AM</span>
      </div>
      <span class="fw-semibold text-grey-dark flex-shrink-0">#231128001</span>
    </div>
    <div>
      <div class="flex gap-8px flex-align-center flex-justify-between mb-16">
        <h4 class="text-white mb-0 fw-bold">Table 12</h4>
        <span class="fs-14 fw-medium text-grey-medium">3 items</span>
      </div>
      <div class="block mb-24">
        <div class="block mb-16">
          <p class="text-primary mb-4">American Style Burger</p>
          <p class="mb-4">No Tomatoes</p>
          <p class="mb-4">No Onion</p>
        </div>
        <div class="block mb-16">
          <p class="text-primary mb-4">Combo-6</p>
          <p class="mb-4">With everything</p>
        </div>
      </div>
      <div class="flex flex-justify-between">
        <span class="badge badge-warning">In Progress</span>
        <span class="badge badge-success">Ready</span>
      </div>
    </div>
  </div>
  <div class="table-box cursor-pointer" (click)="toggleClass()">
    <div class="flex flex-justify-between mb-24 table-datebox">
      <div class="text-grey-medium datetime-wrap">
        <span>28/11/2023</span>
        <span>11:45 AM</span>
      </div>
      <span class="fw-semibold text-grey-dark flex-shrink-0">#231128001</span>
    </div>
    <div>
      <div class="flex gap-8px flex-align-center flex-justify-between mb-16">
        <h4 class="text-white mb-0 fw-bold">Table 12</h4>
        <span class="fs-14 fw-medium text-grey-medium">3 items</span>
      </div>
      <div class="block mb-24">
        <div class="block mb-16">
          <p class="text-primary mb-4">American Style Burger</p>
          <p class="mb-4">No Tomatoes</p>
          <p class="mb-4">No Onion</p>
        </div>
        <div class="block mb-16">
          <p class="text-primary mb-4">Combo-6</p>
          <p class="mb-4">With everything</p>
        </div>
      </div>
      <div class="flex flex-justify-between">
        <span class="badge badge-warning">In Progress</span>
        <span class="badge badge-success">Ready</span>
      </div>
    </div>
  </div>
  <div class="table-box cursor-pointer" (click)="toggleClass()">
    <div class="flex flex-justify-between mb-24 table-datebox">
      <div class="text-grey-medium datetime-wrap">
        <span>28/11/2023</span>
        <span>11:45 AM</span>
      </div>
      <span class="fw-semibold text-grey-dark flex-shrink-0">#231128001</span>
    </div>
    <div>
      <div class="flex gap-8px flex-align-center flex-justify-between mb-16">
        <h4 class="text-white mb-0 fw-bold">Table 12</h4>
        <span class="fs-14 fw-medium text-grey-medium">3 items</span>
      </div>
      <div class="block mb-24">
        <div class="block mb-16">
          <p class="text-primary mb-4">American Style Burger</p>
          <p class="mb-4">No Tomatoes</p>
          <p class="mb-4">No Onion</p>
        </div>
        <div class="block mb-16">
          <p class="text-primary mb-4">Combo-6</p>
          <p class="mb-4">With everything</p>
        </div>
      </div>
      <div class="flex flex-justify-between">
        <span class="badge badge-warning">In Progress</span>
        <span class="badge badge-success">Ready</span>
      </div>
    </div>
  </div>
  <div class="table-box cursor-pointer" (click)="toggleClass()">
    <div class="flex flex-justify-between mb-24 table-datebox">
      <div class="text-grey-medium datetime-wrap">
        <span>28/11/2023</span>
        <span>11:45 AM</span>
      </div>
      <span class="fw-semibold text-grey-dark flex-shrink-0">#231128001</span>
    </div>
    <div>
      <div class="flex gap-8px flex-align-center flex-justify-between mb-16">
        <h4 class="text-white mb-0 fw-bold">Table 12</h4>
        <span class="fs-14 fw-medium text-grey-medium">3 items</span>
      </div>
      <div class="block mb-24">
        <div class="block mb-16">
          <p class="text-primary mb-4">American Style Burger</p>
          <p class="mb-4">No Tomatoes</p>
          <p class="mb-4">No Onion</p>
        </div>
        <div class="block mb-16">
          <p class="text-primary mb-4">Combo-6</p>
          <p class="mb-4">With everything</p>
        </div>
      </div>
      <div class="flex flex-justify-between">
        <span class="badge badge-warning">In Progress</span>
        <span class="badge badge-success">Ready</span>
      </div>
    </div>
  </div>
  <div class="table-box cursor-pointer" (click)="toggleClass()">
    <div class="flex flex-justify-between mb-24 table-datebox">
      <div class="text-grey-medium datetime-wrap">
        <span>28/11/2023</span>
        <span>11:45 AM</span>
      </div>
      <span class="fw-semibold text-grey-dark flex-shrink-0">#231128001</span>
    </div>
    <div>
      <div class="flex gap-8px flex-align-center flex-justify-between mb-16">
        <h4 class="text-white mb-0 fw-bold">Table 12</h4>
        <span class="fs-14 fw-medium text-grey-medium">3 items</span>
      </div>
      <div class="block mb-24">
        <div class="block mb-16">
          <p class="text-primary mb-4">American Style Burger</p>
          <p class="mb-4">No Tomatoes</p>
          <p class="mb-4">No Onion</p>
        </div>
        <div class="block mb-16">
          <p class="text-primary mb-4">Combo-6</p>
          <p class="mb-4">With everything</p>
        </div>
      </div>
      <div class="flex flex-justify-between">
        <span class="badge badge-warning">In Progress</span>
        <span class="badge badge-success">Ready</span>
      </div>
    </div>
  </div>
  <div class="table-box cursor-pointer" (click)="toggleClass()">
    <div class="flex flex-justify-between mb-24 table-datebox">
      <div class="text-grey-medium datetime-wrap">
        <span>28/11/2023</span>
        <span>11:45 AM</span>
      </div>
      <span class="fw-semibold text-grey-dark flex-shrink-0">#231128001</span>
    </div>
    <div>
      <div class="flex gap-8px flex-align-center flex-justify-between mb-16">
        <h4 class="text-white mb-0 fw-bold">Table 12</h4>
        <span class="fs-14 fw-medium text-grey-medium">3 items</span>
      </div>
      <div class="block mb-24">
        <div class="block mb-16">
          <p class="text-primary mb-4">American Style Burger</p>
          <p class="mb-4">No Tomatoes</p>
          <p class="mb-4">No Onion</p>
        </div>
        <div class="block mb-16">
          <p class="text-primary mb-4">Combo-6</p>
          <p class="mb-4">With everything</p>
        </div>
      </div>
      <div class="flex flex-justify-between">
        <span class="badge badge-warning">In Progress</span>
        <span class="badge badge-success">Ready</span>
      </div>
    </div>
  </div>
  <div class="table-box cursor-pointer" (click)="toggleClass()">
    <div class="flex flex-justify-between mb-24 table-datebox">
      <div class="text-grey-medium datetime-wrap">
        <span>28/11/2023</span>
        <span>11:45 AM</span>
      </div>
      <span class="fw-semibold text-grey-dark flex-shrink-0">#231128001</span>
    </div>
    <div>
      <div class="flex gap-8px flex-align-center flex-justify-between mb-16">
        <h4 class="text-white mb-0 fw-bold">Table 12</h4>
        <span class="fs-14 fw-medium text-grey-medium">3 items</span>
      </div>
      <div class="block mb-24">
        <div class="block mb-16">
          <p class="text-primary mb-4">American Style Burger</p>
          <p class="mb-4">No Tomatoes</p>
          <p class="mb-4">No Onion</p>
        </div>
        <div class="block mb-16">
          <p class="text-primary mb-4">Combo-6</p>
          <p class="mb-4">With everything</p>
        </div>
      </div>
      <div class="flex flex-justify-between">
        <span class="badge badge-warning">In Progress</span>
        <span class="badge badge-success">Ready</span>
      </div>
    </div>
  </div>
  <div class="table-box cursor-pointer" (click)="toggleClass()">
    <div class="flex flex-justify-between mb-24 table-datebox">
      <div class="text-grey-medium datetime-wrap">
        <span>28/11/2023</span>
        <span>11:45 AM</span>
      </div>
      <span class="fw-semibold text-grey-dark flex-shrink-0">#231128001</span>
    </div>
    <div>
      <div class="flex gap-8px flex-align-center flex-justify-between mb-16">
        <h4 class="text-white mb-0 fw-bold">Table 12</h4>
        <span class="fs-14 fw-medium text-grey-medium">3 items</span>
      </div>
      <div class="block mb-24">
        <div class="block mb-16">
          <p class="text-primary mb-4">American Style Burger</p>
          <p class="mb-4">No Tomatoes</p>
          <p class="mb-4">No Onion</p>
        </div>
        <div class="block mb-16">
          <p class="text-primary mb-4">Combo-6</p>
          <p class="mb-4">With everything</p>
        </div>
      </div>
      <div class="flex flex-justify-between">
        <span class="badge badge-warning">In Progress</span>
        <span class="badge badge-success">Ready</span>
      </div>
    </div>
  </div>
  <div class="table-box cursor-pointer" (click)="toggleClass()">
    <div class="flex flex-justify-between mb-24 table-datebox">
      <div class="text-grey-medium datetime-wrap">
        <span>28/11/2023</span>
        <span>11:45 AM</span>
      </div>
      <span class="fw-semibold text-grey-dark flex-shrink-0">#231128001</span>
    </div>
    <div>
      <div class="flex gap-8px flex-align-center flex-justify-between mb-16">
        <h4 class="text-white mb-0 fw-bold">Table 12</h4>
        <span class="fs-14 fw-medium text-grey-medium">3 items</span>
      </div>
      <div class="block mb-24">
        <div class="block mb-16">
          <p class="text-primary mb-4">American Style Burger</p>
          <p class="mb-4">No Tomatoes</p>
          <p class="mb-4">No Onion</p>
        </div>
        <div class="block mb-16">
          <p class="text-primary mb-4">Combo-6</p>
          <p class="mb-4">With everything</p>
        </div>
      </div>
      <div class="flex flex-justify-between">
        <span class="badge badge-warning">In Progress</span>
        <span class="badge badge-success">Ready</span>
      </div>
    </div>
  </div>
</div>
  


<!-- Starts HTML for detail Order -->
 <div class="detail-ordermenu flex flex-column " [class.active]="isClassAdded">
  <div class="flex flex-justify-between mb-20 flex-shrink-0">
    <div class="flex gap-8px flex-grow-1 flex-align-center flex-wrap">
      <h5 class="mb-0 fw-bold">Detail Order</h5>
      <span class="badge badge-success order-status">Ready to Serve</span>
    </div>
    <!-- Close Drawer -->
     <button class="flex-shrink-0 close-btn" mat-icon-button (click)="toggleClass()">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.992" fill-rule="evenodd" clip-rule="evenodd" d="M7.29688 -0.015625C7.75522 -0.015625 8.21353 -0.015625 8.67188 -0.015625C11.2075 0.259457 13.2022 1.43654 14.6562 3.51562C15.4006 4.66028 15.8433 5.91028 15.9844 7.26562C15.9844 7.74478 15.9844 8.22397 15.9844 8.70312C15.7001 11.2258 14.523 13.2102 12.4531 14.6562C11.2993 15.4071 10.0389 15.8498 8.67188 15.9844C8.21353 15.9844 7.75522 15.9844 7.29688 15.9844C4.80813 15.7118 2.83416 14.566 1.375 12.5469C0.592031 11.3802 0.128491 10.0989 -0.015625 8.70312C-0.015625 8.22397 -0.015625 7.74478 -0.015625 7.26562C0.268627 4.74291 1.44571 2.75852 3.51562 1.3125C4.66941 0.561612 5.92981 0.118903 7.29688 -0.015625ZM4.98438 4.51562C5.221 4.47762 5.42934 4.53491 5.60938 4.6875C6.39625 5.47959 7.18794 6.26606 7.98438 7.04688C8.81294 6.2235 9.64628 5.40581 10.4844 4.59375C10.8816 4.42409 11.1889 4.52306 11.4062 4.89062C11.475 5.093 11.4645 5.29091 11.375 5.48438C10.5629 6.32247 9.74525 7.15581 8.92188 7.98438C9.74525 8.81294 10.5629 9.64628 11.375 10.4844C11.5447 10.8816 11.4457 11.1889 11.0781 11.4062C10.8321 11.4891 10.6029 11.4578 10.3906 11.3125C9.59331 10.51 8.79125 9.71309 7.98438 8.92188C7.15581 9.74525 6.32247 10.5629 5.48438 11.375C5.08716 11.5447 4.77987 11.4457 4.5625 11.0781C4.49378 10.8757 4.50422 10.6778 4.59375 10.4844C5.40581 9.64628 6.2235 8.81294 7.04688 7.98438C6.2235 7.15581 5.40581 6.32247 4.59375 5.48438C4.41631 5.03159 4.5465 4.70869 4.98438 4.51562Z" fill="#03A7F6"/>
        </svg>        
     </button>
  </div>
  <div class="order-scroll flex-grow-1 custom-scroll">
    <div class="order-infobox flex flex-wrap mb-28 block">
      <div class="gap-12px w-full fs-12 block mb-12">
        <span class="text-block mb-4">Recipent</span>
        <h5 class="text-grey-medium fs-14 fw-bold">Akhmad Maariz</h5>
      </div>
      <div class="grid-2 w-full">
        <div class="gap-12px w-full fs-12 block mb-12 half-detailbx">
          <span class="text-block mb-4">Transaction ID</span>
          <h5 class="text-grey-medium fs-14 fw-bold">#231128001</h5>
        </div>
        <div class="gap-12px w-full fs-12 block mb-12 half-detailbx">
          <span class="text-block mb-4">Table Number</span>
          <h5 class="text-grey-medium fs-14 fw-bold">19</h5>
        </div>
      </div>
      <div class="gap-12px w-full fs-12 block mb-12">
        <span class="text-block mb-4">Cooking Instruction</span>
        <h5 class="text-grey-medium fs-14 fw-normal">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h5>
      </div>
    </div>

    <div class="product-list flex flex-column gap-20px">
      <div class="ordered-product gap-16px flex">
        <div class="ordered-productimg flex-shrink-0">
          <img src="assets/images/product/img1.jpg" alt="American Style Burger" class="img-fit" />
        </div>
        <div class="ordered-detailbox flex flex-column flex-justify-between flex-grow-1">
          <h5 class="text-white product-name mb-4">American Style Burger</h5>
          <div>
            <span class="text-primary mb-6 d-block fs-13">2 items</span>
            <div class="flex flex-justify-between flex-wrap flex-align-center fw-medium">
              <span class="fs-12 text-grey-medium">Total Price :</span>
              <span class="text-primary fs-16">$27.09</span>
            </div> 
          </div>
        </div>
      </div>
      <div class="ordered-product gap-16px flex">
        <div class="ordered-productimg flex-shrink-0">
          <img src="https://images.pexels.com/photos/6089662/pexels-photo-6089662.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Mocktail" class="img-fit" />
        </div>
        <div class="ordered-detailbox flex flex-column flex-justify-between flex-grow-1">
          <h5 class="text-white product-name mb-4">Mocktail</h5>
          <div>
            <span class="text-primary mb-6 d-block fs-13">2 items</span>
            <div class="flex flex-justify-between flex-wrap flex-align-center fw-medium">
              <span class="fs-12 text-grey-medium">Total Price :</span>
              <span class="text-primary fs-16">$55.17</span>
            </div> 
          </div>
        </div>
      </div>
      <div class="ordered-product gap-16px flex">
        <div class="ordered-productimg flex-shrink-0">
          <img src="assets/images/product/img1.jpg" alt="Burger French Fries Combo" class="img-fit" />
        </div>
        <div class="ordered-detailbox flex flex-column flex-justify-between flex-grow-1">
          <h5 class="text-white product-name mb-4">Burger French Fries Combo</h5>
          <div>
            <span class="text-primary mb-6 d-block fs-13">2 items</span>
            <div class="flex flex-justify-between flex-wrap flex-align-center fw-medium">
              <span class="fs-12 text-grey-medium">Total Price :</span>
              <span class="text-primary fs-16">$55.17</span>
            </div> 
          </div>
        </div>
      </div>
      <div class="btn-wrapper">
        <button mat-flat-button class="btn-secondary" (click)="toggleClass()">Back</button>
        <button mat-flat-button class=""(click)="toggleClass()">Modify Order</button>
      </div>
    </div>
  </div>

  <div class="order-totalbottom flex-shrink-0 block">
    <div class="total-paymentbox flex flex-justify-between gap-8px mb-20 flex-align-center">
      <span class="fs-14 fw-bold">Total Payment</span>
      <span class="fs-18 text-primary fw-bold">$55.17</span>
    </div>
    <button mat-flat-button class="btn-outline-primary w-full">Create New Order</button>
  </div>

 </div>
 <div class="overlay-drawer" (click)="toggleClass()"></div>
<!-- Ends HTML for detail Order -->