import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
// import { ToastrService } from 'ngx-toastr';
import { catchError, finalize, Subscriber, Subscription, throwError } from 'rxjs';
import { SettingsService } from '../../../services/settings.service';
import { ResponseMessage } from '../../../../../model/interfaces/req.res.interface';
import { ConstantService } from '../../../../../shared/services/constant.service';

@Component({
  selector: 'app-add-category',
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    CommonModule,
    TranslateModule
  ],
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit, OnDestroy {

  categoryFormGroup!: FormGroup;
  categoryImageFile: any;
  categoryDetail: any;
  imageFileName: any;
  subscribe!: Subscription;
  subscribeGetAPI!: Subscription;
  subscribeSubject!: Subscription;
  action = 'Add';
  fileTypeError = '';
  profileImageUrl: any;
  constantService = inject(ConstantService);

  // inject
  private fb = inject(FormBuilder);
  private settingService = inject(SettingsService);
  private toastr = inject(ToastrService);
  @ViewChild('fileInput') fileInput!: ElementRef;
  categoryProgressLoader = false;

  constructor() {
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.categoryProgressLoader = state;
    });
    if (this.subscribeSubject) this.subscribeSubject.unsubscribe();
    this.subscribeSubject = this.settingService.isShowCategory.subscribe(res => {
      if (res) {
        this.action = res.action;
        if (this.action == 'Edit') {
          this.constantService.progressLoader.next(true);
          this.getCategoryDetail(res.categoryData.category_id)
        }
      }
    })
  }

  initialCategoryForm() {
    this.categoryFormGroup = this.fb.group({
      categoryName: ['', Validators.required],
      categoryImage: ['']
    })
  }

  ngOnInit() {
    this.initialCategoryForm();
  }

  getCategoryDetail(id: number) {
    if (this.subscribeGetAPI) this.subscribeGetAPI.unsubscribe();
    this.subscribeGetAPI = this.settingService.getCategoryDetail(id).pipe(
      catchError((error) => {
        this.constantService.progressLoader.next(false);
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.categoryDetail = res.data;
          this.setFormData(res.data)
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  setFormData(data: any) {
    this.categoryFormGroup.patchValue({
      categoryName: data.name,
    })
    this.imageFileName = data.file_name;
    this.constantService.progressLoader.next(false);
  }
  //#region Add,update Category API
  addUpdateCategory() {
    this.categoryFormGroup.markAllAsTouched();
    if (this.categoryFormGroup.invalid) return;
    if (!this.categoryImageFile && this.action == 'Add') {
      this.fileTypeError = ' ';
      return;
    }

    let formData = new FormData();
    formData.append('name', this.categoryFormGroup.value.categoryName);
    if (this.categoryImageFile && this.categoryImageFile != undefined) formData.append('image', this.categoryImageFile);

    this.constantService.progressLoader.next(true);
    if (this.subscribe) this.subscribe.unsubscribe();
    if (this.action == 'Add') {
      this.subscribe = this.settingService.addCategory(formData).pipe(
        finalize(() => this.constantService.progressLoader.next(false)),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return throwError(() => error.error.message)
        })).subscribe((res: ResponseMessage) => {
          if (res.code === 200) {
            this.toastr.success(res.message);
            this.backToCategoryList();
          } else {
            this.toastr.error(res.message);
          }
        })
    } else if (this.action == 'Edit') {
      formData.append('category_id', this.categoryDetail.category_id)
      this.subscribe = this.settingService.updateCategory(formData).pipe(
        finalize(() => this.constantService.progressLoader.next(false)),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return throwError(() => error.error.message)
        })).subscribe((res: ResponseMessage) => {
          if (res.code === 200) {
            this.toastr.success(res.message);
            this.backToCategoryList();
          } else {
            this.toastr.error(res.message);
          }
        })
    }
  }
  //#endregion Add,update Category API

  backToCategoryList() {
    this.constantService.progressLoader.next(false);
    let obj = {
      displayList: true
    }
    this.settingService.isShowCategory.next(obj);
  }

  uploadCategoryImage(event: any) {

    const file = event.target.files[0];
    const fileSizeMB = file.size / (1024 * 1024);
    const imageExtensions = ['image/png', 'image/jpeg', 'image/jpg'];

    if (fileSizeMB > 5) {
      this.fileTypeError = 'image_file_5mb_validation';
      return;
    }

    if (!imageExtensions.includes(file.type.toLowerCase())) {
      this.fileTypeError = 'image_file_format_validation';
      return;
    }

    const reader = new FileReader();
    this.categoryImageFile = file;
    this.fileTypeError = '';
    this.imageFileName = file.name;
    reader.readAsDataURL(this.categoryImageFile);
    reader.onload = () => {
      this.profileImageUrl = reader.result;
    };
  }

  imageCheck() {
    this.fileInput.nativeElement.value = "";
    return null;
  }

  ngOnDestroy(): void {
    if (this.subscribe) this.subscribe.unsubscribe();
    if (this.subscribeGetAPI) this.subscribeGetAPI.unsubscribe();
    if (this.subscribeSubject) this.subscribeSubject.unsubscribe();
  }

}
