<div class="authentication-page flex flex-align-center flex-justify-center" [ngClass]="{'pointer-none': loader}">
  <ng-container>
    <button mat-flat-button class="btn-dark lang-btn" (click)="changeLanguage()">
      {{ language | languageName }}
  </button>
  </ng-container>
  <div class="authentication-box">
    <div class="auth-headerwrapper flex">
      <div class="authlogo-box">
        <div class="auth-box flex flex-align-center flex-justify-center">
          <img src="assets/images/logo-small.svg" alt="MuyBi" />
        </div>
      </div>
      <div class="auth-header">
        <h1>{{ "login_title" | translate }}</h1>
        <p class="text-grey">{{ "login_sub_title" | translate }}</p>
      </div>
    </div>
    <div class="auth-innerbox">
      <form [formGroup]="signInForm" (ngSubmit)="login()">
        <mat-radio-group formControlName="type" class="pb-16" (change)="onInputChange()">
          <mat-radio-button value="1">{{
            "email" | translate
            }}</mat-radio-button>
          <mat-radio-button value="2">{{
            "phone" | translate
            }}</mat-radio-button>
        </mat-radio-group>
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">

          <mat-label>
            <ng-container *ngIf="signInForm.controls.type.value=='1'">
              {{ "login_form.enter_your_email" | translate }}
            </ng-container>
            <ng-container *ngIf="signInForm.controls.type.value=='2'">
              {{ "login_form.enter_your_phone" | translate }}*
            </ng-container>
          </mat-label>
          <div class="flex w-full inputwith-btn flex-align-end">

            <ng-container *ngIf="signInForm.controls.type.value=='2'">

              <ngx-mat-intl-tel-input formControlName="phone" class="w-full" [preferredCountries]="preferredCountries"
                [enablePlaceholder]="true" [enableSearch]="true" name="userNamePhone" #userNamePhone>
                <!-- (input)="onPhoneChange($event)" -->
              </ngx-mat-intl-tel-input>
            </ng-container>
            <ng-container *ngIf="signInForm.controls.type.value=='1'">
              <input matInput formControlName="userName" />
            </ng-container>
          </div>
        </mat-form-field>
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-6">
          <mat-label>{{ "password" | translate }}</mat-label>
          <div class="password-field w-full" [class.active]="hidePass">
            <input matInput [type]="hidePass ? 'text' : 'password'" formControlName="password" required />
            <span class="eye-icon" (click)="hidePass = !hidePass"></span>
          </div>
        </mat-form-field>
        <div class="text-end mb-16">
          <a mat-button routerLink="/forgot-password" class="fs-13">
            {{ "login_form.forgot_password" | translate }}
          </a>
        </div>
        <button mat-flat-button type="submit" class="w-full mb-24 btn-lg">
          <mat-spinner *ngIf="loader" [diameter]="spinnerDiameter"
            color="var(--mdc-filled-button-label-text-color)"></mat-spinner>
          <ng-container *ngIf="!loader">
            {{ "sign_in" | translate }}
          </ng-container>
        </button>
        <div class="text-grey text-center">
          {{ "login_form.dont_have_account" | translate }}
          <a mat-button routerLink="/signup" class="text-link">{{
            "sign_up" | translate
            }}</a>
        </div>
      </form>
    </div>
  </div>
</div>