<div class="authentication-page flex flex-align-center flex-justify-center" [ngClass]="{'pointer-none': loader}">
  <button mat-flat-button class="btn-dark lang-btn" (click)="changeLanguage()">
    {{ language | languageName }}
</button>
  <div class="authentication-box">
    <div class="auth-headerwrapper flex">
      <div class="authlogo-box">
        <div class="auth-box flex flex-align-center flex-justify-center">
          <img src="assets/images/logo-small.svg" alt="MuyBi" />
        </div>
      </div>
      <div class="auth-header">
        <h1>{{'reset_password' | translate}}</h1>
        <p class="text-grey">{{'dont_worry' | translate}}</p>
      </div>
    </div>
    <div class="auth-innerbox">
      <form [formGroup]="resetPasswordForm" appPasswordMatchAndValidator>
        <!-- New Password Field -->
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
          <mat-label>{{ 'new_password' | translate }}</mat-label>
          <div class="password-field w-full" [class.active]="hidePass">
            <input matInput matNativeControl [type]="hidePass ? 'text' : 'password'" formControlName="password" />
            <span class="eye-icon" (click)="hidePass = !hidePass"></span>
          </div>

          <!-- Error messages for password field -->
          <!-- <mat-error *ngIf="resetPasswordForm.get('password')?.hasError('required')">
                    {{ 'Password is required' | translate }}
                  </mat-error> -->
          <mat-error *ngIf="resetPasswordForm.get('password')?.hasError('minLength')">
            {{ 'password_atleast_6_char_long' | translate }}
          </mat-error>
          <mat-error *ngIf="resetPasswordForm.get('password')?.hasError('passwordComplexity') && !resetPasswordForm.get('password')?.hasError('minLength')">
            {{ 'string_password_warn' | translate }}
          </mat-error>
        </mat-form-field>

        <!-- Confirm Password Field -->
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
          <mat-label>{{ 'confirm_pass' | translate }}</mat-label>
          <div class="password-field w-full" [class.active]="hideCPass">
            <input matInput matNativeControl [type]="hideCPass ? 'text' : 'password'" formControlName="confirmPassword" />
            <span class="eye-icon" (click)="hideCPass = !hideCPass"></span>
          </div>

          <!-- Error messages for confirm password field -->
          <mat-error *ngIf="resetPasswordForm.get('confirmPassword')?.hasError('passwordMismatch')">
            {{ 'password_not_match' | translate }}
          </mat-error>
        </mat-form-field>

        <!-- Submit Button -->
        <button mat-flat-button class="w-full mb-24 btn-lg" (click)="resetPass()">
          <mat-spinner *ngIf="loader" [diameter]="spinnerDiameter"
            color="var(--mdc-filled-button-label-text-color)"></mat-spinner>
          <ng-container *ngIf="!loader">
            {{ "submit" | translate }}
          </ng-container>
        </button>
        <div class="text-center">
          <a mat-button href="javascript:void(0)" (click)="redirectToLogin()">
            {{ "back_sign_in" | translate }}
          </a>
        </div>
      </form>

    </div>
  </div>
</div>