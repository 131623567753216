import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ConstantService } from '../../../services/constant.service';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  standalone: true,
  imports: [
    HeaderComponent,
    RouterModule,
    SidebarComponent,
    MatProgressBarModule,
    CommonModule,
    MatDialogModule,
  ],
})
export class LayoutComponent {
  constantService = inject(ConstantService)
  cdr = inject(ChangeDetectorRef)
  load = false;
  public isOpenSideBar = false;

  constructor() {
    // this.constantService.progressLoader.subscribe((state: boolean) => {
    //   this.load = state;
    // });
  }


  ngAfterViewInit(): void {
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.load = state;
      this.cdr.detectChanges(); // Triggers change detection
    });
  }
}
