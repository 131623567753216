import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { InvoiceService } from '../service/invoice.service';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { ConstantService } from '../../../../shared/services/constant.service';
import { ToastrService } from 'ngx-toastr';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule, NativeDateAdapter } from '@angular/material/core';
import { MenuService } from '../../menu/services/menu.service';
import { EncryptionService } from '../../../../shared/services/encryption.service';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';

@Component({
  selector: 'app-invoice-list',
  standalone: true,
  providers: [
    { provide: NativeDateAdapter, useClass: NativeDateAdapter },
    EncryptionService,
    DatePipe, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  imports: [
    DatePipe,
    MatButtonModule,
    RouterModule,
    MatTableModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    TranslateModule,
    InfiniteScrollModule,
    FormsModule,
    CommonModule
  ],
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent {
  invoiceService = inject(InvoiceService);
  constantService = inject(ConstantService);
  encDecObj = inject(EncryptionService);
  menuService = inject(MenuService);
  toastr = inject(ToastrService);
  datePipe = inject(DatePipe);
  route = inject(Router);
  invoiceList: any = [];
  pageNumber: any = 1;
  perPages: number = 25;
  totalRecord: any;
  selectProduct: any;
  productList: any = [];
  selectVendorId: any;
  vendorList: any = [];
  selectFromDate: any;
  selectToDate: any;
  searchText: any;
  searchInvoice: any;
  selectBranch: any;
  branchList: any;
  dataSourceInvoice: any;
  selectSortBy: any = 1;
  dateSelectedFrom: any;
  dateSelectedTo: any;
  draftAmount: any;
  last30DayAmount: any;
  invoiceProgressLoader = false;
  subscribeListAPI!: Subscription;
  defaultInvoiceSearchPayload = {
    searchValue: '',
    fromDate: '',
    toDate: '',
    product: 0,
    vendorId: 0,
    branch: 0,
    sortBy: 1
  };
  isResetButtonDisabled: boolean = true;
  invoiceSearchPayload: any = { ...this.defaultInvoiceSearchPayload };
  invoiceListPayload: any = {
    page: 1,
    per_page: 25,
    sort_by: 1,
    search: '',
    user_id: '',
    item_id: '',
    from_date: '',
    to_date: '',
    user_business_location_id: ''
  }
  sortOptions = [
    { value: 1, label: 'ASC', transalteLabel: 'asc' },
    { value: 2, label: 'DESC', transalteLabel: 'desc' }
  ];
  selectedBranchLocation = 'select_business_location';
  selectedBranchLocationId = 0;
  displayedColumns: string[] = ['date', 'customerName', 'id', 'title', 'status', 'amount', 'action'];
  dataSource: any = [];
  currentDate = new Date()
  constructor() {
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.invoiceProgressLoader = state;
    });
  }
  ngOnInit() {
    this.constantService.progressLoader.next(true);
    this.getInvoiceList();
    this.getBranches();
    this.getProducts();
    this.getVendors();
  }
  getVendors() {
    this.constantService.progressLoader.next(true);
    this.invoiceService.getVendorList().pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err: any) => {
        return throwError(() => err.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code == 200) {
          this.vendorList = res.data;
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  getBusinesslocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.selectedBranchLocationId = Number(id);
    }
  }
  getProducts() {
    this.getBusinesslocation();
    let payload = {
      business_location_id: this.selectedBranchLocationId,
    }
    this.invoiceService.getProduct(payload).pipe(catchError((error) => {
      this.toastr.error(error.error.message);
      return throwError(() => error.error.message);
    })).subscribe((res: ResponseMessage) => {
      if (res.code === 200) {
        this.productList = res.data.data;
      } else {
        this.toastr.error(res.message);
      }
    });
  }
  getBranches() {
    this.constantService.getBranchList().pipe(
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.branchList = res.data.data;
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  getInvoiceList() {
    this.pageNumber = 1;
    let payload = {
      page: this.pageNumber,
      per_page: this.perPages,
      search: this.invoiceSearchPayload?.searchValue,
      from_date: this.invoiceSearchPayload?.fromDate,
      to_date: this.invoiceSearchPayload?.toDate,
      item_id: this.invoiceSearchPayload?.product,
      user_id: this.invoiceSearchPayload?.vendorId,
      user_business_location_id: this.invoiceSearchPayload?.branch,
      sort_by: this.invoiceSearchPayload?.sortBy,
      business_location_id: this.selectedBranchLocationId,
    }
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeListAPI = this.invoiceService.getInvoiceList(payload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err: any) => {
        return throwError(() => err.error.message)
      }
      )).subscribe((res: ResponseMessage) => {
        if (res.code == 200) {
          this.invoiceList = res.data.data;
          this.last30DayAmount = res.data.paidAmount;
          this.draftAmount = res.data.draftAmount;
          this.last30DayAmount = parseFloat(Math.round(+this.last30DayAmount * 100) / 100 as any).toFixed(2)
          this.draftAmount = parseFloat(Math.round(+this.draftAmount * 100) / 100 as any).toFixed(2)
          this.totalRecord = res.data.pagination.total;
          this.dataSourceInvoice = new MatTableDataSource(res.data.data);
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  InvoiceListScrollDown() {
    this.pageNumber += this.pageNumber;
    let invoicePayload = {
      page: this.pageNumber,
      per_page: this.perPages,
      business_location_id: this.selectedBranchLocationId,
    }
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeListAPI = this.invoiceService.getInvoiceList(invoicePayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err: any) => {
        return throwError(() => err.error.message)
      }
      )).subscribe((res: ResponseMessage) => {
        if (res.code == 200) {
          this.invoiceList = [...this.invoiceList, ...res.data.data];
          this.totalRecord = res.data.pagination.total;
          this.dataSourceInvoice = new MatTableDataSource(this.invoiceList);
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  selectSearch(type: number, value: any) {
    const payloadMap: any = {
      1: { key: 'fromDate', transform: true },
      2: { key: 'toDate', transform: true },
      3: { key: 'product' },
      4: { key: 'vendorId' },
      5: { key: 'branch' },
      6: { key: 'sortBy' },
    };

    const payloadItem = payloadMap[type];
    if (!payloadItem) {
      return;
    }

    if (value == 0 && (this.invoiceSearchPayload[payloadItem.key] == '' || this.invoiceSearchPayload[payloadItem.key] == 0)) {
      return;
    }

    this.invoiceSearchPayload[payloadItem.key] = payloadItem.transform ?
      this.datePipe.transform(value, 'yyyy-MM-dd') : value;

    this.getInvoiceList();
    this.checkResetButtonShouldBeDisabled();
  }

  searchInvoices() {
    this.searchInvoice = this.searchInvoice.trimStart();
    const searchText = this.searchInvoice.trim();
    if (searchText !== this.invoiceSearchPayload.searchValue) {
      if (searchText && searchText.length > 1) {
        this.invoiceSearchPayload.searchValue = searchText;
        this.getInvoiceList();
        this.checkResetButtonShouldBeDisabled();
      } else if (!searchText && this.invoiceSearchPayload.searchValue !== '') {
        this.invoiceSearchPayload.searchValue = '';
        this.checkResetButtonShouldBeDisabled();
        this.getInvoiceList();
      }
    }
  }

  checkResetButtonShouldBeDisabled(): void {
    this.isResetButtonDisabled = JSON.stringify(this.invoiceSearchPayload) === JSON.stringify(this.defaultInvoiceSearchPayload);
  }

  resetFilter() {
    this.invoiceSearchPayload = {
      searchValue: '',
      fromDate: '',
      toDate: '',
      product: 0,
      vendorId: 0,
      branch: 0,
      sortBy: 1
    };

    this.selectProduct = this.selectBranch = this.selectVendorId = 0;
    this.selectSortBy = this.pageNumber = 1;
    this.selectFromDate = this.selectToDate = this.searchInvoice = '';

    this.getInvoiceList();
    this.isResetButtonDisabled = true;
  }

  createEditInvoice(action: string, id?: number) {
    if (action == 'Create') {
      this.route.navigate(['/invoice/create'])
    }
    else if (action == 'Edit' && id) {
      this.route.navigate(['/invoice/edit', id])
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
  }
}
