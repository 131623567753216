import { CommonModule } from '@angular/common';
import { Component, Inject, inject, OnInit, Optional } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ProductsService } from '../Service/products.service';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { TranslateModule } from '@ngx-translate/core';
import { ConstantService } from '../../../../shared/services/constant.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ItemUnitPipe } from 'src/app/shared/pipes/item-unit.pipe';

@Component({
  selector: 'app-product-view',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    RouterModule,
    ItemUnitPipe,
    TranslateModule
  ],
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss']
})
export class ProductViewComponent implements OnInit {

  subscribeGetAPI!: Subscription;
  productDetail: any;
  productId = 0;
  localData: any;
  isOpenDialog: boolean = false;
  private productService = inject(ProductsService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private toastr = inject(ToastrService);
  private constantService = inject(ConstantService);
  constructor(@Optional() private dialogRef: MatDialogRef<ProductViewComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.localData = { ...data };
    // 1 = Inventory management, 2 = Bar Inventory
    if (this.localData && (this.localData.type == 1 || this.localData.type == 2)) {
      this.isOpenDialog = true;
    }
  }

  ngOnInit() {
    const id = this.isOpenDialog ? this.localData.id : this.route.snapshot.params['id'];
    if (id) {
      this.constantService.progressLoader.next(true);
      const type = this.isOpenDialog ? 1 : '';
      this.getProductDetail(id, type);
    }
  }


  getProductDetail(id: number, type: any) {
    if (this.subscribeGetAPI) this.subscribeGetAPI.unsubscribe();
    this.subscribeGetAPI = this.productService.getProductDetail(id, type).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.productDetail = res.data;
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  backToProductList() {
    this.router.navigate(['/product-list']);
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
