import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxcharlength',
  standalone: true
})
export class MaxcharlengthPipe implements PipeTransform {
  transform(value: string, ...args: any[]): unknown {
    return value.length > args[0] ? value.slice(0, args[0])+'...' : value;
  }
}
