import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConstantService } from '../../../shared/services/constant.service';
import { AuthService } from '../auth.service';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { spinnerDiameter } from 'src/app/model/const/common.const';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-logout',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    TranslateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {

  private toastr = inject(ToastrService);
  private constantService = inject(ConstantService);
  private authService = inject(AuthService);
  private router = inject(Router);
  private dialogRef = inject(MatDialogRef<LogoutComponent>);
  private subscription!: Subscription;
  loader: boolean = false;
  spinnerDiameter = spinnerDiameter;
  language = 'selected_language';
  theme = 'selected_theme';
  logout() {
    this.logoutAPI();
  }

  logoutAPI() {
    if (this.subscription) this.subscription.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.loader = true;
    this.subscription = this.authService.logOut().pipe(
      finalize(() => (this.constantService.progressLoader.next(false), this.loader = false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.checkThemeLanguage(res.message)
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  checkThemeLanguage(message: string) {
    this.dialogRef.close();
    this.constantService.logOutUser();
    this.toastr.success(message);
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }


}
