<mat-dialog-content class="text-center">
  <div class="mb-24 radius-12">
    <img src="assets/images/table-ic.png" alt="Add Table" class="dialog-smallimg" />
  </div>
  <div class="block text-grey-light mb-24">
    <h3 class="h1 mb-24 text-white">{{'add_table' | translate}}</h3>
    <p>{{'fill_in_new_detail' | translate}}</p>
  </div>
  <form [formGroup]="addTableForm">]
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
      <mat-label>{{'table_name' | translate}}</mat-label>
      <input matInput formControlName="table_name" />
    </mat-form-field>
    <div class="btn-box text-center">
      <button mat-flat-button (click)="cancel(false)" class="btn-outline-primary">{{'cancel' | translate}}</button>
      <button mat-flat-button (click)="addTable()">{{'add_now' | translate}}</button>
    </div>
  </form>
</mat-dialog-content>