<mat-dialog-content class="text-center">
  <div class="mb-24 radius-12">
    <img src="assets/images/tip.svg" alt="Add Tip" class="dialog-smallimg" />
  </div>
  <div class="block text-grey-light mb-24">
    <h3 class="h1 text-white">{{ 'add_tip' | translate}}</h3>
  </div>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
    <mat-label>{{ 'enter_tip_amount' | translate}}</mat-label>
    <input matInput [(ngModel)]="amount" allowFlotNumberOnly />
  </mat-form-field>
  <div class="btn-box text-center">
    <button mat-flat-button mat-dialog-close class="btn-outline-primary">{{ 'cancel' | translate}}</button>
    <button mat-flat-button type="submit" (click)="submit()">{{ 'add_now' | translate}}</button>
  </div>
</mat-dialog-content>