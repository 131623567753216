import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, NativeDateAdapter, } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { ViewOrderHistoryComponent } from './view-order-history/view-order-history.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
export interface PeriodicElement {
  amount: string;
  dateTime: string;
  collectedFrom: string;
  paymentMethod: string;
  cashierName: string,
  totalAmount: number,
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    amount:
      'Carla Kenter',
    dateTime: '7/18/2024 03:32PM',
    collectedFrom: 'Cooper Septimus',
    paymentMethod: 'Card',
    cashierName: 'T-01',
    totalAmount: 1225.24
  },
  {
    amount:
      'Carla Kenter',
    dateTime: '7/18/2024 03:32PM',
    collectedFrom: 'Cooper Septimus',
    paymentMethod: 'Card',
    cashierName: 'T-01',
    totalAmount: 1225.24
  },
  {
    amount:
      'Carla Kenter',
    dateTime: '7/18/2024 03:32PM',
    collectedFrom: 'Cooper Septimus',
    paymentMethod: 'Card',
    cashierName: 'T-01',
    totalAmount: 1225.24
  },
  {
    amount:
      'Carla Kenter',
    dateTime: '7/18/2024 03:32PM',
    collectedFrom: 'Cooper Septimus',
    paymentMethod: 'Card',
    cashierName: 'T-01',
    totalAmount: 1225.24
  },
  {
    amount:
      'Carla Kenter',
    dateTime: '7/18/2024 03:32PM',
    collectedFrom: 'Cooper Septimus',
    paymentMethod: 'Card',
    cashierName: 'T-01',
    totalAmount: 1225.24
  },
  {
    amount:
      'Carla Kenter',
    dateTime: '7/18/2024 03:32PM',
    collectedFrom: 'Cooper Septimus',
    paymentMethod: 'Card',
    cashierName: 'T-01',
    totalAmount: 1225.24
  },
  {
    amount:
      'Carla Kenter',
    dateTime: '7/18/2024 03:32PM',
    collectedFrom: 'Cooper Septimus',
    paymentMethod: 'Card',
    cashierName: 'T-01',
    totalAmount: 1225.24
  },
  {
    amount:
      'Carla Kenter',
    dateTime: '7/18/2024 03:32PM',
    collectedFrom: 'Cooper Septimus',
    paymentMethod: 'Card',
    cashierName: 'T-01',
    totalAmount: 1225.24
  },
  {
    amount:
      'Carla Kenter',
    dateTime: '7/18/2024 03:32PM',
    collectedFrom: 'Cooper Septimus',
    paymentMethod: 'Card',
    cashierName: 'T-01',
    totalAmount: 1225.24
  },
  {
    amount:
      'Carla Kenter',
    dateTime: '7/18/2024 03:32PM',
    collectedFrom: 'Cooper Septimus',
    paymentMethod: 'Card',
    cashierName: 'T-01',
    totalAmount: 1225.24
  },
];
@Component({
  selector: 'app-order-history',
  standalone: true,
  providers: [
    { provide: NativeDateAdapter, useClass: NativeDateAdapter }
  ],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatNativeDateModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent {
  displayedColumns: string[] = ['amount','dateTime',  'collectedFrom', 'paymentMethod', 'cashierName', 'totalAmount', 'viewReceipt'];
  dataSource = ELEMENT_DATA;

  readonly dialog = inject(MatDialog);
  openDialog() {
    const dialogRef = this.dialog.open(ViewOrderHistoryComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
      scrollStrategy: new NoopScrollStrategy()
    });
  }
}
