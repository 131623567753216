import { Component,inject } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatExpansionModule} from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SplitPaymentComponent } from '../../components/split-payment/split-payment.component';
import { SendPaymentLinkComponent } from '../../components/send-payment-link/send-payment-link.component';

@Component({
  selector: 'app-billing',
  standalone: true,
  imports: [
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatButtonModule,
    MatTooltipModule
  ],
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent {
   panelOpenState = true;

  readonly dialog = inject(MatDialog);
  openDialog() {
    const dialogRef = this.dialog.open(SplitPaymentComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
      scrollStrategy: new NoopScrollStrategy()
    });
  }
  sendPaymentLinkDialog() {
    const dialogRef = this.dialog.open(SendPaymentLinkComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
      scrollStrategy: new NoopScrollStrategy()
    });
  }

  panel(type: boolean) {
    this.panelOpenState = type;
  }
  
}
