import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { GenerateQrComponent } from '../generate-qr/generate-qr.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-setup-online-order',
  standalone: true,
  imports: [
    RouterModule,
    MatSelectModule,
    MatFormFieldModule,
    MatRadioModule,
    MatInputModule,
    MatButtonModule
  ],
  templateUrl: './setup-online-order.component.html',
  styleUrls: ['./setup-online-order.component.scss']
})
export class SetupOnlineOrderComponent {
  readonly dialog = inject(MatDialog);
  openDialog() {
    const dialogRef = this.dialog.open(GenerateQrComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
      scrollStrategy: new NoopScrollStrategy()
    });
  }
}
