import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { NativeDateAdapter } from '@angular/material/core';
import { ReservationService } from '../services/reservation.service';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConstantService } from 'src/app/shared/services/constant.service';
import { ToastrService } from 'ngx-toastr';
import { catchError, finalize, throwError } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-add-reservation',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  providers: [
    { provide: NativeDateAdapter, useClass: NativeDateAdapter }
  ],
  templateUrl: './add-reservation.component.html',
  styleUrls: ['./add-reservation.component.scss']
})
export class AddReservationComponent {
  private reservationService = inject(ReservationService);
  private fb = inject(FormBuilder);
  private route = inject(Router);
  private activateRoute = inject(ActivatedRoute);
  private constantService = inject(ConstantService);
  private toastr = inject(ToastrService);
  editId = this.activateRoute.snapshot.params['id'];
  addReservationForm: any;



  initialReservationForm() {
    this.addReservationForm = this.fb.group({
      id: [''],
      customer_name: ['', Validators.required],
      mobile_number: ['', Validators.required],
      no_of_guests: ['', Validators.required],
      select_date: ['', Validators.required],
      select_time: ['', Validators.required],
      select_table: ['', Validators.required],
      event: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.initialReservationForm();
  }

  addUpdateReservation() {
    if (this.addReservationForm.invalid) {
      return;
    }
    this.constantService.progressLoader.next(true);
    if (this.editId) {
      this.addReservationForm.patchValue({ id: this.editId })
      this.reservationService.updateReservation(this.addReservationForm.value).pipe(
        finalize(() => this.constantService.progressLoader.next(false)),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return throwError(() => error.error.message);
        })).subscribe((res: any) => {
          if (res.code == 200) {
            this.toastr.success(res.message);
          } else {
            this.toastr.error(res.message);
          }
        })
    } else {
      this.reservationService.addReservation(this.addReservationForm.value).pipe(
        finalize(() => this.constantService.progressLoader.next(false)),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return throwError(() => error.error.message);
        })).subscribe((res: any) => {
          if (res.code == 200) {
            this.toastr.success(res.message);
          } else {
            this.toastr.error(res.message);
          }
        })
    }
  }

  backToReservationList() {
    this.route.navigate(['/reservation'])
  }
}

