import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  standalone: true,
  selector: '[appPasswordMatchAndValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PasswordMatchAndValidatorDirective,
      multi: true
    }
  ]
})
export class PasswordMatchAndValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    const passwordControl = control.get('password');
    const confirmPasswordControl = control.get('confirmPassword');

    if (!passwordControl || !confirmPasswordControl) {
      return null;
    }

    const password = passwordControl.value;
    const confirmPassword = confirmPasswordControl.value;

    // Minimum length validation
    const minLength = 6; // Define your minimum length here
    const isMinLengthValid = password.length >= minLength;

    // Password complexity validation
    const hasCapitalAlphabet = /[A-Z]/.test(password);
    const hasLowerAlphabet = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    const validComplexity = hasCapitalAlphabet && hasLowerAlphabet && hasNumber && hasSpecial;

    // Password mismatch validation
    const passwordMismatch = password !== confirmPassword;

    // Set errors on confirmPassword if passwords don't match
    if (passwordMismatch) {
      confirmPasswordControl.setErrors({ passwordMismatch: true });
    } else {
      if (confirmPasswordControl.hasError('passwordMismatch')) {
        confirmPasswordControl.setErrors(null);
      }
    }

    // Return errors for complexity, mismatch, or length
    const errors: ValidationErrors = {};

    if (!isMinLengthValid) {
      errors.minLength = true;
    }

    if (!validComplexity) {
      errors.passwordComplexity = true;
    }

    if (Object.keys(errors).length > 0) {
      passwordControl.setErrors(errors);
    }

    return null;
  }
}
