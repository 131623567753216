import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup, MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import {
  catchError,
  concat,
  forkJoin,
  Subscription,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';

// Components
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';

// Services
import { AuthService } from '../auth.service';
import { defaultCountry, spinnerDiameter } from '../../../model/const/common.const';
import { BusinessDetailFormValues } from '../../../model/interfaces/auth.interface';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { ConstantService } from '../../../shared/services/constant.service';
import { NumberOnlyDirective } from '../../../shared/directives/number-only.directive';
import { EncryptionService } from '../../../shared/services/encryption.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-signup-business-details',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxGpAutocompleteModule,
    NgxMatIntlTelInputComponent,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    RouterModule,
    TranslateModule,
    NumberOnlyDirective,
    MatProgressSpinnerModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './signup-business-details.component.html',
  styleUrls: ['./signup-business-details.component.scss'],
})
export class SignupBusinessDetailsComponent implements OnInit {
  private fb = inject(FormBuilder);
  private authService = inject(AuthService);
  private constantService = inject(ConstantService);
  private cdr = inject(ChangeDetectorRef);
  private subscription!: Subscription;
  private toastr = inject(ToastrService);
  private encDecObj = inject(EncryptionService);
  public submitDisabled = false;
  public states: any = [];
  public cities: any = [];
  public city: any = [];
  public retailQuestion = [];
  public restaurantQuestion = [];
  public previousFormValue: any = {};
  public userDetail = '';
  loader: boolean = false;
  spinnerDiameter = spinnerDiameter;

  public signupBusinessDetailsForm = this.fb.group({
    businessType: ['1', Validators.required],
    businessName: ['', Validators.required],
    phone: ['', [Validators.required]],
    estimatedAnnualRevenue: ['', [Validators.required, Validators.min(1)]],
    isPhysicalLocation: ['2', Validators.required],
    streetNumber: [''],
    streetName: [''],
    apartment: [''],
    building: [''],
    unit: [''],
    cityId: [{ value: '', disabled: true }],
    stateId: [''],
    postcode: [''],
    businessBranch: ['1', Validators.required],
    questionAnswer: this.fb.array([]),
  });

  get questionAnswer(): FormArray {
    return this.signupBusinessDetailsForm.get('questionAnswer') as FormArray;
  }
  preferredCountries: Array<string> = [];
  // @Input() preferredCountries: Array<string> = ['us'];
  @Input() set businessDetailFormData(value: BusinessDetailFormValues | null) {
    if (value) {
      // Set the previous form value and update it once the dynamic question response is received from API
      this.previousFormValue = value;
    }
  }

  @Output() submittedBusinessDetails =
    new EventEmitter<BusinessDetailFormValues>();

  businessQuestions$ = this.authService.businessQuestionBaseBusinessType().pipe(
    tap((res) => {
      this.retailQuestion = res.data.retail_questions;
      this.restaurantQuestion = res.data.restaurant_questions;

      // After API response patch form values
      const previousFormValue = this.previousFormValue;
      if (Object.keys(previousFormValue).length) {
        this.setDynamicQuestionBasedOnType(previousFormValue.businessType);
        this.signupBusinessDetailsForm.patchValue(
          { ...previousFormValue },
          { onlySelf: true, emitEvent: false }
        );
        if (previousFormValue.stateId) {
          this.getCities(Number(previousFormValue.stateId));
        }
      } else if (this.signupBusinessDetailsForm.value.businessType) {
        this.setDynamicQuestionBasedOnType(
          this.signupBusinessDetailsForm.value.businessType
        );
      }
    }),
    switchMap(
      () => this.signupBusinessDetailsForm.controls.businessType.valueChanges
    ),
    tap((value) => {
      if (value) {
        this.setDynamicQuestionBasedOnType(value);
      }
    })
  );

  physicalLocationInputChange$ =
    this.signupBusinessDetailsForm.controls.isPhysicalLocation.valueChanges.pipe(
      tap((value) => {
        const fields = [
          'streetNumber',
          'streetName',
          'apartment',
          'building',
          'unit',
          'cityId',
          'stateId',
          'postcode',
        ];

        if (value === '1') {
          fields.forEach((field) => {
            (this.signupBusinessDetailsForm.controls as any)[
              field
            ].setValidators([Validators.required]);
          });
        } else {
          fields.forEach((field) => {
            (this.signupBusinessDetailsForm.controls as any)[
              field
            ].setValidators([]);
          });
        }
        fields.forEach((field) => {
          (this.signupBusinessDetailsForm.controls as any)[
            field
          ].updateValueAndValidity();
        });
      })
    );

  ngOnInit() {
    this.preferredCountries = this.authService.getPreferredCountries();
    this.constantService.getState(defaultCountry).subscribe((response) => {
      if (response.data.length > 0) {
        this.states = response.data;
      }
    });
    localStorage.setItem('currentStep', '2')
  }

  setDynamicQuestionBasedOnType(value: string) {
    let questions: any = [];
    if (value === '1') {
      questions = this.retailQuestion;
    } else {
      questions = this.restaurantQuestion;
    }
    this.questionAnswer.clear();
    questions.forEach((data: any) => {
      this.questionAnswer.push(
        this.fb.group({
          question: [data.question],
          question_id: [data.id],
          answer: ['', Validators.required],
        })
      );
    });
    this.cdr.detectChanges(); // Mark for change detection
  }

  getCities(stateId: number) {
    this.constantService.getCity(stateId).subscribe((response) => {
      if (response.data.length > 0) {
        this.cities = response.data;
        this.signupBusinessDetailsForm.controls.cityId.enable();
      }
    });
  }

  setSubmitDisabledDisabled(value: boolean): void {
    this.submitDisabled = value;
  }

  formSubmit(value: any): void {
    if (this.subscription) this.subscription.unsubscribe();
    if (this.signupBusinessDetailsForm.invalid) {
      return;
    }
    const data = {
      business_type: value.businessType,
      business_name: value.businessName,
      phone_number: value.phone,
      estimated_annual_revenue: value.estimatedAnnualRevenue,
      is_physical_location: value.isPhysicalLocation,
      business_branch: value.businessBranch,
      number: value.streetNumber,
      street_name: value.streetName,
      appartment: value.apartment,
      city_id: value.cityId || '',
      state_id: value.stateId,
      postcode: value.postcode,
      building: value.building,
      unit: value.unit,
      question_answer: JSON.stringify(value.questionAnswer),
    };
    this.setSubmitDisabledDisabled(true);
    this.loader = true;
    this.subscription = this.authService
      .addBusinessDetails(data)
      .pipe(
        tap((res: ResponseMessage) => {
          this.setSubmitDisabledDisabled(false);
          this.loader = false;
          if (res.code === 200) {
            // localStorage.setItem('token', res.data.token);
            // this.userDetail.set(res.data);
            // const userDetail = this.encDecObj.setEncrypt(
            //   JSON.stringify(this.userDetail())
            // );
            this.constantService.selectLocation(res.data?.business_location_id);
            this.authService.removePreferredCountries();
            this.submittedBusinessDetails.emit(value);
          } else {
            this.toastr.error(res.message);
          }
        }),
        catchError((err) => {
          this.toastr.error(err.error.message);
          this.setSubmitDisabledDisabled(false);
          this.loader = false;
          return throwError(() => err.error.message);
        })
      )
      .subscribe();
  }
  ngOnDestroy() {
    localStorage.removeItem('currentStep');
    if (this.subscription) this.subscription.unsubscribe();
  }
}
