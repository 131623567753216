import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Router, RouterModule } from '@angular/router';
import { InfiniteScrollDirective, InfiniteScrollModule } from 'ngx-infinite-scroll';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { FoodMenuService } from '../service/food-menu.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { EncryptionService } from '../../../shared/services/encryption.service';
import { ConstantService } from '../../../shared/services/constant.service';
import { inventoryStatusArray, muyBiLogo } from '../../../model/const/common.const';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-food-list',
  standalone: true,
  imports: [
    RouterModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    InfiniteScrollModule,
    FormsModule,
    TranslateModule,
    CommonModule
  ],
  templateUrl: './food-list.component.html',
  styleUrls: ['./food-list.component.scss']
})
export class FoodListComponent {
  totalRecord: number = 0;
  subscribeListAPI!: Subscription;
  serachMenu: String = '';
  menuListData: any;
  private menuService = inject(FoodMenuService);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private encDecObj = inject(EncryptionService);
  constantService = inject(ConstantService)
  muybiLogo = muyBiLogo;
  selectedBranchLocation = 'select_business_location';
  selectedBranchLocationId = 0;
  pageNumber: number = 1;
  perPages: number = 25;
  inventoryStatus = inventoryStatusArray;
  defaultMenuSearchPayload: any = {
    searchValue: '',
    category: 0,
    branch: 0,
    inventory: 0,
    page: 1,
    business_location_id: ''
  };
  isResetButtonDisabled: boolean = true
  selectedInventory: any = 0;
  menuSearchPayload: any = { ...this.defaultMenuSearchPayload }
  selectCategory: any;
  selectBranch: any;
  categoryListData: any;
  branchListData: any;
  menuProgressLoader = false;
  constructor() {
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.menuProgressLoader = state;
    });
  }
  ngOnInit(): void {
    this.getCategories();
    this.getBranches();
    this.getMenuList();
  }

  //#region Menu List
  getBusinesslocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.selectedBranchLocationId = Number(id);
    }
  }
  getMenuList() {
    this.getBusinesslocation();
    this.pageNumber = 1;
    let menuPayload = {
      page: this.pageNumber,
      per_page: this.perPages,
      business_location_id: this.selectedBranchLocationId,
      search_text: this.menuSearchPayload.searchValue,
      category: this.menuSearchPayload.category,
      branch: this.menuSearchPayload.branch,
      inventory: this.menuSearchPayload.inventory,
    }
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeListAPI = this.menuService.getMenuList(menuPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.menuListData = res.data.data;
          this.totalRecord = res.data.pagination.total;
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  selectSearch(type: number, value: number) {
    // 1 = Category, 2 = Branch, 3 = Inventory
    if (type == 1 && value == 0 && this.menuSearchPayload.category == 0) {
      return;
    }
    if (type == 2 && value == 0 && this.menuSearchPayload.branch == 0) {
      return;
    }
    if (type == 3 && value == 0 && this.menuSearchPayload.inventory == 0) {
      return;
    }
    else if (type == 1) {
      this.menuSearchPayload.category = value;
    } else if (type == 2) {
      this.menuSearchPayload.branch = value;
    } else if (type == 3) {
      this.menuSearchPayload.inventory = value;
    }
    this.getMenuList();
    this.checkResetButtonShouldBeDisabled();
  }
  checkResetButtonShouldBeDisabled(): void {
    this.isResetButtonDisabled = JSON.stringify(this.menuSearchPayload) === JSON.stringify(this.defaultMenuSearchPayload);
  }
  getCategories() {
    this.menuService.getCategoryList().pipe(
      catchError((error) => {
        this.constantService.progressLoader.next(false);
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.categoryListData = res.data.data;
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  getBranches() {
    this.menuService.getBranceList().pipe(
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.branchListData = res.data.data;
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  resetFilter(): void {
    this.menuSearchPayload = { ...this.defaultMenuSearchPayload };
    this.checkResetButtonShouldBeDisabled();
    this.serachMenu = '';
    this.selectBranch = '';
    this.selectCategory = '';
    this.selectedInventory = '';
    this.isResetButtonDisabled = true;
    this.getMenuList();
  }
  menuListScrollDown() {
    this.pageNumber += this.pageNumber;
    let menuPayload = {
      page: this.pageNumber,
      per_page: this.perPages,
      business_location_id: this.selectedBranchLocationId,
      category: this.menuSearchPayload.category,
      branch: this.menuSearchPayload.branch,
      inventory: this.menuSearchPayload.inventory,
    }
    this.constantService.progressLoader.next(true);
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.subscribeListAPI = this.menuService.getMenuList(menuPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.totalRecord = res.data.pagination.total;
          this.menuListData = [...this.menuListData, ...res.data.data];
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  //#endregion menu List

  //#region Search Menu
  serachMenus() {
    this.serachMenu = this.serachMenu.trimStart();
    const searchText = this.serachMenu.trim();

    if (searchText !== this.menuSearchPayload.searchValue) {
      if (searchText && searchText.length > 1) {
        this.menuSearchPayload.searchValue = searchText;
        this.getMenuList();
      } else if (!searchText && this.menuSearchPayload.searchValue !== '') {
        this.menuSearchPayload.searchValue = '';
        this.getMenuList();
      }
    }
    this.checkResetButtonShouldBeDisabled();
  }

  //#endregion Search Menu

  createEditMenu(action: string, id?: number) {
    if (action == 'Add') {
      this.router.navigate(['/food-menu/add']);
    } else {
      this.router.navigate([`/food-menu/edit/${id}`]);
    }
  }

  ngOnDestroy(): void {
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
  }
}
