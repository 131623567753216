<mat-dialog-content class="text-center" [ngClass]="{'pointer-none': loader || resendLoader}">
  <div class="mb-20 flex flex-justify-center">
    <img src="assets/images/verification.png" alt="OTP Verification" class="dialog-smallimg" />
  </div>
  <div class="block text-grey mb-20">
    <h3 class="h1 mb-4 text-white">
      {{ modalData.title | translate }}
    </h3>
    <p innerHTML="{{ modalData.sub_title | translate : {phone: modalData.phone_number} }}"></p>
  </div>
  <form [formGroup]="otpForm" (ngSubmit)="verify()">
    <div class="mb-16">
      <ng-otp-input (onInputChange)="onOtpChange($event)"
        [config]="{ length: 6 ,disableAutoFocus:true,allowNumbersOnly:true}"></ng-otp-input>
    </div>
    <div class="btn-box mb-24">
      <button type="button" mat-stroked-button (click)="close()" class="w-full">
        {{ "cancel" | translate }}
      </button>
      <!-- <button type="submit" mat-flat-button class="w-full" [disabled]="sendSubmitDisabled"> -->
      <button type="submit" mat-flat-button class="w-full">
        <mat-spinner *ngIf="loader" [diameter]="spinnerDiameter"
          color="var(--mdc-filled-button-label-text-color)"></mat-spinner>
        <ng-container *ngIf="!loader">
          {{ "verify" | translate }}
        </ng-container>
      </button>
    </div>

    <ng-container *ngIf="modalData.resend">

      <div class="flex flex-justify-between otp-timerwrap">
        <span class="fs-13 remaining-time">{{ formattedRemainingTime }}</span>
        <span class="text-grey-light fs-14">
          {{ modalData.dont_receive | translate }}
          <div class="spinner flex flex-justify-center" *ngIf="resendLoader">
            <mat-spinner [diameter]="spinnerDiameter"
              color="var(----mdc-circular-progress-active-indicator-color)"></mat-spinner>
          </div>
          <ng-container *ngIf="!resendLoader">
            <a mat-button class="text-link otp-verificationlink" (click)="resend()" [disabled]="sendResendDisabled">
              {{ "resend" | translate }}
            </a>
          </ng-container>
        </span>
      </div>
    </ng-container>
  </form>
</mat-dialog-content>