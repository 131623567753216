import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { TransactionsService } from './Service/transactions.service';
import { ToastrService } from 'ngx-toastr';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { ConstantService } from '../../../../shared/services/constant.service';
import { EncryptionService } from '../../../../shared/services/encryption.service';
import { dayList, paymentMethod, paymentType } from '../../../../model/const/common.const';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { DateTime24HrPipe } from '../../../../shared/pipes/date-time-24hr.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PaymentMethodTypePipe } from '../../../../shared/pipes/payment-method-type.pipe';
import { MatDialog } from '@angular/material/dialog';
import { ViewTransactionDetailComponent } from './view-transaction-detail/view-transaction-detail.component';
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import { MatNativeDateModule } from '@angular/material/core';
@Component({
  selector: 'app-transaction-list',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatTableModule,
    MatButtonModule,
    MatNativeDateModule,
    MatIconModule,
    FormsModule,
    CommonModule,
    MatTooltipModule,
    DateTime24HrPipe,
    TranslateModule,
    InfiniteScrollModule,
    PaymentMethodTypePipe,
  ],
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss'],
  providers: [
    DatePipe,
    PaymentMethodTypePipe,
    DateTime24HrPipe,
    EncryptionService]
})
export class TransactionListComponent {
  displayedColumns: string[] = ['order_id', 'sub_total', 'transaction_date_time', 'collected_from', 'payment_method', 'cashier_name', 'total_amount', 'viewReceipt'];
  dataSourceTransactions: any;
  totalRecord: number = 0;
  subscribeListAPI!: Subscription;
  subscribeDeleteAPI!: Subscription;
  trasactionListData: any;
  trasactionTotalCount: any;
  dayLists = dayList;
  paymentMethods = paymentMethod;
  paymentTypes = paymentType;
  branchList: any;
  productList: any;
  selectDay = 0;
  selectPaymentMenthod = 0;
  selectPaymentType = 0;
  selectBranch = 0;
  selectProduct = 0;
  selectFromDate: any;
  selectToDate: any;
  maxDate: any;
  currentDate: any;
  transactionProgressLoader = false;

  defaultTransactionSearchPayload = {
    searchValue: '',
    fromDate: '',
    toDate: '',
    day: 0,
    paymentMethod: 0,
    paymentType: 0,
    branch: 0,
    product: 0,
  };
  isResetButtonDisabled: boolean = true;
  transactionSearchPayload: any = { ...this.defaultTransactionSearchPayload };
  selectedBranchLocation = 'select_business_location';
  selectedBranchLocationId = 0;
  pageNumber: number = 1;
  perPages: number = 25;
  serachTransaction: String = '';
  private transactionsService = inject(TransactionsService);
  private toastr = inject(ToastrService);
  private encDecObj = inject(EncryptionService);
  public constantService = inject(ConstantService);
  private datePipe = inject(DatePipe);
  private dialog = inject(MatDialog);
  private paymentMethodTypePipe = inject(PaymentMethodTypePipe);
  private dateTime24HrPipe = inject(DateTime24HrPipe);

  constructor() {
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.transactionProgressLoader = state;
    });
  }

  ngOnInit(): void {
    this.currentDate = new Date();
    this.constantService.progressLoader.next(true);
    this.getBranches();
    this.getProducts();
    this.getTransactionList();
  }

  getBusinesslocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.selectedBranchLocationId = Number(id);
    }
  }

  //#region Filters API's

  getProducts() {
    this.getBusinesslocation();
    let payload = {
      business_location_id: this.selectedBranchLocationId,
    }
    this.transactionsService.getProduct(payload).pipe(catchError((error) => {
      this.toastr.error(error.error.message);
      return throwError(() => error.error.message);
    })).subscribe((res: ResponseMessage) => {
      if (res.code === 200) {
        this.productList = res.data.data;
      } else {
        this.toastr.error(res.message);
      }
    });
  }

  getBranches() {
    this.transactionsService.getBranchList().pipe(
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.branchList = res.data.data;
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  //#endregion Filters API's

  //#region Transaction List

  getTransactionList() {
    this.pageNumber = 1;
    let payload = {
      page: this.pageNumber,
      per_page: this.perPages,
      search: this.transactionSearchPayload?.searchValue,
      from_date: this.transactionSearchPayload?.fromDate,
      to_date: this.transactionSearchPayload?.toDate,
      day_wise: this.transactionSearchPayload?.day,
      payment_method: this.transactionSearchPayload?.paymentMethod,
      transaction_type: this.transactionSearchPayload?.paymentType,
      user_business_location_id: this.transactionSearchPayload?.branch,
      item_id: this.transactionSearchPayload?.product,
    }
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeListAPI = this.transactionsService.getTrasactionList(payload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.trasactionListData = res.data.data;
          this.totalRecord = res.data.pagination.total;
          this.trasactionTotalCount = res.data.total_counts;
          this.dataSourceTransactions = new MatTableDataSource(res.data.data);
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  transactionListScrollDown() {
    this.pageNumber += this.pageNumber;
    let productPayload = {
      page: this.pageNumber,
      per_page: this.perPages,
      business_location_id: this.selectedBranchLocationId,
    }
    this.constantService.progressLoader.next(true);
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.subscribeListAPI = this.transactionsService.getTrasactionList(productPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.trasactionListData = [...this.trasactionListData, ...res.data.data];
          this.trasactionTotalCount = res.data.total_counts;
          this.totalRecord = res.data.pagination.total;
          this.dataSourceTransactions = new MatTableDataSource(this.trasactionListData);
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  //#endregion Transaction List

  //#region Search Transaction
  serachTransactions() {
    this.serachTransaction = this.serachTransaction.trimStart();
    const searchText = this.serachTransaction.trim();

    if (searchText !== this.transactionSearchPayload.searchValue) {
      if (searchText && searchText.length > 1) {
        this.transactionSearchPayload.searchValue = searchText;
        this.getTransactionList();
        this.checkResetButtonShouldBeDisabled();
      } else if (!searchText && this.transactionSearchPayload.searchValue !== '') {
        this.transactionSearchPayload.searchValue = '';
        this.checkResetButtonShouldBeDisabled();
        this.getTransactionList();
      }
    }
  }

  selectSearch(type: number, value: any) {
    const payloadMap: any = {
      1: { key: 'fromDate', transform: true },
      2: { key: 'toDate', transform: true },
      3: { key: 'day' },
      4: { key: 'paymentMethod' },
      5: { key: 'paymentType' },
      6: { key: 'branch' },
      7: { key: 'product' }
    };

    const payloadItem = payloadMap[type];
    if (!payloadItem) {
      return;
    }

    // Check if value is 0 and corresponding payload field is empty or zero
    if (value == 0 && (this.transactionSearchPayload[payloadItem.key] == '' || this.transactionSearchPayload[payloadItem.key] == 0)) {
      return;
    }

    // Apply the value or transform it if needed
    this.transactionSearchPayload[payloadItem.key] = payloadItem.transform ?
      this.datePipe.transform(value, 'yyyy-MM-dd') : value;

    this.getTransactionList();
    this.checkResetButtonShouldBeDisabled();
  }

  resetFilter() {
    // Reset transaction search payload
    this.transactionSearchPayload = {
      fromDate: '',
      toDate: '',
      day: 0,
      paymentMethod: 0,
      paymentType: 0,
      branch: 0,
      product: 0,
    };

    // Reset selected filter variables
    this.selectDay = this.selectPaymentMenthod = this.selectPaymentType =
      this.selectBranch = this.selectProduct = 0;

    // Reset selected date variables
    this.selectFromDate = this.selectToDate = this.serachTransaction = '';
    this.pageNumber = 1;
    // Refresh the transaction list
    this.getTransactionList();
    this.isResetButtonDisabled = true;
  }

  checkResetButtonShouldBeDisabled(): void {
    this.isResetButtonDisabled = JSON.stringify(this.transactionSearchPayload) === JSON.stringify(this.defaultTransactionSearchPayload);
  }

  //#endregion Search Transaction
  viewReceipt(orderId: number, transactionId: number) {
    let data = {
      orderId,
      transactionId
    };

    const dialogRef = this.dialog.open(ViewTransactionDetailComponent, {
      width: '600px',
      panelClass: '',
      disableClose: true,
      autoFocus: false,
      data
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res?.success) {

      }
    })
  }

  exportTransaction() {
    const headersData = [
      'ORDER ID',
      'AMOUNT',
      'DATE & TIME',
      'COLLECTED FROM',
      'PAYMENT METHOD',
      'CASHIER NAME',
      'TOTAL AMOUNT',
    ];

    const fileName = `Transactions_${moment().format('YYYY/MM/DD')}`;

    const options = {
      filename: fileName,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      headers: headersData,
    };

    const exportTransactionsData = this.trasactionListData.map((data: any) => ({
      'ORDER ID': data.order_id,
      'AMOUNT': `$${data.order_total_amount.toFixed(2)}`,
      'DATE & TIME': this.dateTime24HrPipe.transform(data.transaction_date_time) || '',
      'COLLECTED FROM': data.collected_from,
      'PAYMENT METHOD': this.paymentMethodTypePipe.transform(data.payment_method) || '',
      'CASHIER NAME': data.cashier_name,
      'TOTAL AMOUNT': `$${data.total_amount.toFixed(2)}`,
    }));
    this.exportFile(exportTransactionsData, options);
  }

  //With Total Counts
  // exportTransaction() {

  //   // Summary data
  //   const transactionCount = this.trasactionTotalCount.transaction_count || 0;
  //   const amountBeforeFees = this.trasactionTotalCount.amount_before_fees || 0;
  //   const amountAfterFees = this.trasactionTotalCount.amount_after_fees || 0;

  //   // Initial rows
  //   let exportTransactionsFinal = [
  //     [], // First row blank
  //     [
  //       'Number of Transactions',
  //       `${transactionCount}`,
  //       'Total Collection Before Fees',
  //       `${amountBeforeFees.toFixed(2)}`,
  //       'Total Net Sales After Fees',
  //       `${amountAfterFees.toFixed(2)}`,
  //     ],
  //     [], // Third row blank
  //   ];

  //   // Table headers and data
  //   const headersData = [
  //     'AMOUNT',
  //     'DATE & TIME',
  //     'COLLECTED FROM',
  //     'PAYMENT METHOD',
  //     'CASHIER NAME',
  //     'TOTAL AMOUNT',
  //   ];

  //   // Prepare the file name
  //   const fileName = `Transactions_${moment().format('YYYY/MM/DD')}`;

  //   // CSV options
  //   const options = {
  //     filename: fileName,
  //     fieldSeparator: ',',
  //     quoteStrings: '"',
  //     decimalSeparator: '.',
  //     showLabels: true,
  //     showTitle: false,
  //     useTextFile: false,
  //     useBom: true,
  //     headers: headersData,
  //   };

  //   // Map transaction data and add to the final array
  //   const exportTransactionsData = this.trasactionListData.map((data: any) => ({
  //     'AMOUNT': `$${data.sub_total.toFixed(2)}`,
  //     'DATE & TIME': this.dateTime24HrPipe.transform(data.transaction_date_time) || '',
  //     'COLLECTED FROM': data.collected_from,
  //     'PAYMENT METHOD': this.paymentMethodTypePipe.transform(data.payment_method) || '',
  //     'CASHIER NAME': data.cashier_name,
  //     'TOTAL AMOUNT': `$${data.total_amount.toFixed(2)}`,
  //   }));

  //   // Append headers and data to final array
  //   exportTransactionsFinal.push(headersData); // Adding headers as the fourth row
  //   exportTransactionsFinal.push(...exportTransactionsData); // Adding data

  //   // Export the file
  //   this.exportFile(exportTransactionsFinal, options);
  // }



  exportFile(data: any[], options: any) {
    if (data.length > 0) {
      new ExportToCsv(options).generateCsv(data);
      this.toastr.success('CSV exported successfully.');
    } else {
      this.toastr.error('No record found');
    }
  }


  ngOnDestroy(): void {
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
  }
}
