import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private httpClient = inject(HttpClient);
  splitAmountOption: { id: number, translateVal: string, value: number | string }[] = [
    { id: 1, translateVal: '2', value: 2 },
    { id: 2, translateVal: '3', value: 3 },
    { id: 3, translateVal: '4', value: 4 },
    { id: 0, translateVal: 'custom', value: 'Custom' }
  ];
  constructor() { }

  sendPaymentLink(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('payment-link', data);
  }

}
