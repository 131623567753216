import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  apiUrl = environment.apiUrl;


  private httpClient = inject(HttpClient)

  // displayCategoryList = signal<boolean>(true);
  isShowCategory = new BehaviorSubject<any>({});
  isShowCategoryList = this.isShowCategory.asObservable();

  //#region Category API's

  // Get Category List
  getCategoryList(data: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    params = params.append('page', data.page);
    params = params.append('per_page', data.per_page);
    data.search_text ? params = params.append('search_name', data.search_text) : '';
    return this.httpClient.get<ResponseMessage>('get-category', { params: params });
  }

  // Add New Category 
  addCategory(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('add-category', data);
  }

  // Get Category Detail
  getCategoryDetail(id: number): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>(`edit-category/${id}`);
  }

  // update Category
  updateCategory(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('update-category', data);
  }

  // Delete Category 
  deleteCategory(id: number): Observable<ResponseMessage> {
    return this.httpClient.delete<ResponseMessage>(`delete-category/${id}`);
  }

  // Search Category by name
  searchCategory(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('search-category', data);
  }

  //#endregion Category API's

  // get image library
  getImageLibrary(page: number,per_page:any) {
    return this.httpClient.get<ResponseMessage>(`get-image-library?page=${page}&per_page=${per_page}`);
  }

  // add image to image library
  addImageLibrary(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('add-image', data);
  }

  // deletes image from image library
  deleteImageFromLibrary(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('delete-image', data);
  }

  // get Profile Detail
  getProfile() {
    return this.httpClient.get<ResponseMessage>('my-profile');
  }

  // Update Profile Detail
  updateProfile(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('update-profile', data);
  }
}
