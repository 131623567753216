import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, inject, Optional, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ToastrService } from 'ngx-toastr';
import { catchError, finalize, throwError } from 'rxjs';
import { UploadImagePopupComponent } from '../upload-image-popup/upload-image-popup.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { SettingsService } from '../../services/settings.service';
import { environment } from '../../../../../environments/environment';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { ConfirmationComponent } from '../../../../shared/components/dialog/confirmation/confirmation.component';
import { TranslateModule } from '@ngx-translate/core';
import { ConstantService } from '../../../../shared/services/constant.service';



@Component({
  selector: 'app-image-library',
  standalone: true,
  imports: [
    MatButtonModule,
    CommonModule,
    InfiniteScrollModule,
    MatCheckboxModule,
    MatDialogModule,
    TranslateModule
  ],
  templateUrl: './image-library.component.html',
  styleUrls: ['./image-library.component.scss'],
})
export class ImageLibraryComponent {
  private settingService = inject(SettingsService);
  private constantService = inject(ConstantService);
  public imageLibrayryArr: any;
  private api = inject(HttpClient);
  private page: any = 1;
  private toastr = inject(ToastrService);
  private imageFile: any;
  private imageFileName: any;
  private fileTypeError: any;
  private dialog = inject(MatDialog)
  public selectedImageCheckboxes: any[] = [];
  localData: any;
  isOpenDialog: boolean = false;
  per_page: any = 15;
  produCtSeletedCount: any;
  @ViewChild('fileInput') fileInput!: ElementRef;

  constructor(
    @Optional() private dialogRef: MatDialogRef<ImageLibraryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.localData = { ...data };
    this.produCtSeletedCount = this.localData.selectedProductImage;
    if (this.produCtSeletedCount?.length > 0)
      this.selectedImageCheckboxes = this.produCtSeletedCount.map((res: any) => { return res.media_id })
    // 1 = Product, 2 = Menu
    if (this.localData && (this.localData.type == 1 || this.localData.type == 2)) {
      this.isOpenDialog = true;
    }
  }
  ngOnInit() {
    this.getImageLibrary();
  }

  /**
   * calls get image library api
   */
  getImageLibrary() {
    this.constantService.progressLoader.next(true);
    this.page = 1;
    this.imageLibrayryArr = [];
    this.settingService.getImageLibrary(this.page, this.per_page).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: any) => {
        if (res.code == 200) {
          this.imageLibrayryArr = res.data.data;
          if (this.imageLibrayryArr.length == 0) {
            this.selectedImageCheckboxes = [];
          }
        }
      })
  }
  getScrolledDown() {
    this.constantService.progressLoader.next(true);
    this.page = this.page + 1;
    this.settingService.getImageLibrary(this.page, this.per_page).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: any) => {
        if (res.code == 200) {
          this.imageLibrayryArr = [...this.imageLibrayryArr, ...res.data.data]
        }
      })
  }

  /**
   * 
   * @param event it is uploaded image file
   * uploades image and show in list
   */
  uploadImage(event: any) {
    const file = event.target.files[0];
    const fileSizeMB = file.size / (1024 * 1024);
    const imageExtensions = ['image/png', 'image/jpeg', 'image/jpg'];

    if (fileSizeMB > 5) {
      this.fileTypeError = 'Please ensure your image file is no larger than 5MB for upload.';
      this.toastr.error(this.fileTypeError);
      return;
    }

    if (!imageExtensions.includes(file.type.toLowerCase())) {
      this.fileTypeError = 'You can only upload files in .jpg, .jpeg, or .png formats.';
      this.toastr.error(this.fileTypeError);
      return;
    }
    this.constantService.progressLoader.next(true);
    const reader = new FileReader();
    this.imageFile = file;
    this.imageFileName = file.name;
    reader.readAsDataURL(this.imageFile);
    reader.onload = () => {
      let formData = new FormData();
      formData.append('image', this.imageFile);
      this.settingService.addImageLibrary(formData).pipe(
        finalize(() => this.constantService.progressLoader.next(false)), catchError((err) => {
          this.toastr.error(err.error.message);
          return throwError(() => err.error.message)
        })).subscribe((res: ResponseMessage) => {
          if (res.code == 200) {
            this.toastr.success(res.message);
            this.getImageLibrary();
          } else {
            this.toastr.error(res.message);
          }
        })
    };
  }

  /**
   * 
   * it clears the uploaded image from input
   */
  imageCheck() {
    this.fileInput.nativeElement.value = "";
    return null;
  }

  /**
   *  gives the ids of selected checkboxe
   */
  changeImageCheckboxe(event: any, media_id: any) {
    if (event.checked) {
      if (this.localData.type == 2) { // single select 
        this.selectedImageCheckboxes = [];
      }

      this.selectedImageCheckboxes.push(media_id);
      if (this.localData.type == 1) {
        if (this.selectedImageCheckboxes.length > 5) {
          this.selectedImageCheckboxes = this.selectedImageCheckboxes.filter(mediaId => mediaId !== media_id);
          event.source.checked = false;
        }
      }

    } else {
      this.selectedImageCheckboxes = this.selectedImageCheckboxes.filter(mediaId => mediaId !== media_id);
    }
  }

  isChecked(media_id: number): any {
    return this.selectedImageCheckboxes.includes(media_id);
  }
  /**
   * deletes selected images from library
   */
  deleteImageFromLibrary() {
    let data = {
      type: 2,
      title: 'delete',
      message: 'delete_image_library_message',
      buttonName: { cancel: 'cancel', yes: 'yes', },
      id: ''
    }
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      panelClass: ['modal-wrapper'],
      disableClose: true,
      autoFocus: false,
      data
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res?.success) {
        this.constantService.progressLoader.next(true);
        let obj = {
          media_ids: this.selectedImageCheckboxes.join(',')
        }
        this.settingService.deleteImageFromLibrary(obj).pipe(
          finalize(() => this.constantService.progressLoader.next(false)),
          catchError((err) => {
            this.toastr.error(err.error.message);
            return throwError(() => err.error.message)
          })).subscribe((res: ResponseMessage) => {
            if (res.code == 200) {
              this.toastr.success(res.message);
              this.getImageLibrary();
            } else {
              this.toastr.error(res.message);
            }
          })
      }
    })

  }

  readonly dialogUpload = inject(MatDialog);

  openDialog() {
    const dialogRef = this.dialog.open(UploadImagePopupComponent, {
      width: '488px',
      panelClass: ['modal-wrapper', 'uploadimage-modal'],
      scrollStrategy: new NoopScrollStrategy()
    });
    dialogRef.afterClosed().subscribe((res1: any) => {
      if (res1) {
        this.constantService.progressLoader.next(true);
        let formData = new FormData();
        formData.append('image', res1)
        this.settingService.addImageLibrary(formData).pipe(
          finalize(() => this.constantService.progressLoader.next(false)),
          catchError((err) => {
            this.toastr.error(err.error.message);
            return throwError(() => err.error.message)
          })).subscribe((res: ResponseMessage) => {
            if (res.code == 200) {
              this.toastr.success(res.message);
              this.getImageLibrary();
            } else {
              this.toastr.error(res.message);
            }
          })
      }
    })
  }

  // Open Image library as a popup

  selectImage() {
    const filteredData = this.imageLibrayryArr.filter((item: any) => this.selectedImageCheckboxes.includes(item.media_id))
      .map((item: any) => ({
        media_id: item.media_id,
        image_name: item.image_name,
        url: item.url
      }));

    this.dialogRef.close({
      success: true,
      type: this.localData.type,
      selectImage: filteredData,
    })
  }

  cancel() {
    this.dialogRef.close({
      success: false,
      type: this.localData.type,
    })
  }
}
