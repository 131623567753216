import { Component, inject, OnInit } from '@angular/core';
import { EncryptionService } from '../../../services/encryption.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ConstantService } from '../../../services/constant.service';
import { catchError, Subscription, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';

@Component({
  selector: 'app-branch-location',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MatSelectModule,
    MatFormFieldModule
  ],
  templateUrl: './branch-location.component.html',
  styleUrls: ['./branch-location.component.scss'],
  providers: [EncryptionService],
})
export class BranchLocationComponent implements OnInit {
  constantService = inject(ConstantService);
  toastr = inject(ToastrService);
  private encDecObj = inject(EncryptionService);
  subscribe!: Subscription;
  selectedBranchLocationId: any;
  branchListData: any;
  language = 'selected_language';
  theme = 'selected_theme';
  selectedBranchLocation = 'select_business_location';
  constructor() { }

  ngOnInit() {
    this.getBranches();
  }
  getBranches() {
    if (this.subscribe) this.subscribe.unsubscribe();
    this.subscribe = this.constantService.getBranchList().pipe(
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message);
      })
    ).subscribe((res: ResponseMessage) => {
      if (res.code === 200) {
        this.branchListData = res.data.data;
        // this.constantService.branchList.set(res.data.data)
        this.setBranchLocation()
      } else {
        this.toastr.error(res.message);
      }
    });
  }
  setBranchLocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(
        localStorage.getItem(this.selectedBranchLocation)
      );
      this.selectedBranchLocationId = Number(id);
    } else if (this.branchListData?.length > 0) {
      this.selectLocation(this.branchListData[0].business_location_id);
    }
  }

  selectLocation(id: number) {
    this.selectedBranchLocationId = id;
    this.constantService.selectLocation(id);
  }

  ngOnDestroy(): void {
    if (this.subscribe) this.subscribe.unsubscribe();
  }

}
