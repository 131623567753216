import { Component, inject, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router, RouterModule } from '@angular/router';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { InventoryService } from '../Service/inventory.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { EncryptionService } from '../../../shared/services/encryption.service';
import { ConstantService } from '../../../shared/services/constant.service';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollDirective, InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { inventoryStatusArray } from '../../../model/const/common.const';
import { ProductViewComponent } from '../../retail/product-management/product-view/product-view.component';
import { ConfirmationComponent } from '../../../shared/components/dialog/confirmation/confirmation.component';
import { ProductsService } from '../../retail/product-management/Service/products.service';



@Component({
  selector: 'app-inventory-list',
  standalone: true,
  imports: [
    MatFormFieldModule,
    RouterModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatButtonModule,
    CommonModule,
    FormsModule,
    InfiniteScrollModule,
    TranslateModule,
    MatTooltipModule,
  ],
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.scss']
})
export class InventoryListComponent {

  displayedColumns: string[] = ['name', 'category_name', 'user_business_location', 'available_stock', 'available_to_sell', 'price', 'action'];
  dataSourceInventory: any;
  totalRecord: number = 0;
  subscribeListAPI!: Subscription;
  subscribeDeleteAPI!: Subscription;
  searchInventory: String = '';
  inventoryListData: any;
  private inventoryService = inject(InventoryService);
  private productService = inject(ProductsService);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private dialog = inject(MatDialog);
  private encDecObj = inject(EncryptionService);
  public constantService = inject(ConstantService);
  selectedInventory: any = 0;
  defaultInventorySearchPayload = {
    searchValue: '',
    category: 0,
    branch: 0,
    inventory: 0
  };
  isResetButtonDisabled: boolean = true;
  inventorySearchPayload: any = { ...this.defaultInventorySearchPayload };
  categoryListData: any;
  branchListData: any;
  selectCategory: any;
  selectBranch: any;
  inventoryStatus = inventoryStatusArray;
  selectedBranchLocation = 'select_business_location';
  selectedBranchLocationId = 0;
  pageNumber: number = 1;
  perPages: number = 25;
  @Input() title: string = 'inventory_management';
  @Input() moduleType: number = 1; // 1 = management, 2= Bar
  itemProgressLoader = false;
  constructor() {
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.itemProgressLoader = state;
    });
  }
  ngOnInit(): void {
    this.constantService.progressLoader.next(true);
    this.getCategories();
    this.getBranches();
    this.getInventoryList();
  }
  private getBusinessLocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.selectedBranchLocationId = Number(id);
    }
  }
  //#region Filters API's
  getCategories() {
    this.inventoryService.getCategoryList().pipe(
      catchError((error) => {
        this.constantService.progressLoader.next(false);
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.categoryListData = res.data.data;
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  getBranches() {
    this.inventoryService.getBranceList().pipe(
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.branchListData = res.data.data;
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  //#endregion Filters API's
  //#region Search Category
  searchInventorys() {
    this.searchInventory = this.searchInventory.trimStart();
    const searchText = this.searchInventory.trim();
    if (searchText !== this.inventorySearchPayload.searchValue) {
      if (searchText && searchText.length > 1) {
        this.inventorySearchPayload.searchValue = searchText;
        this.getInventoryList();
      } else if (!searchText && this.inventorySearchPayload.searchValue !== '') {
        this.inventorySearchPayload.searchValue = '';
        this.getInventoryList();
      }
    }
    this.checkResetButtonShouldBeDisabled();
  }

  selectSearch(type: number, value: number) {
    // 1 = Category, 2 = Branch, 3 = Inventory
    if (type == 1 && value == 0 && this.inventorySearchPayload.category == 0) {
      return;
    }
    if (type == 2 && value == 0 && this.inventorySearchPayload.branch == 0) {
      return;
    }
    if (type == 3 && value == 0 && this.inventorySearchPayload.inventory == 0) {
      return;
    }
    else if (type == 1) {
      this.inventorySearchPayload.category = value;
    } else if (type == 2) {
      this.inventorySearchPayload.branch = value;
    } else if (type == 3) {
      this.inventorySearchPayload.inventory = value;
    }
    this.getInventoryList();
    this.checkResetButtonShouldBeDisabled();
  }

  checkResetButtonShouldBeDisabled(): void {
    this.isResetButtonDisabled = JSON.stringify(this.inventorySearchPayload) === JSON.stringify(this.defaultInventorySearchPayload);
  }

  resetFilter(): void {
    this.inventorySearchPayload = { ...this.defaultInventorySearchPayload };
    this.checkResetButtonShouldBeDisabled();
    this.selectCategory = '';
    this.selectBranch = '';
    this.selectedInventory = '';
    this.searchInventory = '';
    this.isResetButtonDisabled = true;
    this.getInventoryList();
  }
  //#endregion Search Category

  //#region Inventory List

  getInventoryList() {
    this.getBusinessLocation();
    this.pageNumber = 1;
    let inventoryPayload = {
      page: this.pageNumber,
      per_page: this.perPages,
      business_location_id: this.selectedBranchLocationId,
      item_for: this.moduleType, //1=restaurant,2=bar
      search_text: this.inventorySearchPayload?.searchValue,
      category_text: this.inventorySearchPayload?.category,
      branch_text: this.inventorySearchPayload?.branch,
      inventory_text: this.inventorySearchPayload?.inventory,
    }
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeListAPI = this.inventoryService.getInventoryList(inventoryPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.inventoryListData = res.data.data;
          this.totalRecord = res.data.pagination.total;
          this.dataSourceInventory = new MatTableDataSource(res.data.data);
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  inventoryListScrollDown() {
    this.getBusinessLocation();
    this.pageNumber += this.pageNumber;
    let inventoryPayload = {
      page: this.pageNumber,
      per_page: this.perPages,
      business_location_id: this.selectedBranchLocationId,
      item_for: 1, //1=restaurant,2=bar
    }
    this.constantService.progressLoader.next(true);
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.subscribeListAPI = this.inventoryService.getInventoryList(inventoryPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.totalRecord = res.data.pagination.total;
          this.inventoryListData = [...this.inventoryListData, ...res.data.data];
          this.dataSourceInventory = new MatTableDataSource(this.inventoryListData);
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  //#endregion Inventory List

  //#region 

  viewInventory(id: number, itemData: any) {
    let data = {
      type: 1,
      name: 'Inventory Management',
      id,
      data: itemData
    }
    const dialogRef = this.dialog.open(ProductViewComponent, {
      width: '800px',
      autoFocus: false,
      panelClass: ['modal-wrapper'],
      data,
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res?.success) {
      }
    })
  }

  //moduleType=>  1 = management, 2= Bar

  addEditItem(action: string, id?: number) {
    const route = action == 'Add' ? '/inventory-list/add' : `/inventory-list/edit/${id}`;
    this.router.navigate([route], { state: { inventoryData: this.moduleType, action } });
  }

  createMenu() {
    this.router.navigate(['/food-menu/add'], { state: { inventoryData: this.moduleType } });
  }

  //Remove
  deleteProductDialog(id: number) {
    let data = {
      type: 4,
      title: 'delete',
      message: 'delete_item_message',
      buttonName: { cancel: 'cancel', yes: 'yes', },
      id: id
    }
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      panelClass: ['modal-wrapper'],
      disableClose: true,
      autoFocus: false,
      data
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res?.success) {
        this.deleteProduct(id);
      }
    })
  }

  deleteProduct(id: number) {
    this.constantService.progressLoader.next(true);
    if (this.subscribeDeleteAPI) this.subscribeDeleteAPI.unsubscribe();
    this.subscribeDeleteAPI = this.productService.deleteProduct(id).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.toastr.success(res.message);
          this.getInventoryList();
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  //#endregion

  //#region Destroy
  ngOnDestroy(): void {
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    if (this.subscribeDeleteAPI) this.subscribeDeleteAPI.unsubscribe();
  }
  //#endregion Destroy
}
