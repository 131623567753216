import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TableManagementService {

  constructor(private http: HttpClient) { }

  tableList(data: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    params = params.append('page', data.page);
    params = params.append('per_page', data.per_page);
    return this.http.get<ResponseMessage>('get-tables', { params });
  }

  addTable(data: any): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('add-table', data);
  }
}
