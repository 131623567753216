import { Component, inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, NativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup, MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ViewOrderComponent } from '../view-order/view-order.component';
export interface PeriodicElement {
  date: string;
  vendorName: string;
  id: string;
  title: string;
  totalAmount: number,
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    date: '28/05/2024',
    vendorName: 'Makenna Schleifer',
    id: '#12345',
    title: 'Bread',
    totalAmount: 540.55
  },
  {
    date: '28/05/2024',
    vendorName: 'Cheyenne Westervelt',
    id: '#12345',
    title: 'Cheese',
    totalAmount: 540.55
  },
  {
    date: '28/05/2024',
    vendorName: 'Cheyenne Westervelt',
    id: '#12345',
    title: 'Cheese',
    totalAmount: 540.55
  },
  {
    date: '28/05/2024',
    vendorName: 'Cheyenne Westervelt',
    id: '#12345',
    title: 'Cheese',
    totalAmount: 540.55
  },
  {
    date: '28/05/2024',
    vendorName: 'Cheyenne Westervelt',
    id: '#12345',
    title: 'Cheese',
    totalAmount: 540.55
  },
  {
    date: '28/05/2024',
    vendorName: 'Cheyenne Westervelt',
    id: '#12345',
    title: 'Cheese',
    totalAmount: 540.55
  },
  {
    date: '28/05/2024',
    vendorName: 'Cheyenne Westervelt',
    id: '#12345',
    title: 'Cheese',
    totalAmount: 540.55
  },
  {
    date: '28/05/2024',
    vendorName: 'Cheyenne Westervelt',
    id: '#12345',
    title: 'Cheese',
    totalAmount: 540.55
  },
];
@Component({
  selector: 'app-purchase-list',
  standalone: true,
  providers: [
    { provide: NativeDateAdapter, useClass: NativeDateAdapter }
  ],
  imports: [
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatSortModule,
    MatRadioModule,
    MatTooltipModule
  ],
  templateUrl: './purchase-list.component.html',
  styleUrls: ['./purchase-list.component.scss']
})
export class PurchaseListComponent {
  displayedColumns: string[] = ['date', 'vendorName', 'id', 'title', 'purchaseStatus', 'totalAmount', 'action'];
  dataSource = ELEMENT_DATA;

  readonly dialog = inject(MatDialog);

  openDialog() {
    const dialogRef = this.dialog.open(ViewOrderComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
    });
  }
}
