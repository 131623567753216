import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  constructor(private http: HttpClient) { }

  getReservationList(data: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    params = params.append('page', data.page);
    params = params.append('per_page', data.per_page);
    return this.http.get<ResponseMessage>('get-reservation', { params })
  }

  addReservation(data: any): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('add-reservation', data);
  }

  updateReservation(data: any): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('update-reservation', data);
  }

  deleteReservationt(id: any): Observable<ResponseMessage> {
    return this.http.delete<ResponseMessage>(`delete-reservation/${id}`)
  }
}
