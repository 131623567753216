import { Component, Inject, inject, Optional, QueryList, ViewChildren } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RouterModule } from '@angular/router';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { HeaderComponent } from '../../../../shared/components/common/header/header.component';
import { SidebarComponent } from '../../../../shared/components/common/sidebar/sidebar.component';
import { EncryptionService } from '../../../../shared/services/encryption.service';
import { muyBiLogo } from '../../../../model/const/common.const';
import { ConstantService } from '../../../../shared/services/constant.service';
import { CheckoutService } from '../service/checkout.service';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { CreateOrderRetailComponent } from './create-order-retail/create-order-retail.component';
import { CreateOrderRestaurantComponent } from "./create-order-restaurant/create-order-restaurant.component";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';


@Component({
  selector: 'app-product-listing',
  standalone: true,
  imports: [
    // HeaderComponent,
    // SidebarComponent,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    TranslateModule,
    InfiniteScrollModule,
    MatButtonToggleModule,
    CommonModule,
    FormsModule,
    CreateOrderRetailComponent,
    // CreateOrderRestaurantComponent,
    MatDialogModule,
    MatCheckboxModule
  ],
  templateUrl: './product-listing.component.html',
  styleUrls: ['./product-listing.component.scss'],
  providers: [EncryptionService]
})
export class ProductListingComponent {
  openOrder: boolean = false;
  categoryId: number = 0;
  totalRecord: number = 0;
  categoryList: any = [];
  orderItems: any = [];
  checkOutMenuList: any = [];
  subscribeListAPI!: Subscription;
  subscribeDeleteAPI!: Subscription;
  serachCheckOutMenu: String = '';
  muybiLogo = muyBiLogo;
  pageNumber: number = 1;
  perPages: number = 25;
  menuSearchPayload = {
    searchValue: '',
  };
  tempOrderItemData = 'tempOrderItemData';
  selectedBranchLocation = 'select_business_location';
  selectedBranchLocationId = 0;
  public constantService = inject(ConstantService);
  public checkoutService = inject(CheckoutService);
  private encDecObj = inject(EncryptionService);
  public toastr = inject(ToastrService);
  public localData: any;
  selectedProduct: any = [];
  itemProgressLoader = false;
  @ViewChildren('checkbox') checkboxes!: QueryList<MatCheckbox>;

  constructor(@Optional() private dialogRef: MatDialogRef<ProductListingComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.localData = data;
    // localStorage.removeItem(this.tempOrderItemData);
    this.checkoutService.isDisplayOrder.subscribe(res => {
      if (res.success && res.action == 'Discard') {
        this.orderItems = [];
        this.openOrder = false;
        localStorage.removeItem(this.tempOrderItemData);
        this.createOrderRemoveClass();
      }
    })

    if (this.localData?.orderItems?.length > 0) {
      this.selectedProduct = this.localData.orderItems
    }
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.itemProgressLoader = state;
    });
  }

  ngOnInit(): void {
    this.constantService.progressLoader.next(true);
    this.getBusinesslocation();
    this.getCategories();
  }

  getBusinesslocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.selectedBranchLocationId = Number(id);
    }
  }

  getCategories() {
    this.checkoutService.getCategoryList().pipe(
      catchError((error) => {
        this.constantService.progressLoader.next(false);
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message);
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.categoryList = res.data.data;
          if (this.categoryList.length > 0) {
            this.categoryId = this.categoryList[0].category_id;
            this.getCheckOutMenu();
          } else {
            this.constantService.progressLoader.next(false);
            this.totalRecord = 0;
          }
        } else {
          this.constantService.progressLoader.next(false);
          this.toastr.error(res.message);
        }
      });
  }

  //#region Checkout Menu List

  getCheckOutMenu() {
    this.getBusinesslocation();
    this.pageNumber = 1;
    let menuPayload = {
      page: this.pageNumber,
      per_page: this.perPages,
      category_id: this.categoryId,
      business_location_id: this.selectedBranchLocationId,
      search_text: this.menuSearchPayload.searchValue
    }
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeListAPI = this.checkoutService.getCheckOutMenuList(menuPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.checkOutMenuList = res.data.data;
          // this.selectedProduct = this.checkOutMenuList;
          this.totalRecord = res.data.pagination.total;
          if (this.totalRecord > 0 && localStorage.getItem(this.tempOrderItemData)) this.selectProductForOrder();
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  checkOutMenuScrollDown() {
    this.pageNumber += this.pageNumber;
    let menuPayload = {
      page: this.pageNumber,
      per_page: this.perPages,
      category_id: this.categoryId,
      business_location_id: this.selectedBranchLocationId,
    }
    this.constantService.progressLoader.next(true);
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.subscribeListAPI = this.checkoutService.getCheckOutMenuList(menuPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.totalRecord = res.data.pagination.total;
          this.checkOutMenuList = [...this.checkOutMenuList, ...res.data.data];
          // this.selectedProduct = this.checkOutMenuList;
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  //#endregion Checkout Menu List

  //#region Search Checkout Menu 
  serachCheckOutMenus() {
    this.serachCheckOutMenu = this.serachCheckOutMenu.trimStart();
    const searchText = this.serachCheckOutMenu.trim();

    if (searchText !== this.menuSearchPayload.searchValue) {
      if (searchText && searchText.length > 1) {
        this.menuSearchPayload.searchValue = searchText;
        this.getCheckOutMenu();
      } else if (!searchText && this.menuSearchPayload.searchValue !== '') {
        this.menuSearchPayload.searchValue = '';
        this.getCheckOutMenu();
      }
    }
  }

  //#endregion Search Checkout Menu

  selectProductForOrder(menu?: any) {
    this.createOrderAddClass();
    // this.openOrder = !this.openOrder;
    if (localStorage.getItem(this.tempOrderItemData)) {
      this.orderItems = JSON.parse(localStorage.getItem(this.tempOrderItemData) as any);
    }
    this.manageProduct(menu)
  }

  manageProduct(menu: any) {
    if (menu) {
      const existingItemIndex = this.orderItems.findIndex((item: any) => item.item_id === menu.item_id);
      if (existingItemIndex > -1) {
        const existingItem = { ...this.orderItems[existingItemIndex] };
        if ((existingItem.quantity < existingItem.available_stock) || (existingItem.available_stock == -1)) {
          existingItem.quantity += 1;
          this.orderItems[existingItemIndex] = existingItem;
          localStorage.setItem(this.tempOrderItemData, JSON.stringify(this.orderItems));
        } else {
          this.constantService.showError('max_stock_reach');
        }
      } else {
        const tempOrder = {
          item_id: menu.item_id,
          name: menu.name,
          price: menu.price,
          image_name: menu.image_name,
          url: menu.url,
          quantity: 1,
          available_stock: menu.available_stock
        };
        this.orderItems.push(tempOrder);
        localStorage.setItem(this.tempOrderItemData, JSON.stringify(this.orderItems));
      }
    }
    this.createOrder('New');
  }

  // old Code
  // selectProductForOrder1(menu: any) {
  //   this.createOrderAddClass();
  //   // this.openOrder = !this.openOrder;
  //   if (localStorage.getItem(this.tempOrderItemData)) {
  //     this.orderItems = JSON.parse(localStorage.getItem(this.tempOrderItemData) as any);
  //   }

  //   const tempFlg = this.orderItems.length ? "Existing" : 'New';
  //   const existingItemIndex = this.orderItems.findIndex((item: any) => item.item_id === menu.item_id);

  //   if (existingItemIndex > -1) {
  //     // Existing item found, update its quantity if stock allows
  //     const existingItem = { ...this.orderItems[existingItemIndex] };
  //     if ((existingItem.quantity < existingItem.available_stock) || (existingItem.available_stock == -1)) {
  //       existingItem.quantity += 1;
  //       this.orderItems[existingItemIndex] = existingItem;
  //     } else {
  //       this.constantService.showError('max_stock_reach');
  //     }
  //   } else {
  //     // If the item doesn't exist, add it to the array
  //     const tempOrder = {
  //       item_id: menu.item_id,
  //       name: menu.name,
  //       price: menu.price,
  //       image_name: menu.image_name,
  //       url: menu.url,
  //       quantity: 1,
  //       available_stock: menu.available_stock
  //     };
  //     this.orderItems.push(tempOrder);
  //   }
  //   localStorage.setItem(this.tempOrderItemData, JSON.stringify(this.orderItems));

  //   if (tempFlg === 'New' || tempFlg === 'Existing') {
  //     this.createOrder(tempFlg);
  //   }

  // }


  createOrder(action: any) {
    let obj = {
      success: true,
      action: action
    }
    this.checkoutService.isDisplayOrder.next(obj);
  }

  createOrderAddClass() {
    const deviceType = this.constantService.getDeviceType();
    const className = 'open_checkout';

    if (deviceType === 1) {
      document.body.classList.add(className);
    } else {
      this.createOrderRemoveClass();
    }
  }

  createOrderRemoveClass() {
    const className = 'open_checkout';
    if (document.body.classList.contains(className)) {
      document.body.classList.remove(className);
    }
  }


  selectMenuCategory(id: number) {
    if (id != this.categoryId) {
      this.categoryId = id;
      this.getCheckOutMenu();
    }
  }
  changeProductCheckboxe(event: any, item: any) {
    if (event.checked) {
      if (!item.quantiy) item.quantity = 1;
      if (!item.itemTotal) item.itemTotal = (+item.quantity * (+item.price))
      this.selectedProduct.push(item);
    } else {
      this.selectedProduct = this.selectedProduct.filter((res: any) => {
        if (res.item_id != item.item_id) {
          return res
        }
      })
    }
  }
  isChecked(item: any) {
    return this.selectedProduct.some((product: any) => item.item_id == product.item_id)
  }
  selectProducts() {
    this.dialogRef.close(this.selectedProduct)
  }
  closePopup() {
    this.dialogRef.close();
  }
  toggleCheckbox(i: any, item: any) {
    const checkbox = this.checkboxes.toArray()[i];
    checkbox.checked = !checkbox.checked
    this.changeProductCheckboxe(checkbox, item)
  }
  ngOnDestroy() {
    this.createOrderRemoveClass();
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    if (this.subscribeDeleteAPI) this.subscribeDeleteAPI.unsubscribe();
  }
}
