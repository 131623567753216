import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioGroup, MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import { DeleteVendorComponent } from '../../components/delete-vendor/delete-vendor.component';
import { TranslateModule } from '@ngx-translate/core';
import { VendorManagementService } from '../../service/vendor-management.service';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ToastrService } from 'ngx-toastr';
import { ConstantService } from '../../../../shared/services/constant.service';
import { MaxcharlengthPipe } from '../../../../shared/pipes/maxcharlength.pipe';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-vendor-list',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatTableModule,
    MatTooltipModule,
    MatRadioModule,
    RouterModule,
    InfiniteScrollModule,
    TranslateModule,
    MaxcharlengthPipe
  ],
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.scss']
})
export class VendorListComponent {
  vendorService = inject(VendorManagementService);
  constantService = inject(ConstantService);
  toastr = inject(ToastrService);
  route = inject(Router);
  displayedColumns: string[] = ['vendorName', 'email', 'phoneNumber', 'description', 'businessDetail', 'action'];
  dataSource: any = [];
  vendorPayload: any = {
    page: 1,
    per_page: 15,
    search: ''
  }
  vendorListData: any = []
  pageNumber = 1;
  totalRecords: any = 0;
  subscriberList!: Subscription;
  maxcharLimit = 100;
  readonly dialog = inject(MatDialog);
  vendorProgressLoader = false;

  constructor() {
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.vendorProgressLoader = state;
    });
  }

  ngOnInit(): void {
    this.constantService.progressLoader.next(true);
    this.getVendorList();
  }

  openDeleteVendorDialog(id: any, name: any) {
    const dialogRef = this.dialog.open(DeleteVendorComponent, {
      width: '564px',
      data: { name },
      panelClass: ['modal-wrapper'],
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.deleteVendor(id);
      }
    })
  }
  editVendor(id: any) {
    this.route.navigate(['/vendor-list/edit', id])
  }
  getVendorList() {
    this.constantService.progressLoader.next(true);
    this.vendorPayload.page = 1;
    if (this.subscriberList) this.subscriberList.unsubscribe();
    this.subscriberList = this.vendorService.getVendorList(this.vendorPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err) => {
        this.toastr.error(err.error.message);
        return throwError(() => err.error.message)
      })
    ).subscribe((res: any) => {
      if (res.code == 200) {
        this.totalRecords = res.data.pagination.total;
        this.dataSource = res.data.data;
        this.vendorListData = res.data.data;
      } else {
        this.toastr.error(res.error);
      }
    })
  }
  vendorListScrollDown() {
    this.constantService.progressLoader.next(true);
    this.vendorPayload.page = this.pageNumber + 1;
    if (this.subscriberList) this.subscriberList.unsubscribe();
    this.subscriberList = this.vendorService.getVendorList(this.vendorPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err) => {
        this.toastr.error(err.error.message);
        return throwError(() => err.error.message)
      })
    ).subscribe((res: any) => {
      if (res.code == 200) {
        this.totalRecords = res.data.pagination.total;
        this.vendorListData = [...this.vendorListData, ...res.data.data];
        this.dataSource = this.vendorListData;
      } else {
        this.toastr.error(res.message);
      }
    })
  }
  deleteVendor(id: any) {
    this.constantService.progressLoader.next(true);
    if (this.subscriberList) this.subscriberList.unsubscribe();
    this.subscriberList = this.vendorService.deleteVendor(id).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err) => {
        this.toastr.error(err.error.message);
        return throwError(() => err.error.message)
      })
    ).subscribe((res: any) => {
      if (res.code == 200) {
        this.toastr.success(res.message);
        this.getVendorList();
      } else {
        this.toastr.error(res.error);
      }
    })
  }
  searchVendor(event: any) {
    let serachVendor = event.target.value.trimStart();
    const searchText = serachVendor.trim();
    if (searchText !== this.vendorPayload.search) {
      if (searchText && searchText.length > 1) {
        this.vendorPayload.search = searchText;
        this.getVendorList();
      } else if (!searchText && this.vendorPayload.search !== '') {
        this.vendorPayload.search = '';
        this.getVendorList();
      }
    }
  }
}
