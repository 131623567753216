<div class="authentication-page flex flex-align-center flex-justify-center" [ngClass]="{'pointer-none': loader}">
  <button mat-flat-button class="btn-dark lang-btn" (click)="changeLanguage()">
    {{ language | languageName }}
</button>
  <div class="authentication-box">
    <div class="auth-headerwrapper flex">
      <div class="authlogo-box">
        <div class="auth-box flex flex-align-center flex-justify-center">
          <img src="assets/images/logo-small.svg" alt="MuyBi" />
        </div>
      </div>
      <div class="auth-header">
        <h1>{{ "forgot_password" | translate }}</h1>
        <p class="text-grey">{{ "dont_worry_it_happens" | translate }}</p>
      </div>
    </div>
    <div class="auth-innerbox">
      <form [formGroup]="forgotPassForm" (ngSubmit)="submitForm()">
        <mat-radio-group formControlName="type" class="mb-16" (change)="onInputChange()">
          <mat-radio-button value="1">{{
            "email" | translate
            }}</mat-radio-button>
          <mat-radio-button value="2">{{
            "phone" | translate
            }}</mat-radio-button>
        </mat-radio-group>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label *ngIf="forgotPassForm.value.type == '1'">
            {{ "forgot_password_form.enter_your_email" | translate }}
          </mat-label>
          <mat-label *ngIf="forgotPassForm.value.type == '2'">
            {{ "forgot_password_form.enter_your_phone" | translate }}*
          </mat-label>
          <div class="flex w-full inputwith-btn flex-align-end mb-16">
            <ng-container *ngIf="forgotPassForm.value.type == '1'">
              <input matInput formControlName="userName" />
            </ng-container>
            <ng-container *ngIf="forgotPassForm.value.type == '2'">
              <ngx-mat-intl-tel-input formControlName="phone" class="w-full" [preferredCountries]="preferredCountries"
                [enablePlaceholder]="true" [enableSearch]="true" name="userNamePhone" #userNamePhone>
              </ngx-mat-intl-tel-input>
            </ng-container>
          </div>
        </mat-form-field>
        <button mat-flat-button type="submit" class="w-full mb-24 btn-lg">
          <mat-spinner *ngIf="loader" [diameter]="spinnerDiameter"
            color="var(--mdc-filled-button-label-text-color)"></mat-spinner>
          <ng-container *ngIf="!loader">
            {{'forgot_password_form.send_link' | translate}}
          </ng-container>
        </button>
        <div class="bg-dark mb-24 linkinfo-wrap flex text-grey-light radius-12 fs-14">
          <mat-icon class="flex-shrink-0"><img src="assets/images/icons/attach-link.svg" alt="Attach Link" /></mat-icon>
          <span>
            {{ "forgot_password_form.note" | translate }}
          </span>
        </div>
        <div class="text-center">
          <a mat-button href="javascript:void(0)" (click)="redirectToLogin()">
            {{ "forgot_password_form.back_sign_in" | translate }}
          </a>
        </div>
      </form>
    </div>
  </div>
</div>