import { Pipe, PipeTransform } from '@angular/core';
import { languageArray } from 'src/app/model/const/common.const';

@Pipe({
  name: 'languageName',
  standalone: true,
})
export class LanguageNamePipe implements PipeTransform {

  transform(langCode: string): string {

    //Revese Language flow
    const oppositeLanguage = langCode.toLowerCase() === 'en' ? 'es' : 'en';
    const language = languageArray.find(item => item.prefix === oppositeLanguage);

    // Return the opposite language name or default to English if not found
    return language ? language.name : 'Español';

    // Language Wise
    // const language = languageArray.find((item: { prefix: string; }) => item.prefix === langCode.toLowerCase());

    // // Return the matched language name or default to English if not found
    // return language ? language.name : 'Español';
  }

}
