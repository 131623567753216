<mat-dialog-content class="text-center">
  <div class="mb-24 radius-12">
    <img src="assets/images/update-ic.png" alt="Update Waiting List" class="dialog-smallimg" />
  </div>
  <div class="block text-grey-light mb-24">
    <h3 class="h1 mb-24 text-white">
      {{localData?.element?('update_waiting_list' | translate):('add_waiting_list' | translate)}}</h3>
    <p>{{localData?.element?('edit_below_to_modify_waiting' | translate):('fill_in_below_detail' | translate)}}</p>
  </div>
  <form [formGroup]="waitingListForm">
    <div class="mb-24 updatelist-field">
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{'enter_customer_name' | translate}}</mat-label>
        <input matInput formControlName="customer_name">
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{'queue_number' | translate | titlecase}}</mat-label>
        <input matInput formControlName="queue_number" allowNumberOnly>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{'no_of_guests' | translate | titlecase}}</mat-label>
        <input matInput formControlName="no_of_guest" allowNumberOnly>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{'enter_mobile' | translate}}</mat-label>
        <input matInput formControlName="mobile_no" allowNumberOnly>
      </mat-form-field>
    </div>
    <div class="btn-box text-center">
      <button mat-flat-button mat-dialog-close class="btn-outline-primary">{{'cancel' | translate}}</button>
      <button mat-flat-button (click)="addUpdateWaiting()">
        {{localData?.element?('update_now' | translate):('add_now' | translate)}}
      </button>
    </div>
  </form>
</mat-dialog-content>