import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-take-picture-popup',
  templateUrl: './take-picture-popup.component.html',
  styleUrls: ['./take-picture-popup.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule,TranslateModule]
})
export class TakePicturePopupComponent {
  dialogref = inject(MatDialogRef<TakePicturePopupComponent>);
  toastr = inject(ToastrService);
  ngOnInit() {

  }
  WIDTH = 640;
  HEIGHT = 480;

  @ViewChild("video")
  public video!: ElementRef;

  @ViewChild("canvas")
  public canvas!: ElementRef;

  captures: string[] = [];
  error: any;
  isCaptured!: boolean;

  async ngAfterViewInit() {
    await this.setupDevices();
  }

  async setupDevices() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true
      });
      if (stream) {
        this.video.nativeElement.srcObject = stream;
        this.video.nativeElement.play();
        this.error = null;
      } else {
        this.error = "You have no output video device";
        this.dialogref.close();
      }
    } catch (error: any) {
      if (error.name === 'NotAllowedError') {
        this.toastr.error('Camera access is currently disabled. Please enable camera permissions on your device and try again.');
      } else if (error.name === 'NotFoundError') {
        this.toastr.error('Unable to detect a camera device. Please connect a camera and check your device settings.');
      } else {
        this.toastr.error('An unknown error occurred.');
      }
      this.dialogref.close();
    }
  }


  capture() {
    this.drawImageToCanvas(this.video.nativeElement);
    this.captures.push(this.canvas.nativeElement.toDataURL("image/png"));
    let imgFile = this.convertDataUrlToFile(this.captures[0], 'image_library_' + '' + new Date().getTime());
    this.isCaptured = true;
    // this.video.nativeElement.stop();
    this.dialogref.close(imgFile)
  }

  drawImageToCanvas(image: any) {
    this.canvas.nativeElement
      .getContext("2d")
      .drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
  }

  convertDataUrlToFile(dataUrl: string, fileName: string): File {
    const arr: any = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  }
}

