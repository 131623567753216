import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  private httpClient = inject(HttpClient);
  isDisplayOrder = new BehaviorSubject<any>({});

  //#region Checkout API's

  // Get Checkout Menu  List

  getCheckOutMenuList(data: any): Observable<ResponseMessage> {
    const params = new HttpParams({
      fromObject: {
        page: data.page,
        per_page: data.per_page,
        business_location_id: data.business_location_id,
        category_id: data.category_id,
        ...(data.search_text && { search_name: data.search_text })
      }
    });

    return this.httpClient.get<ResponseMessage>('get-category-wise-products', { params });
  }

  // Get Category List
  getCategoryList(): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>('get-all-categories');
  }

  // Apply Discount Coupon 
  applyCoupon(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('apply-coupon', data);
  }

  // Checkout product
  checkout(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('checkout', data);
  }

  //Create Order
  createOrder(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('order-generate', data);
  }

  orderPayment(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('order-generate', data);
  }


  //#endregion Checkout API's

}
