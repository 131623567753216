// import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, inject } from '@angular/core';
// import { MatButtonModule } from '@angular/material/button';
// import { MatDialog, MatDialogModule } from '@angular/material/dialog';
// import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatInputModule } from '@angular/material/input';
// import { MatSelectModule } from '@angular/material/select';
// import { MatTableDataSource, MatTableModule } from '@angular/material/table';
// import { MatTooltipModule } from '@angular/material/tooltip';
// import { Router, RouterModule } from '@angular/router';
// import { HeaderComponent } from '../../../shared/components/common/header/header.component';
// import { SidebarComponent } from '../../../shared/components/common/sidebar/sidebar.component';
// import { ViewProductComponent } from '../../../shared/components/dialog/view-product/view-product.component';
// import { inventoryStatusArray } from '../../../model/const/common.const';
// import { catchError, Subscription, throwError } from 'rxjs';
// import { BarInventoryService } from '../Service/bar-inventory.service';
// import { ToastrService } from 'ngx-toastr';
// import { EncryptionService } from '../../../shared/services/encryption.service';
// import { ConstantService } from '../../../shared/services/constant.service';
// import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
// import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
// import { TranslateModule } from '@ngx-translate/core';
import { InventoryListComponent } from '../../inventory-management/inventory-list/inventory-list.component';

@Component({
  selector: 'app-list',
  standalone: true,
  imports: [
    // RouterModule,
    // MatFormFieldModule,
    // MatInputModule,
    // MatSelectModule,
    // MatIconModule,
    // MatTableModule,
    // MatButtonModule,
    CommonModule,
    FormsModule,
    // MatDialogModule,
    // InfiniteScrollDirective,
    // TranslateModule,
    // MatTooltipModule,
    InventoryListComponent,
  ],
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {
  // displayedColumns: string[] = ['name', 'category_name', 'user_business_location', 'available_stock', 'available_to_sell', 'price', 'action'];
  // dataSourceBarInventory: any;
  // totalRecord: number = 0;
  // subscribeListAPI!: Subscription;
  // searchBarInventory: String = '';
  // barInventoryListData: any;
  // private barInventoryService = inject(BarInventoryService);
  // private toastr = inject(ToastrService);
  // private router = inject(Router);
  // readonly dialog = inject(MatDialog);
  // private encDecObj = inject(EncryptionService);
  // public constantService = inject(ConstantService);
  // selectedInventory: any = 0;
  // defaultBarInventorySearchPayload = {
  //   searchValue: '',
  //   category: 0,
  //   branch: 0,
  //   inventory: 0
  // };
  // isResetButtonDisabled: boolean = true;
  // barInventorySearchPayload: any = { ...this.defaultBarInventorySearchPayload };
  // categoryListData: any;
  // branchListData: any;
  // selectCategory: any;
  // selectBranch: any;
  // inventoryStatus = inventoryStatusArray;
  // selectedBranchLocation = 'select_business_location';
  // selectedBranchLocationId = 0;
  // pageNumber: number = 1;
  // perPages: number = 25;
  ngOnInit(): void {
    // this.constantService.progressLoader.set(true);
    // this.getCategories();
    // this.getBranches();
    // this.getInventoryList();
  }
  // private getBusinessLocation() {
  //   if (localStorage.getItem(this.selectedBranchLocation)) {
  //     const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
  //     this.selectedBranchLocationId = Number(id);
  //   }
  // }
  //#region Filters API's
  // getCategories() {
  //   this.barInventoryService.getCategoryList().pipe(
  //     catchError((error) => {
  //       this.constantService.progressLoader.set(false);
  //       this.toastr.error(error.error.message);
  //       return throwError(() => error.error.message)
  //     })).subscribe((res: ResponseMessage) => {
  //       if (res.code === 200) {
  //         this.categoryListData = res.data.data;
  //       } else {
  //         this.toastr.error(res.message);
  //       }
  //     })
  // }
  // getBranches() {
  //   this.barInventoryService.getBranceList().pipe(
  //     catchError((error) => {
  //       this.toastr.error(error.error.message);
  //       return throwError(() => error.error.message)
  //     })).subscribe((res: ResponseMessage) => {
  //       if (res.code === 200) {
  //         this.branchListData = res.data.data;
  //       } else {
  //         this.toastr.error(res.message);
  //       }
  //     })
  // }
  //#endregion Filters API's
  //#region Search Category
  // searchInventorys() {
  //   this.searchBarInventory = this.searchBarInventory.trimStart();
  //   const searchText = this.searchBarInventory.trim();
  //   if (searchText !== this.barInventorySearchPayload.searchValue) {
  //     if (searchText && searchText.length > 1) {
  //       this.barInventorySearchPayload.searchValue = searchText;
  //       this.getInventoryList();
  //     } else if (!searchText && this.barInventorySearchPayload.searchValue !== '') {
  //       this.barInventorySearchPayload.searchValue = '';
  //       this.getInventoryList();
  //     }
  //   }
  //   this.checkResetButtonShouldBeDisabled();
  // }

  // selectSearch(type: number, value: number) {
  //   // 1 = Category, 2 = Branch, 3 = Inventory
  //   if (type == 1 && value == 0 && this.barInventorySearchPayload.category == 0) {
  //     return;
  //   }
  //   if (type == 2 && value == 0 && this.barInventorySearchPayload.branch == 0) {
  //     return;
  //   }
  //   if (type == 3 && value == 0 && this.barInventorySearchPayload.inventory == 0) {
  //     return;
  //   }
  //   else if (type == 1) {
  //     this.barInventorySearchPayload.category = value;
  //   } else if (type == 2) {
  //     this.barInventorySearchPayload.branch = value;
  //   } else if (type == 3) {
  //     this.barInventorySearchPayload.inventory = value;
  //   }
  //   this.getInventoryList();
  //   this.checkResetButtonShouldBeDisabled();
  // }

  // checkResetButtonShouldBeDisabled(): void {
  //   this.isResetButtonDisabled = JSON.stringify(this.barInventorySearchPayload) === JSON.stringify(this.defaultBarInventorySearchPayload);
  // }

  // resetFilter(): void {
  //   this.barInventorySearchPayload = { ...this.defaultBarInventorySearchPayload };
  //   this.checkResetButtonShouldBeDisabled();
  //   this.searchBarInventory = '';
  //   this.isResetButtonDisabled = true;
  //   this.getInventoryList();
  // }
  //#endregion Search Category

  //#region Inventory List

  // getInventoryList(value?: string) {
  //   this.getBusinessLocation();
  //   this.pageNumber = 1;
  //   let inventoryPayload = {
  //     page: this.pageNumber,
  //     per_page: this.perPages,
  //     business_location_id: this.selectedBranchLocationId,
  //     item_for: 2, //1=restaurant,2=bar
  //     // search_text: this.barInventorySearchPayload?.searchValue,
  //     // category_text: this.barInventorySearchPayload?.category,
  //     // branch_text: this.barInventorySearchPayload?.branch,
  //     // inventory_text: this.barInventorySearchPayload?.inventory,
  //   }
  //   if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
  //   this.constantService.progressLoader.set(true);
  //   this.subscribeListAPI = this.barInventoryService.getBarInventoryList(inventoryPayload).pipe(
  //     catchError((error) => {
  //       this.constantService.progressLoader.set(false);
  //       this.toastr.error(error.error.message);
  //       return throwError(() => error.error.message)
  //     })).subscribe((res: ResponseMessage) => {
  //       this.constantService.progressLoader.set(false);
  //       if (res.code === 200) {
  //         this.barInventoryListData = res.data.data;
  //         this.totalRecord = res.data.pagination.total;
  //         this.dataSourceBarInventory = new MatTableDataSource(res.data.data);
  //       } else {
  //         this.toastr.error(res.message);
  //       }
  //     })
  // }

  // inventoryListScrollDown() {
  //   this.getBusinessLocation();
  //   this.pageNumber += this.pageNumber;
  //   let inventoryPayload = {
  //     page: this.pageNumber,
  //     per_page: this.perPages,
  //     business_location_id: this.selectedBranchLocationId,
  //     item_for: 2, //1=restaurant,2=bar
  //   }
  //   this.constantService.progressLoader.set(true);
  //   if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
  //   this.subscribeListAPI = this.barInventoryService.getBarInventoryList(inventoryPayload).pipe(
  //     catchError((error) => {
  //       this.constantService.progressLoader.set(false);
  //       this.toastr.error(error.error.message);
  //       return throwError(() => error.error.message)
  //     })).subscribe((res: ResponseMessage) => {
  //       this.constantService.progressLoader.set(false);
  //       if (res.code === 200) {
  //         this.constantService.progressLoader.set(false);
  //         this.totalRecord = res.data.pagination.total;
  //         this.barInventoryListData = [...this.barInventoryListData, ...res.data.data];
  //         this.dataSourceBarInventory = new MatTableDataSource(this.barInventoryListData);
  //       } else {
  //         this.toastr.error(res.message);
  //       }
  //     })
  // }
  //#endregion Inventory List

  //#region 
  // editInventory(id: number) {
  //   this.router.navigate([`/inventory-list/edit/${id}`]);
  // }

  // viewInventory(id: number) {
  //   this.router.navigate([`/inventory-list/view/${id}`]);
  // }

  // openDialog() {
  //   const dialogRef = this.dialog.open(ViewProductComponent, {
  //     width: '564px',
  //     panelClass: ['modal-wrapper'],
  //     scrollStrategy: new NoopScrollStrategy()
  //   });
  // }

  //#region Destroy
  // ngOnDestroy(): void {
  //   if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
  //   // if (this.subscribeDeleteAPI) this.subscribeDeleteAPI.unsubscribe();
  // }
  //#endregion Destroy

}
