import { Component, inject, } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { ProductsService } from '../Service/products.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CommonModule } from '@angular/common';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { HeaderComponent } from '../../../../shared/components/common/header/header.component';
import { SidebarComponent } from '../../../../shared/components/common/sidebar/sidebar.component';
import { ConfirmationComponent } from '../../../../shared/components/dialog/confirmation/confirmation.component';
import { EncryptionService } from '../../../../shared/services/encryption.service';
import { ConstantService } from '../../../../shared/services/constant.service';
import { inventoryStatusArray } from '../../../../model/const/common.const';

@Component({
  selector: 'app-product-list',
  standalone: true,
  imports: [
    HeaderComponent,
    SidebarComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatTooltipModule,
    MatDialogModule,
    MatIconModule,
    RouterModule,
    FormsModule,
    CommonModule,
    InfiniteScrollModule,
    TranslateModule
  ],
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  providers: [EncryptionService]
})
export class ProductListComponent {
  displayedColumns: string[] = ['name', 'category_name', 'business_location_name', 'available_stock', 'available_to_sell', 'price', 'action'];
  dataSourceProducts: any;
  totalRecord: number = 0;
  subscribeListAPI!: Subscription;
  subscribeDeleteAPI!: Subscription;
  serachProduct: String = '';
  productListData: any;
  private productService = inject(ProductsService);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private dialog = inject(MatDialog);
  private encDecObj = inject(EncryptionService);
  public constantService = inject(ConstantService);
  selectedInventory: any = 0;
  defaultProductSearchPayload = {
    searchValue: '',
    category: 0,
    branch: 0,
    inventory: 0
  };
  isResetButtonDisabled: boolean = true;
  productSearchPayload: any = { ...this.defaultProductSearchPayload };
  categoryListData: any;
  branchListData: any;
  selectCategory: any;
  selectBranch: any;
  inventoryStatus = inventoryStatusArray;
  selectedBranchLocation = 'select_business_location';
  selectedBranchLocationId = 0;
  pageNumber: number = 1;
  perPages: number = 25;
  itemProgressLoader = false;

  constructor() {
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.itemProgressLoader = state;
    });
  }

  ngOnInit(): void {
    this.constantService.progressLoader.next(true);
    this.getCategories();
    this.getBranches();
    this.getProductList();
  }

  //#region Filters API's
  getCategories() {
    this.productService.getCategoryList().pipe(
      catchError((error) => {
        this.constantService.progressLoader.next(false);
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.categoryListData = res.data.data;
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  getBranches() {
    this.productService.getBranceList().pipe(
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.branchListData = res.data.data;
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  //#endregion Filters API's

  selectSearch(type: number, value: number) {
    // 1 = Category, 2 = Branch, 3 = Inventory
    if (type == 1 && value == 0 && this.productSearchPayload.category == 0) {
      return;
    }
    if (type == 2 && value == 0 && this.productSearchPayload.branch == 0) {
      return;
    }
    if (type == 3 && value == 0 && this.productSearchPayload.inventory == 0) {
      return;
    }
    else if (type == 1) {
      this.productSearchPayload.category = value;
    } else if (type == 2) {
      this.productSearchPayload.branch = value;
    } else if (type == 3) {
      this.productSearchPayload.inventory = value;
    }
    this.getProductList();
    this.checkResetButtonShouldBeDisabled();
  }


  //#region Search Category
  searchProducts() {
    this.serachProduct = this.serachProduct.trimStart();
    const searchText = this.serachProduct.trim();
    if (searchText !== this.productSearchPayload.searchValue) {
      if (searchText && searchText.length > 1) {
        this.productSearchPayload.searchValue = searchText;
        this.getProductList();
      } else if (!searchText && this.productSearchPayload.searchValue !== '') {
        this.productSearchPayload.searchValue = '';
        this.getProductList();
      }
    }
    this.checkResetButtonShouldBeDisabled();
  }
  checkResetButtonShouldBeDisabled(): void {
    this.isResetButtonDisabled = JSON.stringify(this.productSearchPayload) === JSON.stringify(this.defaultProductSearchPayload);
  }

  resetFilter(): void {
    this.productSearchPayload = { ...this.defaultProductSearchPayload };
    this.checkResetButtonShouldBeDisabled();
    this.serachProduct = '';
    this.selectCategory = '';
    this.selectBranch = '';
    this.selectedInventory = '';
    this.isResetButtonDisabled = true;
    this.getProductList();
  }

  //#endregion Search Category

  //#region Product List
  getBusinesslocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.selectedBranchLocationId = Number(id);
    }
  }
  getProductList() {
    this.getBusinesslocation();
    this.pageNumber = 1;
    let productPayload = {
      page: this.pageNumber,
      per_page: this.perPages,
      business_location_id: this.selectedBranchLocationId,
      search_text: this.productSearchPayload?.searchValue,
      category_text: this.productSearchPayload?.category,
      branch_text: this.productSearchPayload?.branch,
      inventory_text: this.productSearchPayload?.inventory,
    }
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeListAPI = this.productService.getProductList(productPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.productListData = res.data.data;
          this.totalRecord = res.data.pagination.total;
          this.dataSourceProducts = new MatTableDataSource(res.data.data);
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  productListScrollDown() {
    this.pageNumber += this.pageNumber;
    let productPayload = {
      page: this.pageNumber,
      per_page: this.perPages,
      business_location_id: this.selectedBranchLocationId,
    }
    this.constantService.progressLoader.next(true);
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.subscribeListAPI = this.productService.getProductList(productPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.totalRecord = res.data.pagination.total;
          this.productListData = [...this.productListData, ...res.data.data];
          this.dataSourceProducts = new MatTableDataSource(this.productListData);
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  //#endregion Product List

  //#region 
  editProduct(id: number) {
    this.router.navigate([`/product-list/edit/${id}`]);
  }

  viewProduct(id: number) {
    this.router.navigate([`/product-list/view/${id}`]);
  }
  //Remove
  deleteProductDialog(id: number) {
    let data = {
      type: 3,
      title: 'delete',
      message: 'delete_product_message', //delete_Product_message
      buttonName: { cancel: 'cancel', yes: 'yes', },
      id: id
    }
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      panelClass: ['modal-wrapper'],
      disableClose: true,
      autoFocus: false,
      data
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res?.success) {
        this.deleteProduct(id);
      }
    })
  }

  deleteProduct(id: number) {
    this.constantService.progressLoader.next(true);
    if (this.subscribeDeleteAPI) this.subscribeDeleteAPI.unsubscribe();
    this.subscribeDeleteAPI = this.productService.deleteProduct(id).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.toastr.success(res.message);
          this.getProductList();
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  //#endregion


  //#region Destroy
  ngOnDestroy(): void {
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    if (this.subscribeDeleteAPI) this.subscribeDeleteAPI.unsubscribe();
  }
  //#endregion Destroy
}
