import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { googlePlaceAPIKey } from 'src/app/model/const/common.const';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private googleApiKey = googlePlaceAPIKey;

  constructor(private http: HttpClient) { }

  // Get the current latitude and longitude of the user
  getCurrentLocation(): Promise<{ latitude: number; longitude: number }> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        reject(new Error('Geolocation not supported.'));
      }
    });
  }

  // Get formatted address based on latitude and longitude
  getFormattedAddress(latitude: number, longitude: number): Promise<string> {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${this.googleApiKey}`;
    return this.http.get<any>(url).toPromise().then((response) => {
      if (response.results && response.results.length > 0) {
        return response.results[0].formatted_address;
      } else {
        throw new Error('No address found.');
      }
    });
  }

  // Get country code from the geocoding API response
  getCountryCodeFromCoordinates(latitude: number, longitude: number): Promise<string> {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${this.googleApiKey}`;
    return this.http.get<any>(url).toPromise().then((response) => {
      if (response.results && response.results.length > 0) {
        const addressComponents = response.results[0].address_components;
        for (let component of addressComponents) {
          if (component.types.includes('country')) {
            return component.short_name; // Country code (e.g., 'US', 'ES')
          }
        }
        throw new Error('Country not found.');
      } else {
        throw new Error('No address found.');
      }
    });
  }
  
}
