<!-- Starts Header -->
<div class="section-box mb-24 flex flex-wrap flex-align-center header-section gap-8px backbtn-wrap"  [ngClass]="{'pointer-none': invoiceProgressLoader}">
  <h1 class="text-white mb-0 text-truncate title-full">{{'create_invoice' | translate}}</h1>
  <a mat-flat-button class="btn-outline-secondary backbtn"
  (click)="backToInvoiceList()">
    <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859"
        stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    {{'back' | translate}}</a>
  <button mat-flat-button class="btn-secondary btn-lg">{{'preview_invoice' | translate}}</button>
  <button mat-flat-button class="btn-outline-primary btn-lg" (click)="sendOrDraftInvoice('2')">
    {{'save_as_draft' | translate}}</button>
  <button mat-flat-button class="btn-primary btn-lg" (click)="sendOrDraftInvoice('1')">
    {{'send_invoice' | translate}}</button>
</div>

<div class="form-boxwrapper grid grid-column flex-wrap flex-align-start gap-20px"  [ngClass]="{'pointer-none': invoiceProgressLoader}">

  <div class="form-leftwrapper section-box ">
    <form [formGroup]="createInvoiceForm">
      <div class="grid2">
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
          <mat-label>{{'customer_first_name' | translate}}</mat-label>
          <input matInput formControlName="customer_first_name">
        </mat-form-field>
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
          <mat-label>{{'customer_last_name' | translate}}</mat-label>
          <input matInput formControlName="customer_last_name">
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label>{{'customer_email' | translate}}</mat-label>
        <input matInput formControlName="customer_email">
      </mat-form-field>
      <div class="grid2">
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
          <mat-label>{{'invoice_title' | translate}}</mat-label>
          <input matInput formControlName="invoice_title">
        </mat-form-field>
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
          <mat-label>{{'invoice_id' | translate}}</mat-label>
          <input matInput formControlName="invoice_id">
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label>{{'message' | translate}}</mat-label>
        <input matInput formControlName="message">
      </mat-form-field>
      <div class="grid2">
        <mat-form-field subscriptSizing="dynamic" appearance="outline" class="filter-date mb-20" appDayMonthYearFormat>
          <mat-label>{{'service_date' | translate}}</mat-label>
          <div class="w-full relative input-rightspace">
            <input matInput [matDatepicker]="picker" formControlName="service_date" readonly
              (dateChange)="checkServiceDate()">
            <mat-datepicker-toggle matIconSuffix [for]="picker"
              class="ic-input cursor-pointer date-icon"></mat-datepicker-toggle>
          </div>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" appearance="outline" class="filter-date mb-20" appDayMonthYearFormat>
          <mat-label>{{'due_date' | translate}}</mat-label>
          <div class="w-full relative input-rightspace">
            <input matInput [matDatepicker]="picker2" formControlName="due_date" [min]="minDueDate" readonly
              [disabled]="!createInvoiceForm.value.service_date" (dateChange)="checkServiceDate()">
            <mat-datepicker-toggle matIconSuffix [for]="picker2"
              class="ic-input cursor-pointer date-icon"></mat-datepicker-toggle>
          </div>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="mb-20">
        <label id="example-radio-group-label" class="text-grey-medium d-block mb-10">
          {{'invoice_send_option' | translate}}
        </label>
        <mat-radio-group aria-label="Select an option" formControlName="send_option"
          (change)="changeSendOption($event)">
          <mat-radio-button value="1">{{'invoice_immediately' | translate}}</mat-radio-button>
          <mat-radio-button value="2">{{'shedule_date' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="mb-20">
        <label id="example-radio-group-label" class="text-grey-medium d-block mb-10">
          {{'invoice_frequency' | translate}}
        </label>
        <mat-radio-group aria-label="Select an option" formControlName="frequency" (change)="changeFrequency($event)"
          [ngClass]="{'readonly': createInvoiceForm.value?.send_option=='1'}">
          <mat-radio-button value="1">{{'one_time' | translate}}</mat-radio-button>
          <mat-radio-button value="2">{{'recurring' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="grid2">
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-select"
          *ngIf="createInvoiceForm.value.frequency == '2'">
          <mat-select placeholder="{{'custom_date' | translate}}" formControlName="recurring_type">
            <mat-option value="">{{'select_custom_date' | translate}}</mat-option>
            <mat-option *ngFor="let option of frequencyOptions" [value]="option.value">
              {{ option.label | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" appearance="outline" class="filter-date mb-20"
          *ngIf="createInvoiceForm.value.send_option=='2'">
          <!-- <mat-label>Schedual Date</mat-label> -->
          <div class="w-full relative input-rightspace">
            <input matInput [matDatepicker]="picker3" placeholder="{{'select_date' | translate}}"
              formControlName="schedule_custom_date" [min]="toDay" readonly>
            <mat-datepicker-toggle matIconSuffix [for]="picker3"
              class="ic-input cursor-pointer date-icon"></mat-datepicker-toggle>
          </div>
          <mat-datepicker #picker3></mat-datepicker>
        </mat-form-field>
      </div>
    </form>
  </div>


  <!-- Starts Right form Box -->
  <div class="form-rightwrapper block">

    <div class="table-sectionbox mb-24 section-box boxblock order-productbox">
      <div (click)="redirectToProductAdd()"
        class="flex flex-justify-between flex-wrap gap-8px flex-align-center section-box table-sectionbx header-card">
        <h1 class="mb-0 text-grey-light">#{{createInvoiceForm.getRawValue()?.invoice_id}}</h1>
        <span class="fw-bold text-primary fw-bold cursor-pointer">+ {{'add_item' | translate}}</span>
      </div>
      <div class="table-responsive custom-scroll table-wrapper" style="height: auto;">
        <table mat-table [dataSource]="orderItems" class="mat-elevation-z8">

          <!-- Action Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="action-col"></th>
            <td mat-cell *matCellDef="let element;let j = index;" class="nowrap">
              <div class="flex table-actionbtn" (click)="removeproduct(j)">
                <span class="icon-ic-trash cursor-pointer"></span>
              </div>
            </td>
          </ng-container>

          <!-- ITEMS Column -->
          <ng-container matColumnDef="items">
            <th mat-header-cell *matHeaderCellDef class="item-namecol">{{'invoice_items' | translate}}</th>
            <td mat-cell *matCellDef="let element" class="nowrap">{{element.name}}</td>
          </ng-container>

          <!-- PRICE Column -->
          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef>{{'invoice_price' | translate}}</th>
            <td mat-cell *matCellDef="let element">${{element.price}} </td>
          </ng-container>

          <!-- QTY Column -->
          <ng-container matColumnDef="qty">
            <th mat-header-cell *matHeaderCellDef class="action-col">{{'invoice_quantity' | translate}}.</th>
            <td mat-cell *matCellDef="let element;let i=index" class="nowrap">
              <div class="product-updatewrap flex">
                <span class="flex-shrink-0 counterupdate-btn decrese-btn" (click)="changeItemQuantity(i,'decrease')">
                  <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.04999 1H10.95" stroke="white" stroke-width="1.2" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                <input class="form-control counter-updater flex-shrink-0" [value]="element.quantity" />
                <span class="flex-shrink-0 counterupdate-btn increase-btn" (click)="changeItemQuantity(i,'increase')">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.04999 8H12.95" stroke="white" stroke-width="1.2" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M8 3.0498V12.9498" stroke="white" stroke-width="1.2" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="itemTotal">
            <th mat-header-cell *matHeaderCellDef class="text-center">{{'item_total' | translate}}</th>
            <td mat-cell *matCellDef="let element" class="text-center">${{element.itemTotal }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;let j=index;"></tr>

        </table>
        <div class="no-records" *ngIf="orderItems?.data?.length == 0">
          {{'no_records' | translate}}
        </div>
      </div>
    </div>

    <div class="section-box mb-24 boxblock ordertotal-box">
      <div class="card-box card-header ">
        <h3 class="text-grey-light mb-0">{{'order_total' | translate}}</h3>
      </div>
      <div class="totalcount-wrapper card-box block">
        <div class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between">
          <span class="fw-medium">{{'subtotal' | translate}}</span>
          <span>${{paymentObj.subTotal? paymentObj.subTotal : '00.00'}}</span>
        </div>
        
        <div class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between">
          <span class="fw-medium">{{'discount' | translate}}</span>
          <span>${{paymentObj.giftCardDiscount ? paymentObj.giftCardDiscount.toFixed(2) : '00.00'}}</span>
        </div>
     
        <div class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between">
          <span class="fw-medium">{{'tax' | translate}}({{taxPar}}%)</span>
          <span>${{paymentObj.tax ? paymentObj.tax : '00.00'}}</span>
        </div>
        <div class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between">
          <span class="fw-medium">{{'credit_card_processing_fee' | translate}}</span>
          <span>${{paymentObj.creditCardProcessingFee?paymentObj.creditCardProcessingFee.toFixed(2) : '00.00'}}</span>
        </div>
        <div class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between">
          <span class="fw-medium">{{'shipping_fee' | translate}}</span>
          <span>${{paymentObj.shippingFee?paymentObj.shippingFee.toFixed(2) : '00.00'}}</span>
        </div>
        <div class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between">
          <span class="fw-medium">{{'late_fee' | translate}}</span>
          <span>${{paymentObj.lateFee?paymentObj.lateFee.toFixed(2) : '00.00'}}</span>
        </div>
      </div>
      <div class="text-grey-medium fs-16 ordered-totalbx totalcount-bx flex flex-justify-between">
        <span class="h3 text-primary fw-bold ">{{'total' | translate}}</span>
        <span class="text-primary h3 fw-bold">${{paymentObj.total?paymentObj.total : '00.00'}}</span>
      </div>
    </div>

    <!-- Starts Choose Payment Method Section -->
    <div class="section-box payment-methodbox mb-24 payment-methodboxwrap">
      <div class="text-grey-medium paymentmethod-header fs-12 mb-20">
        <h5 class="mb-4 text-grey-light d-block">{{'choose_payment_method' | translate}}</h5>
        <span class="">{{'select_payment_method_inst' | translate}}</span>
      </div>
      <mat-accordion>
        <mat-expansion-panel expanded (opened)="changePanelState(true)" (closed)="changePanelState(false)" class="w-100"
          style="width:100%">
          <mat-expansion-panel-header>
            <mat-panel-title class="">
              <h5 class="text-grey-light mb-0">{{'credit_debit_card' | translate}}</h5>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <form [formGroup]="paymentMethodForm1">
              <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                <mat-label>{{'enter_name_on_card' | translate}}</mat-label>
                <input matInput formControlName="card_holder_name" maxlength="100">
              </mat-form-field>
              <div>
                <label class="form-label">{{'card_number' | translate}}</label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                  <input matInput formControlName="card_number" appCardMask required>
                </mat-form-field>
              </div>
              <div class="cardgrid-2">
                <div class="">
                  <label class="form-label">{{'expiry_date' | translate}}</label>
                  <div class="dategrid-2">
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                      <mat-select class="small-select" id="month" placeholder="{{'month' | translate}}"
                        formControlName="mm">
                        <mat-option *ngFor="let month of monthsList;" [value]="month.value"
                          [disabled]="month.disable">{{month.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                      <mat-select class="small-select" id="year" placeholder="{{'year' | translate}}"
                        formControlName="yy">
                        <mat-option *ngFor="let year of yearsList;" [value]="year">{{year}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                  <mat-label>{{'cvv' | translate}}</mat-label>
                  <div class="w-full relative input-rightspace">
                    <input matInput placeholder="---" type="password" maxlength="4" formControlName="cvv">
                    <span class="ic-input cursor-pointer" matTooltip="CVV">
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9 0C4.02571 0 0 4.02525 0 9C0 13.9742 4.02525 18 9 18C13.9743 18 18 13.9747 18 9C18 4.02571 13.9747 0 9 0ZM8.73369 13.2124C8.22428 13.2124 7.83334 12.7859 7.83334 12.3002C7.83334 11.8026 8.23612 11.388 8.73369 11.388C9.23129 11.388 9.64586 11.8026 9.64586 12.3002C9.64586 12.7859 9.24307 13.2124 8.73369 13.2124ZM10.0842 8.604C9.43263 9.11341 9.42075 9.46881 9.42075 10.0848C9.42075 10.31 9.30227 10.5706 8.72181 10.5706C8.23605 10.5706 8.07026 10.3929 8.07026 9.77685C8.07026 8.75802 8.52043 8.2723 8.86398 7.97614C9.25492 7.64441 9.91835 7.2772 9.91835 6.6375C9.91835 6.09251 9.44448 5.83189 8.85213 5.83189C7.64378 5.83189 7.90443 6.74413 7.26465 6.74413C6.9448 6.74413 6.55386 6.53084 6.55386 6.06885C6.55386 5.42914 7.28835 4.48137 8.88768 4.48137C10.404 4.48137 11.411 5.32252 11.411 6.43609C11.411 7.54966 10.404 8.35523 10.0842 8.604Z"
                          fill="#989898" />
                      </svg>
                    </span>
                  </div>
                </mat-form-field>
              </div>
            </form>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel style="width:100%">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h5 class="text-grey-light mb-0">{{'gift_card' | translate}}</h5>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div>
            <form [formGroup]="paymentMethodForm2">
              <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                <mat-label>{{'enter_gift_card' | translate}}</mat-label>
                <input matInput formControlName="gift_card" appTrim appUppercaseAlphaNumeric>
              </mat-form-field>
              <button mat-flat-button [disabled]="!paymentMethodForm2.controls.gift_card.value"
                class="btn-outline-primary btn-lg mb-10" (click)="applyGiftCard()">
                {{'enter_gift_card' | translate}}
              </button>
            </form>
          </div>

        </mat-expansion-panel>
      </mat-accordion>

    </div>
    <!-- Ends Choose Payment Method Section -->

    <div class=" grid flex-wrap grid2-col gap-24px">
      <button mat-flat-button class="btn-outline-primary btn-lg" type="button" (click)="shareVia(1)">
        {{'share_via_email' | translate}}</button>
      <button mat-flat-button class="btn-outline-primary btn-lg" type="button" (click)="shareVia(2)">
        {{'share_via_text' | translate}}</button>
    </div>
  </div>
</div>