<div>
  <button class="flex-shrink-0 close-btn" mat-icon-button (click)="close()">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.992" fill-rule="evenodd" clip-rule="evenodd"
        d="M7.29688 -0.015625C7.75522 -0.015625 8.21353 -0.015625 8.67188 -0.015625C11.2075 0.259457 13.2022 1.43654 14.6562 3.51562C15.4006 4.66028 15.8433 5.91028 15.9844 7.26562C15.9844 7.74478 15.9844 8.22397 15.9844 8.70312C15.7001 11.2258 14.523 13.2102 12.4531 14.6562C11.2993 15.4071 10.0389 15.8498 8.67188 15.9844C8.21353 15.9844 7.75522 15.9844 7.29688 15.9844C4.80813 15.7118 2.83416 14.566 1.375 12.5469C0.592031 11.3802 0.128491 10.0989 -0.015625 8.70312C-0.015625 8.22397 -0.015625 7.74478 -0.015625 7.26562C0.268627 4.74291 1.44571 2.75852 3.51562 1.3125C4.66941 0.561612 5.92981 0.118903 7.29688 -0.015625ZM4.98438 4.51562C5.221 4.47762 5.42934 4.53491 5.60938 4.6875C6.39625 5.47959 7.18794 6.26606 7.98438 7.04688C8.81294 6.2235 9.64628 5.40581 10.4844 4.59375C10.8816 4.42409 11.1889 4.52306 11.4062 4.89062C11.475 5.093 11.4645 5.29091 11.375 5.48438C10.5629 6.32247 9.74525 7.15581 8.92188 7.98438C9.74525 8.81294 10.5629 9.64628 11.375 10.4844C11.5447 10.8816 11.4457 11.1889 11.0781 11.4062C10.8321 11.4891 10.6029 11.4578 10.3906 11.3125C9.59331 10.51 8.79125 9.71309 7.98438 8.92188C7.15581 9.74525 6.32247 10.5629 5.48438 11.375C5.08716 11.5447 4.77987 11.4457 4.5625 11.0781C4.49378 10.8757 4.50422 10.6778 4.59375 10.4844C5.40581 9.64628 6.2235 8.81294 7.04688 7.98438C6.2235 7.15581 5.40581 6.32247 4.59375 5.48438C4.41631 5.03159 4.5465 4.70869 4.98438 4.51562Z"
        fill="#03A7F6" />
    </svg>
  </button>
  <mat-dialog-content class="viewtransation-modal">
    <div class="viewtransation-logo mb-12 text-center">
      <div class="logo-smallmodalbx">
        <img [src]="selectedTheme === 'light' ? 'assets/images/logo-main-light.svg' : 'assets/images/logo-main.svg'"
          [alt]="selectedTheme === 'light' ? 'MuyBi Light' : 'MuyBi Dark'"
          [ngClass]="{'logo-light': selectedTheme === 'light', 'logo-dark': selectedTheme === 'dark'}" />
      </div>
    </div>
    <div class="bg-primary invoice-tobox gap-8px flex mb-24 flex-justify-between flex-align-center">
      <div>
        <span>{{'invoice_to' | translate}}:</span>
        <h4 class="mb-0 fs-16 fw-bold">{{transactionDetail?.customer_name?transactionDetail?.customer_name:'-'}} </h4>
        <span>{{transactionDetail?.customer_phone_number?transactionDetail?.customer_phone_number:'-'}}</span>
      </div>
      <div class="bg-white text-grey-medium radius-12 text-end amount-wrapper">
        <span class="d-block text-uppercase">{{'total_amounts' | translate}}</span>
        <h4 class="fs-24 d-block mb-0 amount-pricebx">
          ${{transactionDetail?.total_amount ? transactionDetail?.total_amount.toFixed(2):'00.00'}}</h4>
        <span class="d-block ">{{transactionDetail?.transaction_date_time | dateTime24Hr:true}}</span>
      </div>
    </div>

    <div class="invoice-topbox flex flex-justify-between mb-24 flex-wrap">
      <div class="invoice-infobx">
        <span class="d-block">{{'transaction_id' | translate}}:</span>
        <span class="d-block info-val">
          {{getSelectedTransctionDetail?.transaction_id ? getSelectedTransctionDetail?.transaction_id : '-'}}
        </span>
      </div>
      <div class="invoice-infobx">
        <span class="d-block">{{'payment_mode' | translate}}:</span>
        <span class="d-block info-val">{{transactionDetail?.payment_method?
          (transactionDetail?.payment_method| paymentMethodType):'-'}}</span>
      </div>
      <div class="invoice-infobx">
        <span class="d-block">{{'invoice_number' | translate}}:</span>
        <span
          class="d-block info-val">{{transactionDetail?.invoice_number?('#'+transactionDetail?.invoice_number):'-'}}</span>
      </div>
      <div class="invoice-infobx">
        <span class="d-block">{{'transaction_due_date' | translate}}:</span>
        <span class="d-block info-val">
          {{transactionDetail?.due_date ? (transactionDetail?.due_date | dateTime24Hr:false) :'-'}}</span>
      </div>
    </div>

    <div class="table-responsive custom-scroll mb-16">
      <table mat-table [dataSource]="transactionDetail?.order_items" class="mat-elevation-z8 invoice-table ">
        <ng-container matColumnDef="no">
          <th mat-header-cell *matHeaderCellDef> {{'items' | translate}} </th>
          <td mat-cell *matCellDef="let element;let index=index"> {{index+1}} </td>
        </ng-container>

        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef> {{'invoice_price' | translate}} </th>
          <td mat-cell *matCellDef="let element"> ${{element.price?element.price.toFixed(2):'00.00'}} </td>
        </ng-container>

        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef> {{'invoice_quantity' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
        </ng-container>

        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef> {{'transaction_total' | translate}} </th>
          <td mat-cell *matCellDef="let element"> ${{(element.price * element.quantity).toFixed(2)}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

    <div class="totalbox">
      <div class="totalcount-wrapper mb-12">
        <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
          <span>{{'subtotal' | translate}}</span>
          <span class="text-grey-light">
            ${{transactionDetail?.sub_total?transactionDetail?.sub_total.toFixed(2):'00.00'}}</span>
        </div>
        <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
          <span>{{'discount' | translate}}</span>
          <span
            class="text-grey-light">${{transactionDetail?.discount?transactionDetail?.discount.toFixed(2):'00.00'}}</span>
        </div>
        <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
          <span>{{'gift_card' | translate}}</span>
          <span
            class="text-grey-light">${{transactionDetail?.giftcard?.value?transactionDetail?.giftcard?.value.toFixed(2):'00.00'}}</span>
        </div>
        <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
          <span>{{'tax' | translate}}({{taxPer}}%)</span>
          <span class="text-grey-light">
            ${{transactionDetail?.tax ? transactionDetail?.tax.toFixed(2):'00.00'}}
          </span>
        </div>
        <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
          <span>{{'credit_card_processing_fee' | translate}}</span>
          <span class="text-grey-light">
            ${{transactionDetail?.processing_fee?transactionDetail?.processing_fee.toFixed(2):'00.00'}}
          </span>
        </div>
        <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
          <span>{{'shipping_fee' | translate}}</span>
          <span class="text-grey-light">
            ${{transactionDetail?.shipping_fee?transactionDetail?.shipping_fee.toFixed(2):'00.00'}}
          </span>
        </div>
        <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
          <span>{{'late_fee' | translate}}</span>
          <span
            class="text-grey-light">${{transactionDetail?.late_fee?transactionDetail?.late_fee.toFixed(2):'00.00'}}</span>
        </div>
        <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
          <span>{{'tip' | translate}}</span>
          <span class="text-grey-light">${{transactionDetail?.tip?transactionDetail?.tip.toFixed(2):'00.00'}}</span>
        </div>
        <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
          <span>{{'additional_amount' | translate}}</span>
          <span class="text-grey-light">
            ${{transactionDetail?.additional_amount?transactionDetail?.additional_amount.toFixed(2):'00.00'}}</span>
        </div>

        <!-- <ng-container *ngFor="let transaction of transactionDetail?.transactions?.data">
          <ng-container *ngIf="transaction.payment_method != 1 && transaction.payment_method != 2">
            <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
              <span>{{'transaction_amount' | translate}}</span>
              <span class="text-grey-light">
                ${{transactionDetail?.giftcard?.value ? transactionDetail?.giftcard?.value.toFixed(2):'00.00'}}</span>
            </div>
          </ng-container>
        </ng-container> -->
      </div>
      <div class="text-grey-medium fs-16 ordered-totalbx subtottalbx mb-12 flex flex-justify-between mb-24">
        <span class="h3 text-primary fw-bold">{{'total' | translate}}</span>
        <span class="text-primary h3 fw-bold">
          ${{transactionDetail?.total_amount? transactionDetail?.total_amount.toFixed(2):'00.00'}}</span>
      </div>
    </div>

    <div class="invoice-footer flex flex-justify-between flex-align-center">
      <div class="flex flex-column">
        <span class="fs-16 fw-semibold d-block">Muybi</span>
        <a mat-raised-button [href]="domainUrl" target="_blank" class="footerlink text-grey-medium">{{domainUrl}}</a>
        <div class="flex gap-4px text-grey-medium muybi-info gap-6px flex-wrap">
          <span>contactmuybi.com</span>
          <span>/</span>
          <span>(684) 879 - 0102</span>
          <!-- <a href="tel:(684)879-0102">(684) 879 - 0102</a> -->
        </div>
      </div>
      <img src="assets/images/small-logo.svg" alt="MuyBi" />
    </div>

  </mat-dialog-content>
</div>