import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
export const defaultLanguage = 'en'; // en, es  // Default es but temp en
export const domainURL = 'www.muybi.com'
export const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const numberPattern = /^\+?[0-9 ]+$/;
// export const googlePlaceAPIKey = 'AIzaSyCaKbVhcX_22R_pRKDYuNA7vox-PtGaDkI';
export const googlePlaceAPIKey = 'AIzaSyCsu9_b70IS1m92XjvaZw96NTNTD4fgHN4';
export const muyBiLogo = 'assets/images/muyBi-transparent-logo.png';
export const IPurl = `https://ipinfo.io`;
export const defaultCountry = 66; // "El Salvador"
export const defaultCountryCode = ['us']; // 
export const spinnerDiameter = 24; // 
export const dateFormat = 'DD/MM/YYYY'; // 

export const spanishCountryList = [
  { countryCode: 'SV', countryName: 'El Salvador', localLanguage: 'Spanish' },
  { countryCode: 'GT', countryName: 'Guatemala', localLanguage: 'Spanish' },
  { countryCode: 'HN', countryName: 'Honduras', localLanguage: 'Spanish' },
  { countryCode: 'NI', countryName: 'Nicaragua', localLanguage: 'Spanish' },
  { countryCode: 'CR', countryName: 'Costa Rica', localLanguage: 'Spanish' },
  { countryCode: 'PA', countryName: 'Panama', localLanguage: 'Spanish' }
];

export const languageArray = [
  { prefix: 'en', name: 'English' },
  { prefix: 'es', name: 'Español' },
];
export const availableToSellList = [
  { id: 1, name: 'Yes', translateLabel: 'yes' },
  { id: 2, name: 'No', translateLabel: 'no' },
];
export const paymentMethodOptions = [
  { id: 1, name: 'Credit/Debit Card', translateLabel: 'credit_debit_card' },
  { id: 2, name: 'Cash', translateLabel: 'cash' },
];
export const itemForList = [
  { id: 1, name: 'Restaurant', translateLabel: 'restaurant' },
  { id: 2, name: 'Bar', translateLabel: 'bar' },
];

export const menuType = [
  { id: 1, name: 'Regular', translateLabel: 'regular' },
  { id: 2, name: 'Combo', translateLabel: 'combo' }
]
export const foodType = [
  { id: 1, name: 'Veg', translateLabel: 'veg' },
  { id: 2, name: 'Non-Veg', translateLabel: 'non-veg' },
  { id: 3, name: 'Other', translateLabel: 'other' }
]
export const monthList = [
  { id: 1, value: '01', disable: false, name: 'January', shortName: 'Jan', translateLabel: 'january' },
  { id: 2, value: '02', disable: false, name: 'February', shortName: 'Feb', translateLabel: 'february' },
  { id: 3, value: '03', disable: false, name: 'March', shortName: 'Mar', translateLabel: 'march' },
  { id: 4, value: '04', disable: false, name: 'April', shortName: 'Apr', translateLabel: 'april' },
  { id: 5, value: '05', disable: false, name: 'May', shortName: 'May', translateLabel: 'may' },
  { id: 6, value: '06', disable: false, name: 'June', shortName: 'Jun', translateLabel: 'june' },
  { id: 7, value: '07', disable: false, name: 'July', shortName: 'Jul', translateLabel: 'july' },
  { id: 8, value: '08', disable: false, name: 'August', shortName: 'Aug', translateLabel: 'august' },
  { id: 9, value: '09', disable: false, name: 'September', shortName: 'Sep', translateLabel: 'september' },
  { id: 10, value: '10', disable: false, name: 'October', shortName: 'Oct', translateLabel: 'october' },
  { id: 11, value: '11', disable: false, name: 'November', shortName: 'Nov', translateLabel: 'november' },
  { id: 12, value: '12', disable: false, name: 'December', shortName: 'Dec', translateLabel: 'december' }
];

export const dayList = [
  { id: 1, value: '01', name: 'Today', translateLabel: 'today' },
  { id: 2, value: '02', name: 'Week', translateLabel: 'week' },
  { id: 3, value: '03', name: 'Month', translateLabel: 'month' },
  { id: 4, value: '04', name: 'Year', translateLabel: 'year' },
];

export const paymentMethod = [
  { id: 1, value: '01', name: 'Cash', translateLabel: 'cash' },
  { id: 2, value: '02', name: 'Card', translateLabel: 'card' },
  { id: 3, value: '03', name: 'Gift Card', translateLabel: 'gift_card' },
];
export const paymentType = [
  { id: 1, value: '01', name: 'Payment', translateLabel: 'payment' },
  { id: 2, value: '02', name: 'Refund', translateLabel: 'refund' },
];

export const inventoryStatusArray = [
  { id: 1, name: 'Available', translateLabel: 'available' },
  { id: 2, name: 'Not Available', translateLabel: 'not_available' },
];


export const accountProtectionOptions = [
  { id: 1, value: '1', name: 'Protect with Mobile Number', translateLabel: 'sign_step_three.protect_with_mobile_number' },
  { id: 2, value: '2', name: 'Protect with Microsoft', translateLabel: 'sign_step_three.protect_with_microsoft' }
];


export const paymentViaOption = [
  { id: 1, name: 'Via Email', translateLabel: 'via_email' },
  { id: 2, name: 'Via Text Message', translateLabel: 'via_text_msg' },
];

export const bankAccountType = [
  { value: 1, name: 'Saving', translateLabel: 'saving_bank_account' },
  { value: 2, name: 'Current', translateLabel: 'current_bank_account' },
];

export const typeOfTableReservation = [
  { value: 1, name: 'Available Table', translateLabel: 'available_table' },
  { value: 2, name: 'Reserved Table', translateLabel: 'reserved_table' },
]


// Module Array
export const moduleList = [
  { title: 'Home', name: 'Home', icon: 'icon-home', translateVar: 'home', route: '/dashboard' },
  { title: 'Checkout', name: 'Checkout', icon: 'icon-ic-checkout', translateVar: 'check_out', route: '/checkout' },
  { title: 'Invoices', name: 'Invoices', icon: 'icon-ic-receipt', translateVar: 'invoices', route: '/invoice' },
  { title: 'Transaction', name: 'Transaction', icon: 'icon-ic-transaction', translateVar: 'transaction', route: '/transaction' },
  { title: 'Manage Refunds', name: 'Manage Refunds', icon: 'icon-ic-cashback', translateVar: 'manage_refunds', route: '/refunds' },
  { title: 'Product Management', name: 'Product Management', icon: 'icon-ic-product', translateVar: 'product_management', route: '/product-list' },
  { title: 'Menu', name: 'Menu', icon: 'icon-ic-menu', translateVar: 'menu', route: '/menu' },
  { title: 'Orders Status', name: 'Orders Status', icon: 'icon-order-status', translateVar: 'orders_status', route: '/kitchen-station' },
  { title: 'Edit POS', name: 'Edit POS', icon: 'icon-pos-terminal', translateVar: 'edit_pos', route: '#' },
  { title: 'Table Management', name: 'Table Management', icon: 'icon-dinning-table', translateVar: 'table_management', route: '/table-management' },
  { title: 'Bar Station', name: 'Bar Station', icon: 'icon-station', translateVar: 'bar_station', route: '/bar-station' },
  { title: 'Bar Inventory', name: 'Bar Inventory', icon: 'icon-inventory', translateVar: 'bar_inventory', route: '/bar-inventory' },
  { title: 'Billing', name: 'Billing', icon: 'icon-billing', translateVar: 'billing', route: '/billing' },
  { title: 'Reservation Management', name: 'Reservation Management', icon: 'icon-reservation', translateVar: 'reservation_management', route: '/reservation' },
  { title: 'Waiting List Management', name: 'Waiting List Management', icon: 'icon-time-management', translateVar: 'waiting_list_management', route: '/waiting-list' },
  { title: 'Order History', name: 'Order History', icon: 'icon-ic-order-history', translateVar: 'order_history', route: '/order-history' },
  { title: 'Food Menus', name: 'Food Menus', icon: 'icon-ic-menu', translateVar: 'food_menus', route: '/food-menu' },
  { title: 'Inventory Management', name: 'Inventory Management', icon: 'icon-ic-inventory', translateVar: 'inventory_management', route: '/inventory-list' },
  { title: 'Vendor Management', name: 'Vendor Management', icon: 'icon-ic-inventory', translateVar: 'vendor_management', route: '/vendor-list' },
  { title: 'Purchase Order', name: 'Purchase Order', icon: 'icon-ic-purchase', translateVar: 'purchase_order', route: '/purchase-order' },
  { title: 'Online Ordering', name: 'Online Ordering', icon: 'icon-ic-online-order', translateVar: 'online_ordering', route: '/online-ordering' },
  { title: 'Settings', name: 'Settings', icon: 'icon-ic-settings', translateVar: 'settings', route: '/settings' },
]
export const moduleListTemp = [
  { title: 'Home', name: 'Home', icon: 'icon-home', translateVar: 'home', route: '/dashboard' },
  { title: 'Checkout', name: 'Checkout', icon: 'icon-ic-checkout', translateVar: 'check_out', route: '/checkout' },
  { title: 'Invoices', name: 'Invoices', icon: 'icon-ic-receipt', translateVar: 'invoices', route: '/invoice' },
  { title: 'Transaction', name: 'Transaction', icon: 'icon-ic-transaction', translateVar: 'transaction', route: '/transaction' },
  { title: 'Manage Refunds', name: 'Manage Refunds', icon: 'icon-ic-cashback', translateVar: 'manage_refunds', route: '/refunds' },
  { title: 'Product Management', name: 'Product Management', icon: 'icon-ic-product', translateVar: 'product_management', route: '/product-list' },
  { title: 'Menu', name: 'Menu', icon: 'icon-ic-menu', translateVar: 'menu', route: '/menu' },
  { title: 'Orders Status', name: 'Orders Status', icon: 'icon-order-status', translateVar: 'orders_status', route: '/under-development' },
  { title: 'Edit POS', name: 'Edit POS', icon: 'icon-pos-terminal', translateVar: 'edit_pos', route: 'under-development' },
  { title: 'Table Management', name: 'Table Management', icon: 'icon-dinning-table', translateVar: 'table_management', route: '/under-development' },
  { title: 'Bar Station', name: 'Bar Station', icon: 'icon-station', translateVar: 'bar_station', route: '/under-development' },
  { title: 'Bar Inventory', name: 'Bar Inventory', icon: 'icon-inventory', translateVar: 'bar_inventory', route: '/under-development' },
  { title: 'Billing', name: 'Billing', icon: 'icon-billing', translateVar: 'billing', route: '/under-development' },
  { title: 'Reservation Management', name: 'Reservation Management', icon: 'icon-reservation', translateVar: 'reservation_management', route: '/under-development' },
  { title: 'Waiting List Management', name: 'Waiting List Management', icon: 'icon-time-management', translateVar: 'waiting_list_management', route: '/under-development' },
  { title: 'Order History', name: 'Order History', icon: 'icon-ic-order-history', translateVar: 'order_history', route: '/under-development' },
  { title: 'Food Menus', name: 'Food Menus', icon: 'icon-ic-menu', translateVar: 'food_menus', route: '/under-development' },
  { title: 'Inventory Management', name: 'Inventory Management', icon: 'icon-ic-inventory', translateVar: 'inventory_management', route: '/under-development' },
  { title: 'Vendor Management', name: 'Vendor Management', icon: 'icon-ic-inventory', translateVar: 'vendor_management', route: '/under-development' },
  { title: 'Purchase Order', name: 'Purchase Order', icon: 'icon-ic-purchase', translateVar: 'purchase_order', route: '/under-development' },
  { title: 'Online Ordering', name: 'Online Ordering', icon: 'icon-ic-online-order', translateVar: 'online_ordering', route: '/under-development' },
  { title: 'Settings', name: 'Settings', icon: 'icon-ic-settings', translateVar: 'settings', route: '/settings' },
]


export const MY_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
