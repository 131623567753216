<div
  class="section-box mb-24 flex flex-wrap flex-justify-between flex-align-center header-section gap-8px backbtn-wrap">
  <h1 class="text-white mb-0">#{{refundDetailData.order_id}}</h1>
  <a mat-flat-button class="btn-outline-secondary backbtn" (click)="backToRefundList()">
    <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859"
        stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    {{'back' | translate}}</a>
</div>

<div class="section-box refund-boxwrap mb-24">
  <div class="refund-box">
    <div class="text-grey-light">
      <span class="text-grey-medium d-block mb-4 fw-medium">{{'subtotal' | translate}}</span>
      <span>${{refundDetailData.sub_total > 0?refundDetailData.sub_total.toFixed(2):'00.00'}}</span>
    </div>
    <div class="text-grey-light">
      <span class="text-grey-medium d-block mb-4 fw-medium">{{'discount' | translate}}</span>
      <span>${{refundDetailData.discount > 0?refundDetailData.discount.toFixed(2):'00.00'}}</span>
    </div>

    <div class="text-grey-light">
      <span class="text-grey-medium d-block mb-4 fw-medium">{{'gift_card' | translate}}</span>
      <span>${{refundDetailData.giftcard > 0?refundDetailData.giftcard.toFixed(2):'00.00'}}</span>
    </div>

    <div class="text-grey-light">
      <span class="text-grey-medium d-block mb-4 fw-medium">{{'tax' | translate}} ({{taxPer}}%)</span>
      <span>${{refundDetailData.tax > 0?refundDetailData.tax.toFixed(2):'00.00'}}</span>
    </div>

    <div class="text-grey-light">
      <span class="text-grey-medium d-block mb-4 fw-medium">{{'credit_card_processing_fee' | translate}}</span>
      <span>${{refundDetailData.processing_fee > 0?refundDetailData.processing_fee.toFixed(2):'00.00'}}</span>
    </div>
    <div class="text-grey-light">
      <span class="text-grey-medium d-block mb-4 fw-medium">{{'shipping_fee' | translate}}</span>
      <span>${{refundDetailData.shipping_fee > 0?refundDetailData.shipping_fee.toFixed(2):'00.00'}}</span>
    </div>

    <div class="text-grey-light">
      <span class="text-grey-medium d-block mb-4 fw-medium">{{'late_fee' | translate}}</span>
      <span>${{refundDetailData.late_fee > 0?refundDetailData.late_fee.toFixed(2):'00.00'}}</span>
    </div>

    <div class="text-grey-light">
      <span class="text-grey-medium d-block mb-4 fw-medium">{{'tip' | translate}}</span>
      <span>${{refundDetailData.tip > 0?refundDetailData.tip.toFixed(2):'00.00'}}</span>
    </div>

    <div class="text-grey-light">
      <span class="text-grey-medium d-block mb-4 fw-medium">{{'additional_amount' | translate}}</span>
      <span>${{refundDetailData.additional_amount > 0?refundDetailData.additional_amount.toFixed(2):'00.00'}}</span>
    </div>
    <div class="text-grey-light">
      <span class="text-grey-medium d-block mb-4 fw-medium">{{'transaction_payment_method' | translate}}</span>
      <span> {{ refundDetailData.payment_method | paymentMethodType }} </span>
    </div>
  </div>

  <div class="flex flex-justify-between flex-align-center flex-wrap gap-8px text-primary fs-20 pricebox">
    <span>Total</span>
    <span>${{refundDetailData.total_amount > 0 ? refundDetailData.total_amount.toFixed(2):'00.00'}}</span>
    <!-- <span>{{refundFormGroup.controls['refund_amount'].value}}</span> -->
  </div>
</div>

<div class="main-grid2 flex-align-start">
  <div class="items-select">
    <div class="table-sectionbox">
      <div class="flex flex-justify-between flex-wrap gap-8px flex-align-center section-box table-sectionbx">
        <h1 class="mb-0 mobile-full h1 text-grey-light">{{'select_items_for_refund' | translate}}</h1>
      </div>
      <div class="table-responsive custom-scroll table-wrapper refund-llist">
        <table mat-table [dataSource]="dataSourceRefundDetail" class="mat-elevation-z8">

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="action-col check-select">
              <!-- Master Checkbox -->
              <mat-checkbox class="example-margin" [checked]="isAllSelected()" [indeterminate]="isIndeterminate()"
                (change)="masterToggle()"></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element" class="nowrap">
              <!-- Row Checkbox -->
              <mat-checkbox class="example-margin" [(ngModel)]="element.isSelected"
                (ngModelChange)="onCheckboxChange(element)"></mat-checkbox>
            </td>
          </ng-container>

          <!-- ITEMS Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="item-namecol">{{'invoice_items' | translate}}</th>
            <td mat-cell *matCellDef="let element" class="nowrap">{{element.name}}</td>
          </ng-container>

          <!-- PRICE Column -->
          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef>{{'invoice_price' | translate}}</th>
            <td mat-cell *matCellDef="let element">${{element.price.toFixed(2)}} </td>
          </ng-container>

          <!-- QTY Column -->
          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef class="action-col">{{'invoice_quantity' | translate}}.</th>
            <td mat-cell *matCellDef="let element" class="nowrap">
              <div class="product-updatewrap flex flex-align-center">
                <span class="flex-shrink-0 counterupdate-btn decrese-btn" (click)="decrementQuantity(element)">
                  <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.04999 1H10.95" stroke="white" stroke-width="1.2" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                <span
                  class="form-control counter-updater flex-shrink-0 flex flex-align-center flex-justify-center">{{element.quantity}}</span>
                <span class="flex-shrink-0 counterupdate-btn increase-btn" (click)="incrementQuantity(element)">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.04999 8H12.95" stroke="white" stroke-width="1.2" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M8 3.0498V12.9498" stroke="white" stroke-width="1.2" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="total_amount">
            <th mat-header-cell *matHeaderCellDef class="text-center">{{'item_total' | translate}}</th>
            <td mat-cell *matCellDef="let element" class="text-center">
              ${{ (element.price * element.quantity).toFixed(2) }}
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>

    </div>
  </div>
  <div class="section-box">
    <h2 class="h1 text-grey-light mb-24">{{'reason_for_refund' | translate}}</h2>
    <form [formGroup]="refundFormGroup">
      <div class="form-grid2">
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
          <mat-label>{{'reason_for_refund' | translate}}</mat-label>
          <input matInput formControlName="refund_to">
        </mat-form-field>
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
          <mat-label>{{'refund_amount' | translate}}</mat-label>
          <input matInput formControlName="refund_amount">
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-select mb-20">
        <mat-label>{{'select_reason_for_refund' | translate}}</mat-label>
        <mat-select formControlName="select_reason">

          <mat-option *ngFor="let que of questionList" [value]="que.id"
            (click)="selectQuestion(que)">{{que.question}}</mat-option>

        </mat-select>
      </mat-form-field>
      <ng-container *ngIf="isSelectOtherReason">
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
          <mat-label>{{'reason_for_refund' | translate}}</mat-label>
          <textarea matInput rows="2" formControlName="reason"></textarea>
        </mat-form-field>
      </ng-container>

      <div class="flex flex-justify-end">
        <button mat-flat-button class="btn-lg mobile-full" (click)="sendRefundRequest()"
          [disabled]="refundFormGroup.invalid || (refundFormGroup.controls['refund_amount'].value.slice(1)) <= 0">
          {{'submit_request' | translate}}
        </button>
      </div>
    </form>
  </div>
</div>