<div class="section-box mb-24">
  <div class="category-filter flex innergap-16px custom-scroll">
    <button *ngFor="let item of menuItems" type="button" class="category-filterbtn flex flex-align-center flex-shrink-0"
      [ngClass]="{'active': menuType === item.type}" (click)="selectSettingMenu(item.type)"
      [attr.aria-label]="item.label">
      <span class="product-icon">
        <img [src]="item.icon" [alt]="item.altText" />
      </span>
      {{ item.labelTransalte |translate }}
    </button>
  </div>
</div>

<ng-container *ngIf="menuType === 1">
  <app-image-library></app-image-library>
</ng-container>

<ng-container *ngIf="menuType === 2">
  <ng-container *ngIf="isShowList; else addCategory">
    <app-manage-category></app-manage-category>
  </ng-container>
  <ng-template #addCategory>
    <app-add-category></app-add-category>
  </ng-template>
</ng-container>

<ng-container *ngIf="menuType === 3">
  <app-profile></app-profile>
</ng-container>

<ng-container *ngIf="menuType === 4">
  <!-- <app-sub-vendor-management></app-sub-vendor-management> -->
  <app-under-development></app-under-development>
</ng-container>

<ng-container *ngIf="menuType === 5">
  <app-under-development></app-under-development>
</ng-container>