import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  inject,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { MenuService } from '../services/menu.service';
import { MatDialog } from '@angular/material/dialog';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { NumberOnlyDirective } from '../../../../shared/directives/number-only.directive';
import { ConstantService } from '../../../../shared/services/constant.service';
import { ImageLibraryComponent } from '../../../settings/components/image-library/image-library.component';
import { ConfirmationComponent } from '../../../../shared/components/dialog/confirmation/confirmation.component';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent, } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { EncryptionService } from '../../../../shared/services/encryption.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FlotNumberDirective } from '../../../../shared/directives/flot-number.directive';
import { muyBiLogo } from '../../../../model/const/common.const';
@Component({
  selector: 'app-create-menu',
  standalone: true,
  imports: [
    MatFormFieldModule,
    RouterModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    FlotNumberDirective,
    NumberOnlyDirective,
    MatIconModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatProgressBarModule
  ],
  templateUrl: './create-menu.component.html',
  styleUrls: ['./create-menu.component.scss'],
  providers: [EncryptionService],
})
export class CreateMenuComponent {
  menuFormGroup!: FormGroup;
  menuDetail: any;
  menuCode: any;
  menuId = 0;
  branchLocatonListData: any;
  categoryListData: any;
  subscribe!: Subscription;
  subscribeGetAPI!: Subscription;
  subscribeDeleteAPI!: Subscription;
  subscribeSubject!: Subscription;
  action = 'Add';
  selectedImage: any = {};
  isImageSelcted: boolean = true;
  isSelectItem: boolean = true;
  @ViewChild('fileInput') fileInput!: ElementRef;
  selectedBranchLocation = 'select_business_location';
  selectedBranchLocationId = 0;
  @ViewChild('itemInput') itemInput!: ElementRef<HTMLInputElement>;
  logoUrl = muyBiLogo;
  menuProgressLoader = false;

  // inject
  private fb = inject(FormBuilder);
  private menuService = inject(MenuService);
  private toastr = inject(ToastrService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private dialog = inject(MatDialog);
  private encDecObj = inject(EncryptionService);
  public constantService = inject(ConstantService);

  //#region Temp Code
  productListData: Array<{ name: string; id: number }> = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  currentItem: string | null = null;

  selectedProductNames: string[] = [];
  constructor() {
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.menuProgressLoader = state;
    });
  }


  //#endregion Temp Code

  initialMenuForm() {
    this.menuFormGroup = this.fb.group({
      menuImage: [''],
      menuTitle: ['', Validators.required],
      branchLocation: ['', Validators.required],
      code: [{ value: '', disabled: true }, Validators.required],
      category: ['', Validators.required],
      description: ['', Validators.required],
      price: ['', Validators.required],
      selectedItem: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.constantService.progressLoader.next(true);
    this.initialMenuForm();
    this.getBusinesslocation();
    this.getProducts();
    this.getCategories();
    this.getBranchLocaiton();
    const id = this.route.snapshot.params['id'];
    if (id) {
      this.menuId = id;
      this.action = 'Edit';
      setTimeout(() => { this.getMenuDetail(id); }, 200)
    } else if (this.action == 'Add') {
      this.getMenuCode(this.selectedBranchLocationId);
    }

  }

  getBusinesslocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.selectedBranchLocationId = Number(id);
    }
  }

  //Filtering products
  filteredProducts = () => {
    const currentItem = this.currentItem;
    const itemFilter = currentItem === null ? '' : currentItem.toLowerCase();
    let result = itemFilter
      ? this.productListData.filter((item) =>
        item.name.toLowerCase().includes(itemFilter)
      )
      : this.productListData.slice();

    result = result.filter(
      (item) => !this.selectedProductNames.includes(item.name)
    );
    return result;
  };

  getMenuCode(id: number) {
    this.menuService.getMenuCode(id).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message);
      })
    )
      .subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.menuCode = res.data.code;
          this.menuFormGroup.controls['code'].setValue(this.menuCode);
          if (this.selectedBranchLocationId) {
            this.menuFormGroup.controls['branchLocation'].setValue(this.selectedBranchLocationId);
          }
        } else {
          this.toastr.error(res.message);
        }
      });
  }

  getBranchLocaiton() {
    this.menuService
      .getBranceList()
      .pipe(
        catchError((error) => {
          this.toastr.error(error.error.message);
          return throwError(() => error.error.message);
        })
      )
      .subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.branchLocatonListData = res.data.data;
        } else {
          this.toastr.error(res.message);
        }
      });
  }

  getProducts() {
    let menuPayload = {
      business_location_id: this.selectedBranchLocationId,
    }
    this.constantService.progressLoader.next(true);
    this.menuService.getProduct(menuPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
      this.toastr.error(error.error.message);
      return throwError(() => error.error.message);
    }))
      .subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.productListData = res.data.data;
          this.currentItem = '';
          this.filteredProducts();
          this.selectedProductNames = [];
          this.itemInput.nativeElement.value = '';
          this.menuFormGroup.controls['selectedItem'].setValue(this.currentItem);
        } else {
          this.toastr.error(res.message);
        }
      });
  }
  getCategories() {
    this.menuService
      .getCategoryList()
      .pipe(
        catchError((error) => {
          this.toastr.error(error.error.message);
          return throwError(() => error.error.message);
        })
      )
      .subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.categoryListData = res.data.data;
        } else {
          this.toastr.error(res.message);
        }
      });
  }

  getMenuDetail(id: number) {
    if (this.subscribeGetAPI) this.subscribeGetAPI.unsubscribe();
    this.subscribeGetAPI = this.menuService
      .getMenuDetail(id)
      .pipe(
        catchError((error) => {
          this.constantService.progressLoader.next(false);
          this.toastr.error(error.error.message);
          return throwError(() => error.error.message);
        })
      )
      .subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.menuDetail = res.data;
          this.setFormData(res.data);
        } else {
          this.toastr.error(res.message);
        }
      });
  }

  setFormData(data: any) {
    this.menuFormGroup.patchValue({
      menuTitle: data.title,
      branchLocation: data.business_location_id,
      code: data.code,
      category: data.category_id,
      description: data.description,
      price: data.price,
      selectedItem: data.products,
    });
    this.selectedImage = data.menu_image;
    this.selectedProductNames = data.products.map(
      (item: any) => item.item_name
    );
    this.currentItem = null;
    this.filteredProducts();
    this.constantService.progressLoader.next(false);
  }

  //#region Product Chip
  // add(event: MatChipInputEvent): void {
  //   const value = (event.value || '').trim();
  //   if (value) {
  //     this.selectedProductNames =
  //       this.selectedProductNames.length > 0          ? [...this.selectedProductNames, value]         : [value];
  //   }
  //   this.currentItem.set(null);
  // }

  remove(item: string): void {
    const index = this.selectedProductNames.findIndex((info) => info === item);
    if (index !== -1) {
      this.selectedProductNames.splice(index, 1);
    } else {
      this.menuFormGroup.controls['selectedItem'].setValue(this.currentItem);
    }
    this.itemInput.nativeElement.value = '';
    // Need both to trigger computed
    this.currentItem = '';
    this.currentItem = null;
    this.filteredProducts();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedProductNames = [
      ...this.selectedProductNames,
      event.option.viewValue,
    ];
    this.menuFormGroup.controls['selectedItem'].setValue(this.currentItem);
    this.currentItem = null;
    this.itemInput.nativeElement.value = '';
    this.filteredProducts();
    event.option.deselect();
  }
  //#endregion Product Chip
  //#region Add,update Menu API
  addUpdateMenu() {
    const itemIds: any[] = [];
    this.selectedProductNames.forEach((productName) => {
      const item = this.productListData.find(
        (info) => info.name === productName
      );
      if (item) {
        itemIds.push(item.id);
      }
    });
    this.menuFormGroup.markAllAsTouched();
    if (this.menuFormGroup.invalid) return;

    if (!this.selectedImage || !this.selectedImage.media_id) {
      this.isImageSelcted = false;
      return;
    } else this.isImageSelcted = true;

    let formData = new FormData();
    formData.append('title', this.menuFormGroup.value.menuTitle);
    formData.append('media_id', this.selectedImage.media_id);
    formData.append(
      'business_location_id',
      this.menuFormGroup.value.branchLocation
    );
    formData.append('code', this.menuFormGroup.getRawValue().code);
    formData.append('category_id', this.menuFormGroup.value.category);
    formData.append('description', this.menuFormGroup.value.description);
    formData.append('price', this.menuFormGroup.value.price);
    formData.append('item_id', itemIds.join(','));
    this.constantService.progressLoader.next(true);
    if (this.subscribe) this.subscribe.unsubscribe();
    if (this.action == 'Add') {
      this.subscribe = this.menuService
        .addMenu(formData)
        .pipe(
          finalize(() => this.constantService.progressLoader.next(false)),
          catchError((error) => {
            this.toastr.error(error.error.message);
            return throwError(() => error.error.message);
          })
        )
        .subscribe((res: ResponseMessage) => {
          if (res.code === 200) {
            this.toastr.success(res.message);
            this.backToMenuList();
          } else {
            this.toastr.error(res.message);
          }
        });
    } else if (this.action == 'Edit') {
      formData.append('menu_id', this.menuDetail.food_menu_id);
      this.subscribe = this.menuService
        .updateMenu(formData)
        .pipe(
          finalize(() => this.constantService.progressLoader.next(false)),
          catchError((error) => {
            this.toastr.error(error.error.message);
            return throwError(() => error.error.message);
          })
        )
        .subscribe((res: ResponseMessage) => {
          if (res.code === 200) {
            this.toastr.success(res.message);
            this.backToMenuList();
          } else {
            this.toastr.error(res.message);
          }
        });
    }
  }
  //#endregion Add,update Menu API

  backToMenuList() {
    this.router.navigate(['/menu']);
  }

  openImageLibrary() {
    let data = {
      type: 2,
      name: 'menu',
    };
    const dialogRef = this.dialog.open(ImageLibraryComponent, {
      width: '1000px',
      autoFocus: false,
      panelClass: ['modal-wrapper'],
      data,
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res?.success) {
        this.selectedImage = res.selectImage[0];
        this.isImageSelcted = true;
      }
    });
  }

  removeSelectedImage() {
    if (this.selectedImage) {
      this.selectedImage = {};
      this.constantService.showSuccess('image_remove');
    }
  }

  //Remove
  deleteMenuDialog(id: number) {
    let data = {
      type: 4,
      title: 'delete',
      message: 'delete_menu_message', //delete_Menu_message
      buttonName: { cancel: 'cancel', yes: 'yes' },
      id: id,
    };
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      panelClass: ['modal-wrapper'],
      disableClose: true,
      autoFocus: false,
      data,
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res?.success) {
        this.deleteMenu(id);
      }
    });
  }

  deleteMenu(id: number) {
    this.constantService.progressLoader.next(true);
    if (this.subscribeDeleteAPI) this.subscribeDeleteAPI.unsubscribe();
    this.subscribeDeleteAPI = this.menuService
      .deleteMenu(id)
      .pipe(
        finalize(() => this.constantService.progressLoader.next(false)),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return throwError(() => error.error.message);
        })
      )
      .subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.toastr.success(res.message);
          this.backToMenuList();
        } else {
          this.toastr.error(res.message);
        }
      });
  }

  changeBranchLocation() {
    this.selectedBranchLocationId = this.menuFormGroup.value.branchLocation
    this.getProducts();
  }

  ngOnDestroy(): void {
    if (this.subscribe) this.subscribe.unsubscribe();
    if (this.subscribeGetAPI) this.subscribeGetAPI.unsubscribe();
    if (this.subscribeSubject) this.subscribeSubject.unsubscribe();
  }
}
