import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'itemUnits',
  standalone: true,
})
export class ItemUnitPipe implements PipeTransform {

  private units = [
    { id: 1, name: 'Gm' },
    { id: 2, name: 'Kg' },
    { id: 3, name: 'Ltr' },
    { id: 4, name: 'Mtr' }
  ];

  transform(value: any): string {
    if (!value) return '';

    const unit = this.units.find(unit => unit.id === value);
    return unit ? unit.name : ''; // Return 'Unknown' if the ID is not found
  }
}
