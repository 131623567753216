<!-- <div class="under-develop flex flex-align-center flex-justify-center flex-column">
  <img src="assets/images/under-maintanance.png" alt="Page is Under Develoment" class="mb-24" />
  <div class="text-primary fw-medium">Page is Under Develoment</div>
</div> -->

<div class="under-develop flex flex-align-center flex-justify-center flex-column">
  <img
    [src]="themeType === 'light' ? 'assets/images/under_maintanance_light.png' : 'assets/images/under_maintanance_dark.png'"
    alt="Page is Under Development" class="mb-24" />
  <div class="text-primary fw-medium">Page is Under Development</div>
</div>
