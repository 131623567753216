<mat-dialog-content class="text-center">
  <div class="image-uploader grid grid-column flex-wrap relative">
    <label
      class="uploadimage-bx text-grey-medium bg-grey-lighter flex flex-column flex-align-center flex-justify-center"
      for="uploadimage1">
      <input type="file" id="uploadimage1" hidden (change)="uploadImage($event)" accept=".png, .jpg, .jpeg" #fileInput
        hidden (click)="imageCheck()" />
      <span class="icon-image-plus mb-16 d-block"></span>
      <span class="fs-14 fw-bold">{{'upload_image' | translate}}</span>
    </label>
    <span class="or-text text-grey-medium">Or</span>
    <div class="uploadimage-bx text-grey-medium bg-grey-lighter flex flex-column flex-align-center flex-justify-center"
      (click)="platform == 'muybi_app' ?fileInput.click():takePicture()">
      <span class="mb-16 flex">
        <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M10.5371 24H16.463C20.6244 24 22.7051 24 24.1998 23.0195C24.8468 22.595 25.4024 22.0495 25.8347 21.4142C26.8334 19.9467 26.8334 17.9039 26.8334 13.8182C26.8334 9.73247 26.8334 7.68962 25.8347 6.22214C25.4024 5.58685 24.8468 5.04139 24.1998 4.6169C23.2393 3.98685 22.037 3.76164 20.1961 3.68114C19.3176 3.68114 18.5613 3.02757 18.389 2.18182C18.1305 0.913184 16.996 0 15.6783 0H11.3219C10.0042 0 8.86962 0.913184 8.61119 2.18182C8.43891 3.02757 7.68255 3.68114 6.80408 3.68114C4.96319 3.76164 3.76082 3.98685 2.80041 4.6169C2.15335 5.04139 1.59779 5.58685 1.16545 6.22214C0.166748 7.68962 0.166748 9.73247 0.166748 13.8182C0.166748 17.9039 0.166748 19.9467 1.16545 21.4142C1.59779 22.0495 2.15335 22.595 2.80041 23.0195C4.29506 24 6.37575 24 10.5371 24ZM13.5001 8.36364C10.4318 8.36364 7.94453 10.8057 7.94453 13.8182C7.94453 16.8306 10.4318 19.2727 13.5001 19.2727C16.5683 19.2727 19.0556 16.8306 19.0556 13.8182C19.0556 10.8057 16.5683 8.36364 13.5001 8.36364ZM13.5001 10.5455C11.6591 10.5455 10.1667 12.0107 10.1667 13.8182C10.1667 15.6257 11.6591 17.0909 13.5001 17.0909C15.341 17.0909 16.8334 15.6257 16.8334 13.8182C16.8334 12.0107 15.341 10.5455 13.5001 10.5455ZM19.7964 9.45455C19.7964 8.85205 20.2938 8.36364 20.9075 8.36364H22.389C23.0026 8.36364 23.5001 8.85205 23.5001 9.45455C23.5001 10.057 23.0026 10.5455 22.389 10.5455H20.9075C20.2938 10.5455 19.7964 10.057 19.7964 9.45455Z"
            fill="#989898" />
        </svg>
      </span>
      <span class="fs-14 fw-bold">{{'take_pictures' | translate}}</span>
    </div>
  </div>
</mat-dialog-content>