<mat-dialog-content class="text-center">
  <form [formGroup]="paymentFormGroup">
    <div class="mb-24 radius-12">
      <img src="assets/images/send-icon.png" [alt]="('send_payment_link' | translate)" class="dialog-smallimg" />
    </div>
    <div class="block text-grey-light mb-24">
      <h3 class="h1 text-white">{{'send_payment_link' | translate}}</h3>
    </div>
    <div class="mb-20 text-center">
      <label id="example-radio-group-label" class="text-grey-light d-block mb-12 option-label">{{'choose_option' |
        translate}}</label>
      <mat-radio-group aria-label="Select an option" class="flex-justify-center"
        formControlName="selectedPaymentOption">
        <ng-container *ngFor="let payment of paymentOptions;">
          <mat-radio-button [value]="payment.id" (click)="setDynamicValidation(payment.id)">
            {{payment.translateLabel | translate}}
          </mat-radio-button>
        </ng-container>
      </mat-radio-group>
    </div>
    <ng-container *ngIf="paymentFormGroup.value.selectedPaymentOption == 1">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label>{{'email' | translate}}</mat-label>
        <input matInput formControlName="email" />
      </mat-form-field>
    </ng-container>
    <ng-container
      *ngIf="paymentFormGroup.value.selectedPaymentOption == 2 && preferredCountries && preferredCountries[0]">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label>{{'phone_number' | translate}}</mat-label>
        <ngx-mat-intl-tel-input formControlName="phoneNumber" class="w-full" [preferredCountries]="preferredCountries"
          [enablePlaceholder]="true" [enableSearch]="true" name="userNamePhone" #userNamePhone>
        </ngx-mat-intl-tel-input>
      </mat-form-field>
    </ng-container>

  </form>
  <div class="btn-box text-center">
    <button mat-flat-button mat-dialog-close class="btn-outline-primary">{{'cancel' | translate}}</button>
    <button mat-flat-button (click)="paymentLink()">{{'send_now' | translate}}</button>
  </div>
</mat-dialog-content>