<div class="section-box">
  <div
    class="flex flex-wrap flex-justify-between flex-align-center header-section gap-8px innergap-10px backbtn-wrap relative mb-24">
    <h1 class="text-grey-light mb-0 mobile-full text-truncate">#{{orderDetails?.order_id}}</h1>
    <button (click)="backToCheckout('back')" mat-flat-button class="btn-outline-secondary backbtn"><svg width="7"
        height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859"
          stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>{{'back' | translate}}</button>
    <button mat-flat-button class="split-paymentbtn mobile-full" (click)="splitPayment()">
      {{'split_payment' | translate}}</button>
  </div>

  <form [formGroup]="paymentFormGroup">
    <div class="main-grid2">
      <!-- Starts Choose Payment Method Section -->
      <div class="section-box payment-methodbox payment-methodboxwrap grey-accordion">
        <div class="text-grey-medium paymentmethod-header fs-12 mb-20">
          <h5 class="mb-4 text-grey-light d-block">{{'choose_payment_method' | translate}}</h5>
          <span class="">{{'select_payment_method_inst' | translate}}</span>
        </div>
        <mat-accordion class="block accordion-box">

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h5 class="text-grey-light mb-0">{{'gift_card' | translate}}</h5>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <mat-form-field appearance="outline" subscriptSizing="dynamic" class="discount-code mb-16">
                <mat-label>{{'gift_card' | translate}}</mat-label>
                <div class="relative w-full">
                  <input matInput formControlName="giftCard" appTrim appUppercaseAlphaNumeric>
                  <button *ngIf="appliedGiftCard" mat-flat-button class="apply-btn input-btn pointer-event"
                    type="button" (click)="removeGiftCardCode()">{{'remove' | translate}}</button>
                  <button *ngIf="!appliedGiftCard" mat-flat-button class="apply-btn input-btn pointer-event"
                    type="button" [disabled]="!paymentFormGroup.value.giftCard"
                    (click)="applyGiftCardCode()">Apply</button>
                </div>
              </mat-form-field>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #paymentPanel expanded (opened)="panel(true)" (closed)=" panel(false)">
            <mat-expansion-panel-header>
              <mat-panel-title class="" (click)="$event.stopPropagation()">
                <mat-radio-group class="grid grid-column" formControlName="paymentMethod"
                  (change)="changePaymentMethod();">
                  <mat-radio-button *ngFor="let pay of paymentMethodOption;" [value]="pay.id">
                    <h5 class="text-grey-light mb-0">{{pay.translateLabel | translate}}</h5>
                  </mat-radio-button>
                </mat-radio-group>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div formGroupName="cardPayment" *ngIf="paymentFormGroup.value.paymentMethod == 1">
              <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                <mat-label>{{'enter_name_on_card' | translate}}</mat-label>
                <input matInput formControlName="card_holder_name" maxlength="100">
              </mat-form-field>
              <div>
                <label class="form-label">{{'card_number' | translate}}*</label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                  <input matInput formControlName="card_number" appCardMask required>
                </mat-form-field>
              </div>
              <div class="cardgrid-2">
                <div class="">
                  <label class="form-label">{{'expiry_date' | translate}}*</label>
                  <div class="dategrid-2">
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16 small-select">
                      <mat-select id="month" placeholder="Month" formControlName="expiry_month">
                        <mat-option *ngFor="let month of monthsList;" [value]="month.value"
                          [disabled]="month.disable">{{month.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16 small-select">
                      <mat-select id="year" placeholder="Year" formControlName="expiry_year">
                        <mat-option *ngFor="let year of yearsList;" [value]="year">{{year}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                  <mat-label>{{'cvv' | translate}}</mat-label>
                  <div class="w-full relative input-rightspace">
                    <input matInput placeholder="---" type="password" maxlength="4" formControlName="cvv">
                    <span class="ic-input cursor-pointer" matTooltip="CVV">
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9 0C4.02571 0 0 4.02525 0 9C0 13.9742 4.02525 18 9 18C13.9743 18 18 13.9747 18 9C18 4.02571 13.9747 0 9 0ZM8.73369 13.2124C8.22428 13.2124 7.83334 12.7859 7.83334 12.3002C7.83334 11.8026 8.23612 11.388 8.73369 11.388C9.23129 11.388 9.64586 11.8026 9.64586 12.3002C9.64586 12.7859 9.24307 13.2124 8.73369 13.2124ZM10.0842 8.604C9.43263 9.11341 9.42075 9.46881 9.42075 10.0848C9.42075 10.31 9.30227 10.5706 8.72181 10.5706C8.23605 10.5706 8.07026 10.3929 8.07026 9.77685C8.07026 8.75802 8.52043 8.2723 8.86398 7.97614C9.25492 7.64441 9.91835 7.2772 9.91835 6.6375C9.91835 6.09251 9.44448 5.83189 8.85213 5.83189C7.64378 5.83189 7.90443 6.74413 7.26465 6.74413C6.9448 6.74413 6.55386 6.53084 6.55386 6.06885C6.55386 5.42914 7.28835 4.48137 8.88768 4.48137C10.404 4.48137 11.411 5.32252 11.411 6.43609C11.411 7.54966 10.404 8.35523 10.0842 8.604Z"
                          fill="#989898" />
                      </svg>
                    </span>
                  </div>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="paymentFormGroup.value.paymentMethod == 2">
              <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                <mat-label>{{'amount' | translate}}</mat-label>
                <input matInput formControlName="cashAmount" allowFlotNumberOnly>
              </mat-form-field>
            </div>
          </mat-expansion-panel>

        </mat-accordion>

      </div>
      <!-- Ends Choose Payment Method Section -->

      <!-- Starts Right Section -->
      <div class="flex flex-column">
        <div class="totalcount-wrapper  bg-boxwrap mb-24">
          <div class="mb-24 block">

            <div class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between">
              <span class="fw-medium">{{'subtotal' | translate}}</span>
              <span>${{paymentFormGroup.value.subTotal > 0?paymentFormGroup.value.subTotal:'00.00'}}</span>
            </div>
            <div class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between">
              <span class="fw-medium">{{'discount' | translate}}</span>
              <span>${{paymentFormGroup.value.discount > 0?paymentFormGroup.value.discount:'00.00'}}</span>
            </div>
            <div class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between">
              <span class="fw-medium">{{'tax' | translate}} ({{taxPer}}%)</span>
              <span>${{paymentFormGroup.value.tax > 0?paymentFormGroup.value.tax:'00.00'}}</span>
            </div>
            <div class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between">
              <span class="fw-medium">{{'tip' | translate}}</span>
              <span>${{paymentFormGroup.value.tip > 0 ?paymentFormGroup.value.tip:'00.00'}}</span>
            </div>
            <div *ngIf="paymentFormGroup.value.additionalAmount > 0"
              class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between">
              <span class="fw-medium">{{'additional_amount' | translate}}</span>
              <span> ${{paymentFormGroup.value.additionalAmount}} </span>
            </div>
            <div class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between">
              <span class="fw-medium">{{'gift_card' | translate}}</span>
              <span>
                ${{paymentFormGroup.value.giftCardAmount > 0?paymentFormGroup.value.giftCardAmount:'00.00'}}
              </span>

            </div>
          </div>
          <div class=" mb-24 text-grey-medium fs-16 ordered-totalbx totalcount-bx flex flex-justify-between">
            <span class="fs-18 text-white fw-medium ">{{'total_payment' | translate}}</span>
            <span class="text-primary h3 fw-bold">
              ${{paymentFormGroup.value.grandTotal > 0 ? paymentFormGroup.value.grandTotal:'00.00'}} </span>
          </div>

          <!-- Display Only Cash Amount  -->
          <div class="block" *ngIf="paymentFormGroup.value.paymentMethod == 2">
            <div class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between">
              <span class="fw-medium">{{'amount' | translate}}</span>
              <span>
                ${{paymentFormGroup.value?.cashAmount > 0 ? (+paymentFormGroup.value.cashAmount).toFixed(2):'00.00'}}
              </span>
            </div>
            <div class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between">
              <span class="fw-medium">{{'change_amount' | translate}}</span>
              <span>
                ${{paymentFormGroup.value.changeAmount > 0?paymentFormGroup.value.changeAmount:'00.00'}}
              </span>
            </div>
          </div>

        </div>
        <div class="section-btns">
          <!-- @if (paymentFormGroup.value.paymentMethod == 1) {
          <button mat-flat-button class="w-full mb-24 btn-lg" (click)="confirmOrder()">Pay Now</button>
          }@else if (paymentFormGroup.value.paymentMethod == 2) {
          <button mat-flat-button class="w-full mb-24 btn-lg" (click)="orderPayment(1)">Send Payment Link</button>
          } -->

          <button mat-flat-button class="w-full mb-24 btn-lg" (click)="confirmOrder()">
            {{'pay_now' | translate}}
          </button>

          <!-- <button mat-flat-button class="w-full mb-24 btn-lg" (click)="orderPayment(1)">Send Payment Link</button> -->

        </div>
      </div>
      <!-- Ends Right Section -->
    </div>
  </form>
</div>