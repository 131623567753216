<div class="section-box flex flex-justify-between flex-wrap gap-8px mb-24 flex-align-center header-box backbtn-wrap">
  <h1 class="text-white mb-0 text-truncate">{{(editId?'update_vendor':'add_vendor') | translate}}</h1>

  <button mat-flat-button class="btn-outline-secondary backbtn" (click)="backToList()"><svg width="7" height="15"
      viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859"
        stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    {{'back' | translate}}</button>
</div>

<form class="section-box form-box" [formGroup]="vendorForm" (ngSubmit)="createVendor()">
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
    <mat-label>{{'add_vendor_vendor_name' | translate}}</mat-label>
    <input matInput formControlName="vendor_name">
  </mat-form-field>

  <div class="form-grid2">
    <ng-container *ngIf="preferredCountries && preferredCountries[0]">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label>{{'add_vendor_vendor_number' | translate}}</mat-label>
        <ngx-mat-intl-tel-input formControlName="vendor_number" class="w-full" [preferredCountries]="preferredCountries"
          [enablePlaceholder]="true" [enableSearch]="true" name="userNamePhone" #userNamePhone>
        </ngx-mat-intl-tel-input>
      </mat-form-field>
    </ng-container>
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
      <mat-label>{{'add_vendor_vendor_email' | translate}}</mat-label>
      <input matInput type="text" formControlName="vendor_email">
    </mat-form-field>
  </div>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
    <mat-label>{{'add_vendor_description' | translate}}</mat-label>
    <textarea matInput rows="3" formControlName="vendor_description"></textarea>
  </mat-form-field>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
    <mat-label>{{'add_vendor_business_address_1' | translate}}</mat-label>
    <input matInput formControlName="vendor_business_address1">
  </mat-form-field>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
    <mat-label>{{'add_vendor_business_address_2' | translate}}</mat-label>
    <input matInput formControlName="vendor_business_address2">
  </mat-form-field>
  <div class="text-end">
    <button mat-flat-button class="btn-lg mobile-full"
      type="submit">{{(editId?'edit_vendor_update_now':'add_vendor_add_now') | translate}}</button>
  </div>
</form>