import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';

@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  private httpClient = inject(HttpClient);

  //#region Inventory API's

  // Get Inventory List
  getInventoryList(data: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    params = params.append('page', data.page);
    params = params.append('per_page', data.per_page);
    params = params.append('user_business_location_id', data.business_location_id);
    params = params.append('item_for', data.item_for);
    data.search_text ? (params = params.append('search', data.search_text)) : '';
    data.category_text ? (params = params.append('category_id', data.category_text)) : '';
    data.branch_text ? (params = params.append('business_location_id', data.branch_text)) : '';
    data.inventory_text ? (params = params.append('available_to_sell', data.inventory_text)) : '';
    return this.httpClient.get<ResponseMessage>('get-inventories', { params: params });
  }

  // Add New Product
  addProduct(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('add-item', data);
  }

  // Get Product Detail
  getProductDetail(id: number): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>(`get-item-by-id/${id}`);
  }

  // Get Inventory Detail
  getInventoryDetail(id: number): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>(`inventory-details/${id}`);
  }

  // update Product
  updateProduct(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('update-item', data);
  }

  // Delete Product
  deleteProduct(id: number): Observable<ResponseMessage> {
    return this.httpClient.delete<ResponseMessage>(`delete-item/${id}`);
  }



  // ------- Filter and Add products--------------

  // Get Category List
  getCategoryList(): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>('get-all-categories');
  }

  // Get Branch List
  getBranceList(): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>('view-branch');
  }

  //#endregion Product API's
}
