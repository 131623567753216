<div
  class="section-box mb-24 flex flex-wrap flex-align-center gap-16px header-bx mb-25 backbtn-wrap flex-justify-between">
  <h1 class="text-white mb-0 text-truncate">
    {{ this.action == "Add" ? "Create menu" : "Edit Menu" }}
  </h1>
  <button mat-flat-button class="btn-outline-secondary backbtn" (click)="backToMenuList()">
    <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859"
        stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    {{ "back" | translate }}
  </button>
</div>

<div class="mb-24" [ngClass]="{ 'pointer-none': menuProgressLoader}">
  <form class="form-box section-box card-boxblock" [formGroup]="menuFormGroup">
    <div class="mb-20">
      <label for="upload-image" class="form-label">{{"Image" | translate}}</label>
      <div class="image-uploadbx mb-16 flex flex-align-center flex-justify-center cursor-pointer"
        [ngClass]="{ 'image-invalid': !isImageSelcted }" (click)="openImageLibrary()">
        <img [src]="
            selectedImage?.media_id
              ? selectedImage.url
              : logoUrl
          " [alt]="selectedImage?.media_id ? selectedImage.image_name : 'MuyBi'"
          [class]="selectedImage?.media_id ? 'img-fit' : 'placeholder-img'" />
      </div>
      <div class="grid grid-column flex-align-center gap-16px flex-wrap addedit-pic">
        <ng-container *ngIf="selectedImage && selectedImage?.media_id">
          <div class="grid grid-column add-edittext flex-align-center gap-8px text-primary cursor-pointer fw-medium"
            (click)="openImageLibrary()">
            <img src="assets/images/icons/gallery-edit.svg" alt="Update" />
            <span>{{"update" | translate}}</span>
          </div>
          <span class="divider-line text-grey-dark">|</span>
          <div class="grid grid-column add-edittext flex-align-center gap-8px text-danger cursor-pointer fw-medium"
            (click)="removeSelectedImage()">
            <img src="assets/images/icons/close-circle.svg" alt="Remove" />
            <span>{{"remove" | translate}}</span>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="menu-grid3 mb-20">
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label class="fs-14">{{"menu_title" | translate}}</mat-label>
        <input matInput formControlName="menuTitle" />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-select">
        <mat-label class="fs-14">{{"branch_location" | translate}}</mat-label>
        <mat-select formControlName="branchLocation">
          <mat-option *ngFor="let branch of branchLocatonListData;" [value]="branch.business_location_id"
            (click)="changeBranchLocation()">
            {{ branch.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label class="fs-14">{{"code" | translate}}</mat-label>
        <input matInput type="number" formControlName="code" />
      </mat-form-field>
    </div>
    <div class="menu-grid2 mb-20">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-select">
        <mat-label class="fs-14">{{'menu_category' | translate}}</mat-label>
        <mat-select formControlName="category">
          <mat-option *ngFor="let category of categoryListData;" [value]="category.category_id">
            {{ category.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label class="fs-14">{{"menu_price" | translate}}</mat-label>
        <input matInput formControlName="price" allowFlotNumberOnly />
      </mat-form-field>
    </div>
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
      <mat-label>{{"menu_description" | translate}}</mat-label>
      <textarea matInput rows="3" formControlName="description"></textarea>
    </mat-form-field>
    <!-- <div class="mb-20  block">
      <span class="form-label"> {{'upload_image' | translate}}</span>
      @if(selectedImageList.length > 0){
      @for (imageData of selectedImageList; track $index) {
      <div class="flex menu-uploadwrap flex-align-center">
        <label class="small-uploadimg flex flex-align-center flex-justify-center flex-shrink-0" for="upload-image">
          <input type="file" class="form-control" id="upload-image" hidden />
          <img [src]="imageData.url" alt="Menu" class="img-fit" />
        </label>
        <div class="w-full relative input-rightspace">
          <div class="form-control">{{imageData?.image_name}}</div>
          <label for="upload-image" class="icon-image-plus ic-input cursor-pointer"></label>
        </div>
        <span class="cursor-pointer text-danger flex-shrink-0 fs-14 addremove-btnbx"
          (click)="removeSelectedImage(imageData.media_id)">
          x {{'remove' | translate}}
        </span>
      </div>
      }
      }
      <div class="flex menu-uploadwrap flex-align-center cursor-pointer"
        [ngClass]="{'mat-form-field-invalid': !isImageSelcted}" (click)="openImageLibrary()">
        <label class="small-uploadimg flex flex-align-center flex-justify-center flex-shrink-0">
          <span class="icon-image-plus "></span>
        </label>
        <div class="w-full flex flex-align-center w-full">
          <div class="w-full relative input-rightspace">
            <div class="form-control"></div>
            <label class="icon-image-plus ic-input cursor-pointer"></label>
          </div>
        </div>
        <span class="cursor-pointer text-gredient flex-shrink-0 fs-14 addremove-btnbx">+ {{'add_More' |
          translate}}</span>
      </div>
    </div> -->

    <div class="mb-20 block">
      <mat-form-field
        [ngClass]="{'mat-input-chip-invalid': (action == 'Edit' && menuFormGroup.controls['selectedItem'].invalid|| action == 'Add' && menuFormGroup.controls['selectedItem'].touched && menuFormGroup.controls['selectedItem'].invalid)}"
        appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{"select_item_for_menu" | translate}}</mat-label>
        <mat-chip-grid #chipGrid aria-label="Item selection" formControlName="selectedItem">
          <ng-container *ngFor="let item of selectedProductNames;">
            <mat-chip-row (removed)="remove(item)">
              {{ item }}
              <button matChipRemove [attr.aria-label]="'remove ' + item">
                <mat-icon>
                  <img src="assets/images/icons/close-circle.svg" alt="Remove" />
                </mat-icon>
              </button>
            </mat-chip-row>
          </ng-container>
          <input name="currentItem" #itemInput placeholder="" [(ngModel)]="currentItem"
            [ngModelOptions]="{ standalone: true }" [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" required />
        </mat-chip-grid>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let product of filteredProducts();" [value]="product.id"
            [disabled]="selectedProductNames.includes(product.name)">
            {{ product.name }}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="gap-20px grid grid-column tablet-fullgrid flex-justify-end footer-btns" 
    [ngClass]="{
      'mobile-halfgrid': action === 'Edit', 
      'tablet-fullgrid': action !== 'Edit'
    }">
      <button *ngIf="action == 'Edit'" mat-flat-button class="btn-danger btn-lg deletemenu-btn"
        (click)="deleteMenuDialog(menuId)">
        {{"delete_menu" | translate}}
      </button>
      <button mat-flat-button class="btn-lg updatemenu-btn" (click)="addUpdateMenu()">
        {{ action == "Add" ? ("save" | translate) : ("update_menu" | translate) }}
      </button>
    </div>
  </form>
</div>