import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { SendReceiptComponent } from '../../components/send-receipt/send-receipt.component';

@Component({
  selector: 'app-payment-status',
  standalone: true,
  imports: [
    RouterModule,
    MatButtonModule,
  ],
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss']
})
export class PaymentStatusComponent {
  readonly dialog = inject(MatDialog);
  openDialog() {
    const dialogRef = this.dialog.open(SendReceiptComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
      scrollStrategy: new NoopScrollStrategy()
    });
  }
}
