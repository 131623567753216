<div class="section-box mb-24 card-boxblock">
  <h1 class="text-grey-light mb-20">{{'change_password' | translate}}</h1>
  <div class="">
    <form [formGroup]="passwordFormGroup" appPasswordMatchAndValidator>
      <div class="grid3-box mb-20">
        <!-- Old Password Field -->
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>{{"old_password" | translate}}</mat-label>
          <div class="password-field w-full" [class.active]="hideOldPass">
            <input matInput [type]="hideOldPass ? 'text' : 'password'" formControlName="oldPassword" required />
            <span class="eye-icon" (click)="hideOldPass = !hideOldPass"></span>
          </div>
          <mat-error *ngIf="passwordFormGroup.get('oldPassword')?.hasError('required')">
            {{ 'Old password is required' | translate }}
          </mat-error>
        </mat-form-field>

        <!-- New Password Field -->
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>{{"new_password" | translate}}</mat-label>
          <div class="password-field w-full" [class.active]="hidePass">
            <input matInput [type]="hidePass ? 'text' : 'password'" formControlName="password" required />
            <span class="eye-icon" (click)="hidePass = !hidePass"></span>
          </div>
          <!-- <mat-error *ngIf="passwordFormGroup.get('password')?.hasError('required')">
            {{ 'Password is required' | translate }}
          </mat-error> -->
          <mat-error *ngIf="passwordFormGroup.get('password')?.hasError('minLength')">
            {{ 'password_atleast_6_char_long' | translate }}
          </mat-error>
          <mat-error
            *ngIf="passwordFormGroup.get('password')?.hasError('passwordComplexity') && !passwordFormGroup.get('password')?.hasError('minLength')">
            {{ 'string_password_warn' | translate }}
          </mat-error>
        </mat-form-field>

        <!-- Confirm Password Field -->
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>{{"confirm_password" | translate}}</mat-label>
          <div class="password-field w-full" [class.active]="hideCPass">
            <input matInput [type]="hideCPass ? 'text' : 'password'" formControlName="confirmPassword" required />
            <span class="eye-icon" (click)="hideCPass = !hideCPass"></span>
          </div>
          <mat-error *ngIf="passwordFormGroup.get('confirmPassword')?.hasError('passwordMismatch')">
            {{ 'password_not_match' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Submit Button -->
      <div class="grid mobile-fullgrid flex-justify-end">
        <button mat-flat-button class="mobile-full btn-lg" (click)="changePassword()">
          {{'update_now' | translate}}
        </button>
      </div>
    </form>

  </div>
</div>