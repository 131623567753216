<div class="section-box flex flex-justify-between flex-wrap gap-8px mb-24 flex-align-center ">
  <h1 class="text-white mb-0 mobile-full">{{'vendor_management' | translate}}</h1>
  <div class="flex gap-24px flex-grow-1 flex-justify-end flex-align-center">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-input search-inputbx">
      <div class="w-full relative input-rightspace">
        <input type="search" class="form-control" (input)="searchVendor($event)" matInput
          placeholder="{{'search_menu' | translate}}" />
        <mat-icon class="ic-input cursor-pointer">search</mat-icon>
      </div>
    </mat-form-field>
    <button routerLink="/vendor-list/add" mat-flat-button class="btn-lg flex-shrink-0">{{'add_vendor' |
      translate}}</button>
  </div>
</div>

<div class="table-sectionbox">
  <!-- <div class="flex flex-justify-between flex-wrap gap-8px flex-align-center section-box table-sectionbx">
    <h1 class="mb-0 mobile-full h3 mobile-full">{{'vendor_list' | translate}}</h1>
    <div class="flex gap-16px flex-grow-1 flex-justify-end flex-align-center mobile-full filter-optionswrap flex-wrap">
      <mat-radio-group aria-label="Select an option" class="vendor-radiocheck">
        <mat-radio-button value="1">{{'weekly' | translate}}</mat-radio-button>
        <mat-radio-button value="2">{{'monthly' | translate}} </mat-radio-button>
        <mat-radio-button value="2">{{'yearly' | translate}}</mat-radio-button>
      </mat-radio-group>
      <button mat-flat-button class="flex-shrink-0 export-btn btn-lg" >{{'export' | translate}}</button>
    </div>
  </div> -->
  <div class="table-responsive custom-scroll table-wrapper" infinite-scroll (scrolled)="vendorListScrollDown()"
    [immediateCheck]="true" [scrollWindow]="false" [infiniteScrollDistance]="20" [infiniteScrollThrottle]="0">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <!-- Position Column -->
      <ng-container matColumnDef="vendorName">
        <th mat-header-cell *matHeaderCellDef>{{'vendor_name' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="nowrap"> {{element.name}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>{{'vendor_email' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="nowrap"> {{element.email}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef>{{'vendor_phone_number' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="nowrap"> {{element.number}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>{{'vendor_description' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="nowrap">
          <div class="text-truncate description-coltext" [innerHTML]="element?.description"></div>
        </td>
      </ng-container>
      <ng-container matColumnDef="businessDetail">
        <th mat-header-cell *matHeaderCellDef>{{'vendor_address' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{(element?.address1+' '+element?.address2) |
          maxcharlength:maxcharLimit}}</td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="action-col">{{'action' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex table-actionbtn flex-justify-center">
            <!-- <button mat-icon-button aria-label="View" matTooltip="View" ><span class="icon-eye"></span></button> -->
            <button mat-icon-button aria-label="Edit" matTooltip="{{ 'edit' | translate }}"
              (click)="editVendor(element.id)">
              <span class="icon-development"> </span>
            </button>
            <button (click)="openDeleteVendorDialog(element.id,element.name)" mat-icon-button aria-label="Delete"
              matTooltip="Delete"><span class="icon-ic-trash"></span></button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div class="no-records" *ngIf="totalRecords == 0 && !vendorProgressLoader">
      {{ "no_records" | translate }}
    </div>

  </div>
</div>