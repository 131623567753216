import {
  Component,
  inject,
  Inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogModule,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConstantService } from '../../../services/constant.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { numberValidator } from '../../../validators/number-validator.directive';
import { AuthService } from '../../../../modules/auth/auth.service';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-share-via-email-mobile',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    TranslateModule,
    MatInputModule,
    MatFormFieldModule,
    NgxMatIntlTelInputComponent
  ],
  templateUrl: 'share-via-mobile-email.component.html',
  styleUrls: ['./share-via-mobile-email.component.scss']
})
export class ShareViaEmailMobileComponent {
  fb = inject(FormBuilder);
  dialogRef = inject(MatDialogRef<ShareViaEmailMobileComponent>)
  shareViaMobileEmailtForm!: FormGroup;
  modalData!: any;
  private authService = inject(AuthService);
  preferredCountries: any = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.modalData = { ...data }
  }
  ngOnInit() {
    this.initForm();
  }
  getPrefferedCountry() {
    this.authService.getCountryName().subscribe((response) => {
      if (response) {
        this.preferredCountries = [response.country.toLowerCase()];
      }
    });
  }
  initForm() {
    this.shareViaMobileEmailtForm = this.fb.group({
      share_email_phone_number: ['', Validators.required]
    })
    if (this.modalData.share_via == 1) {
      this.shareViaMobileEmailtForm.get('share_email_phone_number')?.addValidators(Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'))
    } else {
      this.getPrefferedCountry()
      this.shareViaMobileEmailtForm.get('share_email_phone_number')?.addValidators(numberValidator())
    }
  }
  submitForm() {
    if (this.shareViaMobileEmailtForm.invalid) {
      return;
    }
    this.dialogRef.close(this.shareViaMobileEmailtForm.value.share_email_phone_number);
  }
  close() {
    this.dialogRef.close();
  }
}
