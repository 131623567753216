<div class="authentication-page flex flex-align-center flex-justify-center" [ngClass]="{'pointer-none': loader}">
    <ng-container *ngIf="!checkRouteExist">
        <button mat-flat-button class="btn-dark skip-btn" (click)="redirectToDashboard()">
            {{ "skip" | translate }}
        </button>
        <button mat-flat-button class="btn-dark lang-btn" (click)="changeLanguage()">
            {{ language | languageName }}
        </button>
    </ng-container>
    <form [formGroup]="signupAccountProtectionForm">
        <div class="authentication-box">
            <div class="auth-headerwrapper flex">
                <div class="authlogo-box">
                    <div class="auth-box flex flex-align-center flex-justify-center">
                        <img src="assets/images/logo-small.svg" alt="MuyBi" />
                    </div>
                </div>
                <div class="auth-header">
                    <h1>{{ "sign_step_three.title" | translate }}</h1>
                    <p class="text-grey">
                        {{ "sign_step_three.sub_title" | translate }}
                    </p>
                </div>
            </div>
            <div class="auth-innerbox">
                <div class="mb-20">
                    <label id="example-radio-group-label" class="text-grey-medium block mb-10">
                        {{ "sign_step_three.business_protection" | translate }}
                    </label>
                    <mat-radio-group aria-label="Select an option" formControlName="protectWith"
                        (change)="protectWithValueChange($event)">
                        <mat-radio-button *ngFor="let option of accProtectionOptions" [value]="option.value">
                            {{ option.translateLabel | translate }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <ng-container *ngIf="signupAccountProtectionForm.controls.protectWith.value === '1'">
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
                        <mat-label>
                            {{ "sign_step_three.enter_mobile_number" | translate }}
                        </mat-label>
                        <ngx-mat-intl-tel-input formControlName="phone" class="w-full"
                            [preferredCountries]="preferredCountries" [enablePlaceholder]="true" [enableSearch]="true"
                            name="userNamePhone" #userNamePhone #phoneEle (input)="getCountryCode(userNamePhone)">
                        </ngx-mat-intl-tel-input>
                    </mat-form-field>
                </ng-container>
                <div class="mb-20" *ngIf="signupAccountProtectionForm.controls.protectWith.value === '2'">
                    <ng-container *ngIf="qrCodeSVG == ''">
                        <mat-progress-bar mode="indeterminate" class="table-loader mb-24"></mat-progress-bar>
                    </ng-container>
                    <ng-container *ngIf="qrCodeSVG != ''">
                        <div class="text-center">
                            <div [innerHTML]="qrCodeSVG"></div>
                        </div>
                    </ng-container>
                </div>
                <div class="grid grid2-col mb-24 gap-24px">
                    <button mat-stroked-button class="w-full" (click)="back()">
                        {{ "back" | translate }}
                    </button>
                    <!-- <button mat-flat-button class="w-full" type="submit" [disabled]="submitDisabled"
                        (click)="formSubmit(signupAccountProtectionForm.value)">
                        {{checkRotrExists?('submit'| translate):('next' | translate) }}
                    </button> -->
                    <button mat-flat-button type="submit" class="w-full loader-btn"
                        (click)="formSubmit(signupAccountProtectionForm.value)">
                        <mat-spinner *ngIf="loader" [diameter]="spinnerDiameter"
                            color="var(--mdc-filled-button-label-text-color)"></mat-spinner>
                        <ng-container *ngIf="!loader">
                            {{checkRotrExists?('submit'| translate):('next' | translate) }}
                        </ng-container>
                    </button>
                </div>
                <div class="bg-dark linkinfo-wrap flex text-grey-light radius-12 fs-14">
                    <mat-icon class="flex-shrink-0">
                        <img src="assets/images/icons/ic-warning.svg" [alt]="('warning' | translate)" />
                    </mat-icon>
                    <span>
                        {{ "sign_step_three.term_1" | translate }}
                        <a class="text-link" routerLink="/privacy-policy" target="_blank">
                            {{"privacy_policy" | translate}}
                        </a>
                        {{ "sign_step_three.term_2" | translate }}
                        <a class="text-link" routerLink="/terms-and-conditions" target="_blank">
                            {{ "sms_terms_conditions" | translate }}.
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </form>
</div>