import { Component, Inject, inject, Optional } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { WaitingListService } from '../service/waiting-list.service';
import { catchError, finalize, throwError } from 'rxjs';
import { ConstantService } from '../../../shared/services/constant.service';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { ToastrService } from 'ngx-toastr';
import { NumberOnlyDirective } from '../../../shared/directives/number-only.directive';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-update-list',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NumberOnlyDirective
  ],
  templateUrl: './update-list.component.html',
  styleUrls:[ './update-list.component.scss']
})
export class UpdateListComponent {
  private waitingService = inject(WaitingListService);
  private fb = inject(FormBuilder);
  private toastr = inject(ToastrService);
  private dialogRef = inject(MatDialogRef<UpdateListComponent>)
  private constantService = inject(ConstantService);
  waitingListForm: any;
  localData: any
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) data: UpdateListComponent) {
    this.localData = { ...data }
  }
  initialWaitingForm() {
    this.waitingListForm = this.fb.group({
      id: [""],
      customer_name: ['', Validators.required],
      queue_number: ['', Validators.required],
      no_of_guest: ['', Validators.required],
      mobile_no: ['', Validators.required]
    });
    if (this.localData?.element) {
      this.waitingListForm.patchValue(this.localData?.element)
      this.waitingListForm.patchValue({ id: this.localData?.element?.id })
    }
  }
  ngOnInit() {
    this.initialWaitingForm();
  }

  addUpdateWaiting() {
    this.constantService.progressLoader.next(true);
    if (this.waitingListForm.invalid) {
      return;
    }
    this.waitingService.addWaiting(this.waitingListForm.value).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err) => {
        return throwError(() => err.error.message)
      })
    ).subscribe((res: ResponseMessage) => {
      if (res.code == 200) {
        this.toastr.success(res.message);
        this.dialogRef.close(true)
      } else {
        this.toastr.error(res.message);
      }
    })
  }

}
