<mat-dialog-content class="text-center">
  <div class="mb-24 radius-12">
    <img src="assets/images/verification.png" alt="Message Sent Successfully" class="dialog-smallimg" />
  </div>
  <div class="block text-grey-light mb-24">
    <h3 class="h1 mb-24 text-white">{{'message_sent_succefully' | translate}}</h3>
    <p>{{'your_message_delievered_to_client' | translate}}</p>
  </div>
  <div class="text-center">
    <button mat-flat-button mat-dialog-close class="mobile-full backto-listbtn">
      {{'back_to_list' | translate}}
    </button>
  </div>
</mat-dialog-content>