import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../../shared/components/common/header/header.component';
import { SidebarComponent } from '../../../../shared/components/common/sidebar/sidebar.component';


@Component({
  selector: 'app-my-orders',
  standalone: true,
  imports: [
    RouterModule,
    HeaderComponent,
    SidebarComponent,
    MatButtonModule
  ],
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent {
  isClassAdded = false;

  toggleClass() {
    this.isClassAdded = !this.isClassAdded;
  }
}
