import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';

@Injectable({
  providedIn: 'root'
})
export class WaitingListService {

  constructor(private http:HttpClient) { }

  getWaitingList(data:any):Observable<ResponseMessage>{
    let params = new HttpParams();
    params = params.append('page',data.page);
    params = params.append('per_page',data.per_page);
    return this.http.get<ResponseMessage>('get-waiting-list',{params});
  }

  addWaiting(data:any):Observable<ResponseMessage>{
    return this.http.post<ResponseMessage>('add-waiting',data);
  }

  updateWaiting(data:any):Observable<ResponseMessage>{
    return this.http.post<ResponseMessage>('update-waiting',data);
  }
  
  deleteWaiting(id:any):Observable<ResponseMessage>{
    return this.http.delete<ResponseMessage>(`delete-waiting/${id}`);
  }
}
