<div class="block">
  <div class="mb-24 maingrid-2">
    <div class="section-box flex flex-justify-between flex-align-center gap-8px">
      <h1 class="mb-0">
        {{'primary_setup' |translate}}</h1>
      <span class="icon-edit-pencil cursor-pointer flex-shrink-0 fs-24" (click)="redirectToPrimarySetup()"></span>
    </div>
    <div class="section-box flex flex-justify-between flex-align-center gap-8px">
      <h1 class="mb-0">
        {{'account_protection' | translate}}</h1>
      <span class="icon-edit-pencil cursor-pointer flex-shrink-0 fs-24" (click)="redirectToAccountProtection()"></span>
    </div>
  </div>

  <div class="section-box mb-24 card card-boxblock">
    <h1 class="text-grey-light mb-20">{{'my_profile' |translate}}</h1>
    <div class="">
      <form [formGroup]="profileFormGroup">
        <div class="profile-grid2">
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
            <mat-label>{{'name' |translate}}</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
            <mat-label>{{'email_phone' |translate}}</mat-label>
            <input matInput formControlName="emailOrPhone" />
          </mat-form-field>
        </div>
        <div class="grid flex-justify-end mobile-fullgrid">
          <button mat-flat-button class="mobile-full btn-lg" (click)="updateProfile()">
            {{'update_now' |translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
  <app-change-password></app-change-password>
</div>