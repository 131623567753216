import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  MatRadioModule,
} from '@angular/material/radio';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { catchError, Subscription, tap, throwError } from 'rxjs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { numberValidator } from '../../../shared/validators/number-validator.directive';
import { EncryptionService } from '../../../shared/services/encryption.service';
import { OtpVerificationComponent } from '../../../shared/components/dialog/otp-verification/otp-verification.component';
import { accountProtectionOptions, spinnerDiameter } from 'src/app/model/const/common.const';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConstantService } from 'src/app/shared/services/constant.service';
import { LanguageNamePipe } from 'src/app/shared/pipes/language-name.pipe';

@Component({
  selector: 'app-account-protection',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxGpAutocompleteModule,
    NgxMatIntlTelInputComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatProgressBarModule,
    RouterModule,
    TranslateModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    LanguageNamePipe,
  ],
  templateUrl: './account-protection.component.html',
  styleUrls: ['./account-protection.component.scss']
})
export class AccountProtectionComponent {
  private toastr = inject(ToastrService);
  private authService = inject(AuthService);
  private sanitizer = inject(DomSanitizer);
  private fb = inject(FormBuilder);
  readonly dialog = inject(MatDialog);
  private subscription!: Subscription;
  public submitDisabled = false;
  loader: boolean = false;
  spinnerDiameter = spinnerDiameter;
  public qrCodeSVG: any = '';
  accProtectionOptions = accountProtectionOptions;
  private encDecObj = inject(EncryptionService);
  private constantService = inject(ConstantService);
  language: any;
  private translate = inject(TranslateService);
  private route = inject(Router)
  public submit: any = 'next';
  public checkRouteExist: any;
  public accountProtection: any = this.route.getCurrentNavigation()?.extras?.state;
  public checkRotrExists: any = this.accountProtection ? true : false;
  public signupAccountProtectionForm = this.fb.group({
    protectWith: ['1', Validators.required],
    phone: ['', [Validators.required]],
    country_code: [''],
    iso_code: [''],
    secret: [''],
  });
  @ViewChild('phoneEle') phoneEle: any;
  @Input() preferredCountries: Array<string> = ['us'];
  @Input() set phone(value: string | undefined) {
    if (value) {
      this.signupAccountProtectionForm.patchValue(
        { phone: value },
        { onlySelf: true, emitEvent: false }
      );
    }
  }
  @Output() backButtonPressed = new EventEmitter();
  @Output() submittedAccountProtection = new EventEmitter();

  ngOnInit() {
    if (this.route.url == '/account-protection') {
      this.checkRouteExist = true;
    } else {
      this.checkRouteExist = false;
    }
    this.getLang();
    this.getCountry();
  }

  getCountryCode(value: any): any {
    let country_code = value.selectedCountry.dialCode;
    let iso_code = value.selectedCountry.iso2;
    this.signupAccountProtectionForm.patchValue({
      country_code: country_code,
      iso_code: iso_code
    })
  }

  setSubmitDisabledDisabled(value: boolean): void {
    this.submitDisabled = value;
  }

  openDialog(protectWith: string, phone: string): void {
    const userDetail = JSON.parse(
      this.encDecObj.getDecrypt(localStorage.getItem('userDetail'))
    );

    let data: any = {
      user_id: userDetail.user_id,
      protectWith,
      title: 'sign_step_three.verify.title',
      dont_receive: 'sign_step_three.verify.dont_receive',
      api: 1,
      resend: protectWith === '1' ? true : false,
      is_send_from: 3, //1= login, 2 = Signup 3 = account protection
    };

    if (protectWith === '1') {
      data.sub_title = '';
      this.translate.get('sign_step_three.verify.sub_title', { phone }).subscribe((message: string) => {
        data.sub_title = message;
      });
    } else {
      data.sub_title = 'sign_step_three.verify.sub_title_microsoft';
    }
    const dialogRef = this.dialog.open(OtpVerificationComponent,
      {
        width: '564px',
        panelClass: ['modal-wrapper', 'otp-verfication'],
        disableClose: true,
        data
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result.success) {
        if (this.checkRouteExist) {
          this.redirectToProfile();
        } else {
          this.submittedAccountProtection.emit();
        }

      }
    });
  }

  back() {
    if (this.checkRouteExist) {
      this.redirectToProfile();
    } else {
      this.backButtonPressed.emit()
    }
  }
  protectWithValueChange(data: any): void {
    if (data.value === '1') {
      this.signupAccountProtectionForm.controls.phone.setValidators([
        Validators.required,
        numberValidator(),
      ]);
      this.signupAccountProtectionForm.controls.phone.setValue('')
      this.signupAccountProtectionForm.controls.secret.setValidators([]);
      this.signupAccountProtectionForm.controls.phone.updateValueAndValidity();
      this.signupAccountProtectionForm.controls.secret.updateValueAndValidity();
    } else {
      this.signupAccountProtectionForm.controls.phone.setValidators([]);
      this.signupAccountProtectionForm.controls.secret.setValidators([
        Validators.required,
      ]);
      this.signupAccountProtectionForm.controls.phone.updateValueAndValidity();
      this.signupAccountProtectionForm.controls.secret.updateValueAndValidity();

      // Get QR Code
      if (this.subscription) this.subscription.unsubscribe();
      this.subscription = this.authService
        .getMicrosoftQRCode()
        .pipe(
          tap((res: ResponseMessage) => {
            if (res.code === 200) {
              this.signupAccountProtectionForm.patchValue({
                secret: res.data.secret,
              });
              this.qrCodeSVG = this.sanitizer.bypassSecurityTrustHtml(
                res.data.qr_code_image
                  .replace(/<\?xml.*\?>\n/, '')
                  .replace(/\n$/, '')
              )
            } else {
              this.toastr.error(res.message);
            }
          }),
          catchError((err) => {
            this.toastr.error(err.error.message);
            return throwError(() => err.error.message);
          })
        )
        .subscribe();
    }
  }

  formSubmit(value: any) {
    if (this.subscription) this.subscription.unsubscribe();
    if (this.signupAccountProtectionForm.invalid) {
      return;
    }
    const data: any = {
      type: value.protectWith,
      iso_code: value.iso_code,
      country_code: value.country_code
    };
    if (value.protectWith === '1') {
      data.phone_number = value.phone;
    } else {
      data.secret = value.secret;
    }

    if (this.subscription) this.subscription.unsubscribe();
    this.setSubmitDisabledDisabled(true);
    this.loader = true;
    this.subscription = this.authService
      .saveAccountProtection(data)
      .pipe(
        tap((res: ResponseMessage) => {
          this.setSubmitDisabledDisabled(false);
          this.loader = false;
          if (res.code === 200) {
            this.openDialog(value.protectWith, value.phone);
          } else {
            this.toastr.error(res.message);
          }
        }),
        catchError((err) => {
          this.toastr.error(err.error.message);
          this.setSubmitDisabledDisabled(false);
          this.loader = false;
          return throwError(() => err.error.message);
        })
      )
      .subscribe();
  }
  getCountry() {
    this.authService.getCountryName().subscribe((response) => {
      if (!this.accountProtection) {
        if (response && this.preferredCountries.length == 0) {
          this.preferredCountries = [response.country.toLowerCase()];
        }
        this.signupAccountProtectionForm.patchValue({ country_code: this.phoneEle?.selectedCountry?.dialCode, iso_code: this.preferredCountries[0].toLowerCase(), protectWith: '1' })
      } else {
        if (this.accountProtection['account_protection'] != null) {
          if (this.accountProtection['account_protection']?.protected_with.toString() == '2') {
            let data = { value: 2 }
            this.protectWithValueChange(data)
          }
          this.preferredCountries = [this.accountProtection['account_protection']?.iso_code];
          this.signupAccountProtectionForm.patchValue({ country_code: this.accountProtection['account_protection']?.country_code, iso_code: this.accountProtection['account_protection']?.iso_code, protectWith: this.accountProtection['account_protection']?.protected_with.toString(), phone: this.accountProtection['account_protection']?.phone_number })
        } else {
          this.signupAccountProtectionForm.patchValue({ country_code: this.phoneEle?.selectedCountry?.dialCode, iso_code: this.preferredCountries[0].toLowerCase(), protectWith: '1' })
        }
      }

    });
  }

  changeLanguage() {
    this.constantService.changeLanguages(this.language);
    this.getLang();
  }

  getLang() {
    this.language = this.constantService.getLanguage();
  }

  redirectToProfile() {
    this.route.navigate(['/settings'])
  }

  redirectToDashboard() {
    this.route.navigate(['/dashboard'])
  }

}
