
import { DOCUMENT } from '@angular/common';
import { Component, inject, Inject, } from '@angular/core';
import { RouterLink, RouterModule } from '@angular/router';
import { LangType, ThemeType } from './model/types/GlobalTypes';
import { environment } from '../environments/environment';
import { ConstantService } from './shared/services/constant.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // standalone: true
})
export class AppComponent {

  private constantService = inject(ConstantService);

  themeType = 'light';
  langType = 'en'; // Default es but temp eng

  //#region Localstorage 
  language = 'selected_language';
  theme = 'selected_theme';
  constructor(@Inject(DOCUMENT) private document: Document) {
    this.initializeTheme();
    this.initializeLang();
  }

  /**
   * Initial theme settings
   */
  private initializeTheme(): void {
    const storedTheme = this.constantService.getTheme();
    this.constantService.setTheme(storedTheme);
  }

  /**
   * Initial language settings
   */
  private initializeLang(): void {
    let storedLang = this.constantService.getLanguage();
    this.constantService.translateData(storedLang);
    this.constantService.setLang(storedLang);
  }


  ngOnInit() {
    document.documentElement.style.setProperty(
      '--app-height',
      `${window.innerHeight}px`
    );
    window.addEventListener('resize', () => {
      document.documentElement.style.setProperty(
        '--app-height',
        `${window.innerHeight}px`
      );
    });
  }
}

