import { ChangeDetectorRef, Component, inject, ViewChild } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { VendorManagementService } from '../../service/vendor-management.service';
import { emailValidator } from '../../../../shared/validators/email-validator.directive';
import { ConstantService } from '../../../../shared/services/constant.service';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { NumberOnlyDirective } from '../../../../shared/directives/number-only.directive';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { AuthService } from '../../../auth/auth.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-add-vendor',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule,
    NgxMatIntlTelInputComponent,
    ReactiveFormsModule,
    NumberOnlyDirective,
    TranslateModule
  ],
  templateUrl: './add-vendor.component.html',
  styleUrls: ['./add-vendor.component.scss']
})
export class AddVendorComponent {
  vendorManagementService = inject(VendorManagementService);
  authService = inject(AuthService);
  constantService = inject(ConstantService);
  cdr = inject(ChangeDetectorRef);
  fb = inject(FormBuilder);
  toastr = inject(ToastrService);
  route = inject(Router);
  vendorForm: any;
  subscriptionAdd!: Subscription;
  preferredCountries: any = [''];
  ac = inject(ActivatedRoute);
  editId = this.ac.snapshot.params['id'];
  @ViewChild('userNamePhone') userNamePhone: any;

  ngOnInit() {
    this.initForm();
    this.getPrefferedCountry();
  }
  getPrefferedCountry() {
    this.authService.getCountryName().subscribe((response) => {
      if (response) {
        this.preferredCountries = [response.country.toLowerCase()];
        this.cdr.detectChanges();
      }
    });
  }
  initForm() {
    this.vendorForm = this.fb.group({
      vendor_name: ['', Validators.required],
      vendor_number: ['', Validators.required],
      vendor_email: ['', [Validators.required, emailValidator()]],
      vendor_description: [''],
      vendor_business_address1: ['', Validators.required],
      vendor_business_address2: ['', Validators.required]
    })
    if (this.editId) {
      this.getVendorDetail();
    }

  }
  getVendorDetail() {
    this.constantService.progressLoader.next(true);
    this.vendorManagementService.getVendorDetail(this.editId).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err) => {
        this.toastr.error(err.error.message);
        return throwError(() => err.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code == 200) {
          let editData = res.data;
          this.vendorForm.patchValue({
            vendor_name: editData.name,
            vendor_number: editData.number,
            vendor_email: editData.email,
            vendor_description: editData.description,
            vendor_business_address1: editData?.address1,
            vendor_business_address2: editData?.address2
          })
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  createVendor() {
    if (this.vendorForm.invalid) {
      return;
    }
    let obj: any = {
      id: this.editId ? this.editId : '',
      name: this.vendorForm.value.vendor_name,
      number: this.vendorForm.value.vendor_number,
      email: this.vendorForm.value.vendor_email,
      description: this.vendorForm.value.vendor_description,
      address1: this.vendorForm.value.vendor_business_address1,
      address2: this.vendorForm.value.vendor_business_address2
    }
    this.constantService.progressLoader.next(true);
    if (this.subscriptionAdd) this.subscriptionAdd.unsubscribe();
    if (!this.editId) {
      this.subscriptionAdd = this.vendorManagementService.addVendor(obj).pipe(
        finalize(() => this.constantService.progressLoader.next(false)),
        catchError((err) => {
          this.toastr.error(err.error.message);
          return throwError(() => err.error.message);
        })).subscribe((res: ResponseMessage) => {
          if (res.code == 200) {
            this.toastr.success(res.message);
            this.backToList();
          } else {
            this.toastr.error(res.message);
          }
        })
    } else {
      this.subscriptionAdd = this.vendorManagementService.updateVendor(obj).pipe(
        finalize(() => this.constantService.progressLoader.next(false)),
        catchError((err) => {
          this.toastr.error(err.error.message);
          return throwError(() => err.error.message);
        })).subscribe((res: ResponseMessage) => {
          if (res.code == 200) {
            this.toastr.success(res.message);
            this.backToList();
          } else {
            this.toastr.error(res.message);
          }
        })
    }
  }

  backToList() {
    this.route.navigate(['/vendor-list']);
  }
}
