<div class="section-box mb-24 flex flex-wrap flex-align-center gap-16px header-bx mb-25 backbtn-wrap">
  <h1 class="text-white mb-0 text-truncate">
    {{action == 'Edit'?('edit_menu' | translate):('add_menu' | translate)}}
  </h1>
  <button (click)="backToMenuList()" mat-flat-button class="btn-outline-secondary backbtn"><svg width="7" height="15"
      viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859"
        stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>{{'back' | translate}}</button>
</div>

<form class="form-box section-box" [formGroup]="menuFormGroup">
  <div class="mb-20">
    <label for="upload-image" class="form-label">{{"Image" | translate}}</label>
    <div class="image-uploadbx mb-16 flex flex-align-center flex-justify-center cursor-pointer"
      [ngClass]="{ 'image-invalid': !isImageSelcted }" (click)="openImageLibrary()">
      <img [src]="selectedImage?.media_id ? selectedImage.url : logoImage"
        [alt]="selectedImage?.media_id ? selectedImage.image_name : 'MuyBi'"
        [class]="selectedImage?.media_id ? 'img-fit' : 'placeholder-img'" />
    </div>
    <div class="flex flex-align-center gap-16px flex-wrap">
      <ng-container *ngIf="selectedImage && selectedImage?.media_id">
        <div class="flex flex-align-center gap-8px text-primary cursor-pointer fw-medium" (click)="openImageLibrary()">
          <img src="assets/images/icons/gallery-edit.svg" alt="Update" />
          <span>{{"update" | translate}}</span>
        </div>
        <span class="divider-line text-grey-dark">|</span>
        <div class="flex flex-align-center gap-8px text-danger cursor-pointer fw-medium"
          (click)="removeSelectedImage()">
          <img src="assets/images/icons/close-circle.svg" alt="Remove" />
          <span>{{"remove" | translate}}</span>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="mb-24">
    <div class="mb-20">
      <label id="example-radio-group-label" class="text-grey-medium d-block mb-10 fs-14">
        {{'item_for' | translate}}
      </label>
      <mat-radio-group aria-label="Select an option" formControlName="item_for">
        <mat-radio-button *ngFor="let item of itemFor" [value]="item.id">
          {{item.translateLabel| translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="mb-20">
      <label id="example-radio-group-label" class="text-grey-medium d-block mb-10 fs-14">
        {{'menu_type' | translate}}
      </label>
      <mat-radio-group aria-label="Select an option" formControlName="menu_type">
        <mat-radio-button *ngFor="let item of menuType" [value]="item.id">
          {{item.translateLabel | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="menu-grid3 mb-20">
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label class="fs-14">{{'menu_title' | translate}}</mat-label>
        <input matInput formControlName="menuTitle" />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-select">
        <mat-label class="fs-14">{{'branch_location' | translate}}</mat-label>
        <mat-select formControlName="branchLocation">
          <mat-option *ngFor="let branch of branchLocatonListData;" [value]="branch.business_location_id"
            (click)="changeBranchLocation()">
            {{ branch.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label class="fs-14">{{'code' | translate}}</mat-label>
        <input matInput type="number" formControlName="code" />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-select">
        <mat-label class="fs-14">{{'menu_category' | translate}}</mat-label>
        <mat-select formControlName="category">
          <mat-option *ngFor="let category of categoryListData;" [value]="category.category_id">
            {{ category.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label class="fs-14">{{'stock_detail' | translate}}</mat-label>
        <input matInput formControlName="available_stock" max="9999999999" [maxlength]="10" allowNumberOnly />
      </mat-form-field>
      <div>
        <label id="example-radio-group-label" class="text-grey-medium d-block mb-10 fs-14">{{'enable_to_sell' |
          translate}}</label>
        <mat-radio-group aria-label="Select an option" formControlName="available_to_sell">
          <mat-radio-button *ngFor="let item of enableToSell" [value]="item.id">
            {{item.translateLabel | translate}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label class="fs-14">{{'menu_description' | translate}}</mat-label>
        <input matInput formControlName="description"/>
        <!-- <textarea matInput formControlName="description"></textarea> -->
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label class="fs-14">{{'sell_price_for_dine_in' | translate}}</mat-label>
        <input matInput formControlName="price" allowFlotNumberOnly />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label class="fs-14">{{'sell_price_for_delivery' | translate}}</mat-label>
        <input matInput formControlName="price_delivery" allowFlotNumberOnly />
      </mat-form-field>
    </div>

    <div class="mb-20">
      <mat-form-field
        [ngClass]="{'mat-input-chip-invalid': (action == 'Edit' && menuFormGroup.controls['selectedItem'].invalid|| action == 'Add' && menuFormGroup.controls['selectedItem'].touched && menuFormGroup.controls['selectedItem'].invalid)}"
        appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{"select_item_for_menu" | translate}}</mat-label>
        <mat-chip-grid #chipGrid aria-label="Item selection" formControlName="selectedItem">
          <ng-container *ngFor="let item of selectedProductNames;">
            <mat-chip-row (removed)="remove(item)">
              {{ item }}
              <button matChipRemove [attr.aria-label]="'remove ' + item">
                <mat-icon>
                  <img src="assets/images/icons/close-circle.svg" alt="Remove" />
                </mat-icon>
              </button>
            </mat-chip-row>
          </ng-container>
          <input name="currentItem" #itemInput placeholder="" [(ngModel)]="currentItem"
            [ngModelOptions]="{ standalone: true }" [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" required />
        </mat-chip-grid>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">

          <mat-option *ngFor="let product of filteredProducts();" [value]="product.id"
            [disabled]="selectedProductNames.includes(product.name)">
            {{ product.name }}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div>
      <label id="example-radio-group-label" class="text-grey-medium d-block mb-10 fs-14">
        {{'food_type' | translate}}
      </label>
      <mat-radio-group aria-label="Select an option" formControlName="food_type">
        <mat-radio-button *ngFor="let item of foodType" [value]="item.id">
          {{item.translateLabel | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="gap-20px flex flex-justify-end footer-btns">
    <button *ngIf="action == 'Edit'" mat-flat-button class="btn-danger btn-lg deletemenu-btn"
      (click)="deleteMenuDialog(menuId)">
      {{"delete_menu" | translate}}
    </button>
    <button mat-flat-button class="btn-lg updatemenu-btn" (click)="addUpdateMenu()">
      {{ action == "Add" ? ("save" | translate) : ("update_menu" | translate) }}
    </button>
  </div>
</form>