import { Component, inject, OnInit } from '@angular/core';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

// Modules
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

// Interfaces
import { AuthService } from '../auth.service';
import { CommonModule } from '@angular/common';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { MatRadioModule } from '@angular/material/radio';
import { PasswordFieldType } from '../../../model/interfaces/auth.interface';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { OtpVerificationComponent } from '../../../shared/components/dialog/otp-verification/otp-verification.component';
import { emailOrNumberValidator } from '../../../shared/validators/email-or-number-validator.directive';
import { passwordMatchValidator } from '../../../shared/validators/password-match-validator.directive';
import { ConstantService } from '../../../shared/services/constant.service';
import { EncryptionService } from '../../../shared/services/encryption.service';
import { TranslateModule } from '@ngx-translate/core';
import { emailValidator } from '../../../shared/validators/email-validator.directive';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { spinnerDiameter } from 'src/app/model/const/common.const';
import { LanguageNamePipe } from 'src/app/shared/pipes/language-name.pipe';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    RouterModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgxMatIntlTelInputComponent,
    MatRadioModule,
    TranslateModule,
    MatProgressSpinnerModule,
    LanguageNamePipe,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [EncryptionService],
})
export class LoginComponent implements OnInit {
  private authService = inject(AuthService);
  private fb = inject(NonNullableFormBuilder);
  private toastr = inject(ToastrService);
  private dialog = inject(MatDialog);
  private constantService = inject(ConstantService);
  private route = inject(Router);
  private router = inject(ActivatedRoute);
  private token = '';
  hidePass: boolean = false;
  loader: boolean = false;
  private userDetail = {};
  private signinSubscription!: Subscription;
  private encDecObj = inject(EncryptionService);
  spinnerDiameter = spinnerDiameter;

  public preferredCountries = ['us']
  isEmail: boolean = true;
  language: any;

  constructor() {
    const token = this.router.snapshot.queryParams['token'];
    if (token) {
      localStorage.setItem('token', token)
      this.constantService.progressLoader.next(true);
      this.getLoginDetail();
    }

    this.constantService.updateLangLocationBase.subscribe((res: boolean) => {
      if (res) { this.getLang(); }
    });
    this.getLang();
  }

  /** Login Form initialization **/
  signInForm = this.fb.group({
    userName: ['', [Validators.required, emailOrNumberValidator()]],
    phone: ['', []],
    password: ['', [Validators.required, passwordMatchValidator.bind(this)]],
    device_type: [1],
    type: ['1'],
  });

  ngOnInit() {
    this.onInputChange();
    this.setModuleTitle();
    this.setCountry();
  }

  setCountry() {
    this.authService.getCountryName().pipe(
      catchError((err) => {
        this.toastr.error(err.error.message);
        return throwError(() => err.error.message);
      })
    ).subscribe((response) => {
      this.preferredCountries = [response.country.toLowerCase()];
      this.authService.setPreferredCountries(this.preferredCountries);
    });
  }

  /**
   * based on input set validator for email or phone
   */
  onInputChange() {
    this.signInForm.patchValue({
      userName: '',
      phone: '',
    });
    if (this.signInForm.value.type == '1') {
      this.signInForm.controls.userName.setValidators([
        Validators.required,
        emailValidator(),
      ]);
      this.signInForm.controls.userName.updateValueAndValidity();
      this.signInForm.controls.phone.setValidators([]);
      this.signInForm.controls.phone.updateValueAndValidity();
    } else {
      this.signInForm.controls.userName.setValidators([]);
      this.signInForm.controls.userName.updateValueAndValidity();
      this.signInForm.controls.phone.setValidators([
        Validators.required,
        emailOrNumberValidator(),
      ]);
      this.signInForm.controls.phone.updateValueAndValidity();
    }
  }



  /** calls login api if all field are validated  */
  login() {
    if (this.signinSubscription) this.signinSubscription.unsubscribe();
    if (this.signInForm.invalid) {
      return;
    }
    let obj: any;
    obj = {
      email_phone_number:
        this.signInForm.value.userName || this.signInForm.value.phone,
      password: this.signInForm.value.password,
      device_type: this.signInForm.value.device_type,
      login_type: this.signInForm.value.type,
    };
    this.loader = true;
    this.signinSubscription = this.authService
      .signin(obj)
      .pipe(
        finalize(() => this.loader = false),
        catchError((err) => {
          this.toastr.error(err.error.message);
          return throwError(() => err.error.message);
        })
      )
      .subscribe((res: any) => {
        if (res.code == 200) {
          //0= No authenticatoin  1= Phone, 2=Microsoft authenticator
          if (res.data?.step == 3) {
            if (res?.data?.token) {
              const userDetail = this.encDecObj.setEncrypt(JSON.stringify(res.data));
              localStorage.setItem('userDetail', userDetail);
            }
            localStorage.setItem('currentStep', '2');
            this.route.navigate(['/signup']);
            return;
          }
          if (res.data.protected_with == 0) {
            this.setUserData(res)
          } else {
            this.verificationDialog(res.data)
          }
        } else {
          this.toastr.error(res.message);
        }
      });
  }

  verificationDialog(userData: any) {
    let diloagRef = this.dialog.open(OtpVerificationComponent, {
      width: '564px',
      panelClass: ['modal-wrapper', 'otp-verfication'],
      disableClose: true,
      data: {
        user_id: userData.user_id,
        type: this.signInForm.value.userName ? '1' : '2',
        title: 'login_form.verify.title',
        sub_title: userData.protected_with == 1 ? 'login_form.verify.sub_title' : 'login_form.verify.sub_title_microsoft', // 1= Phone, 2=Microsoft authenticator
        dont_receive: 'login_form.verify.dont_receive',
        api: 1,
        phone_number: userData.phone_number,
        is_send_from: 1, //1= login, 2 = Signup 3 = account protection
        resend: userData.protected_with == 1 ? true : false, //  1= Phone, 2=Microsoft authenticator
      },
    });
    diloagRef.afterClosed().subscribe((response) => {
      if (response.success) {
        this.setUserData(response.res);

        // this.toastr.success(response.res.message);

        // // Set Token
        // this.token.set(response.res.data.token);
        // localStorage.setItem('token', response.res.data.token);
        // this.userDetail.set(response.res.data);
        // this.authService.setToken(this.token());

        // // Set User detail
        // const userDetail = this.encDecObj.setEncrypt(
        //   JSON.stringify(this.userDetail())
        // );
        // localStorage.setItem('userDetail', userDetail);

        // // Redirect to the Dashboard after successfully verify login
        // this.route.navigate(['/dashboard']);
      } else {
        localStorage.removeItem('token');
      }
    });
  }

  setUserData(user: any) {
    const { message, data: { token, ...userDetails } } = user;

    // Display success message
    this.toastr.success(message);

    // Set and store token
    this.token = token;
    localStorage.setItem('token', token);
    this.authService.setToken(this.token);

    // Set and store encrypted user details
    this.userDetail = userDetails;
    const encryptedUserDetail = this.encDecObj.setEncrypt(JSON.stringify(userDetails));
    localStorage.setItem('userDetail', encryptedUserDetail);

    // Redirect to the Dashboard
    this.route.navigate(['/dashboard']);
  }

  getLoginDetail() {
    if (this.signinSubscription) this.signinSubscription.unsubscribe();
    let obj = {
      user_type: 3,
    };
    this.loader = true;
    this.signinSubscription = this.authService
      .signinViaSwitch(obj)
      .pipe(
        finalize(() => this.loader = false),
        catchError((err) => {
          this.toastr.error(err.error.message);
          localStorage.removeItem('token');
          return throwError(() => err.error.message);
        })
      )
      .subscribe((res: any) => {
        if (res.code == 200) {
          //0= No authenticatoin  1= Phone, 2=Microsoft authenticator
          this.setUserData(res);
        } else {
          localStorage.removeItem('token')
          this.toastr.error(res.message);
        }
      });
  }

  setModuleTitle() {
    this.constantService.setModuleTitle('Login');
  }

  changeLanguage() {
    this.constantService.changeLanguages(this.language);
    this.getLang();
  }

  getLang() {
    this.language = this.constantService.getLanguage();
  }

  ngOnDestroy(): void {
    if (this.signinSubscription) this.signinSubscription.unsubscribe();
  }
}
